// Colors.tsx
const Colors = {
    background: '#F8F8F8',

    black: '#000000',
    grey9: '#1a1a1a',
    grey8: '#333333',
    grey7: '#4d4d4d',
    grey6: '#666666',
    grey5: '#808080',
    grey4: '#999999',
    grey3: '#b3b3b3',
    grey2: '#cccccc',
    grey1: '#e6e6e6',
    white: '#ffffff',
    whiteTransparent: 'rgba(255, 255, 255, 0.2)',
    transparent: 'rgba(0, 0, 0, 0)',

    blue: '#006997',
    blueDark: '#004360',
    blueLight: '#4084A2',
    blueTransparent: 'rgba(23, 154, 202, 0.1)',
    grayVeryPale: '#F8F8F8',
    grayPale: '#F2F2F2',
    grayLight: '#CECECE',
    grayMed: '#424956',
    grayDark: '#1E1E1E',
    pale: '#EAF5F9',
    paleBright: '#D7EBF2',
    red: '#D3372A',
    green: '#008E17',
    yellow: '#D7A700',
    redDark: '#BD1609',
    redLight: '#E84134',
    redPale: '#FBEFEB',
    greenPale: '#E5F5E7',
    yellowPale: '#FFFDDB',

    vizDefault: 'lightcoral',
    expressionDefault: '#676767',

    websiteYellow: '#FFDA48',
    websiteRed: '#FF452D',
}

export const blendWithWhite = (hex, opacity) => `#${hex.replace(/^#/, '').match(/.{2}/g).map(c => Math.round((parseInt(c, 16) * opacity) + (255 * (1 - opacity))).toString(16).padStart(2, '0')).join('')}`;

const ColorViz = [
    { name: 'Red', value: '#EF4444' },
    { name: 'Orange', value: '#F97316' },
    { name: 'Amber', value: '#F59E0B' },
    { name: 'Yellow', value: '#EAB308' },
    { name: 'Lime', value: '#84CC16' },
    { name: 'Green', value: '#22C55E' },
    { name: 'Emerald', value: '#10B981' },
    { name: 'Teal', value: '#14B8A6' },
    { name: 'Cyan', value: '#06B6D4' },
    { name: 'Sky', value: '#0EA5E9' },
    { name: 'Blue', value: '#3B82F6' },
    { name: 'Indigo', value: '#6366F1' },
    { name: 'Violet', value: '#8B5CF6' },
    { name: 'Purple', value: '#A855F7' },
    { name: 'Fuchsia', value: '#D946EF' },
    { name: 'Pink', value: '#EC4899' },
    { name: 'Rose', value: '#F43F5E' }
];

export const getColorViz = () => ColorViz;

export const getComplementaryColors = (numColors) => {
    const totalColors = ColorViz.length;
    const step = Math.round(totalColors / numColors);
    const startIndex = Math.round(Math.random() * totalColors);
    let complementaryColors = [];

    for (let i = 0; i < numColors; i++) {
        const index = Math.round((startIndex + i * step) % totalColors);
        const color = ColorViz[index].value;
        complementaryColors.push(color);
    }
    return complementaryColors;
};

export const getRandomColor = () => {
    const totalColors = ColorViz.length;
    const index = Math.floor(Math.random() * totalColors);
    return ColorViz[index].value;
};

export default Colors;