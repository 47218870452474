// SignUp.tsx
import {FunctionComponent, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Input from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ButtonLink from "../../components/basic/ButtonLink";
import Colors from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";
import { useRegister } from "../../apollo/useAuth";
import LegalFooter from "../legal/LegalFooter";
import { useComputedProblems } from '../problem/ComputedProblemsContext';
import MessageAlert from "../../components/basic/MessageAlert";
import { Helmet } from "react-helmet-async";

const SignUpRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.background};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.lexendMedium.fontFamily};
  gap: 10px;
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const SignUpParent = styled.div`
  margin: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
`;

const SignUp1 = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.lexendMedium.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
  padding-bottom: 26px;
`;
const Input1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const DropDown1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;
const ButtonContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  max-width: 400px;
`;
const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MessageContainer = styled.div`
  position: absolute;
  top: 60px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LegalFooterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px;
`;

const SignUp: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const promptParam = searchParams.has("prompt");
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { register, registerData, registerLoading, registerError } = useRegister();
  const [isLoading, setIsLoading] = useState(false);
  const { computedProblemIds, copyProblemsToAccount } = useComputedProblems();
  const [showMessage, setShowMessage] = useState(false);

  const onRegisterClick = useCallback(async () => {
    setIsLoading(true);

    if (name.length < 1) {
      // console.log("name", name);
      setError('name');
      setIsLoading(false);
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      setError('email')
      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      setError('password');
      setIsLoading(false);
      return;
    }
    setError(null);

    try {
      await register(name, (email).toLowerCase(), password);
      await copyProblemsToAccount(computedProblemIds);
    } catch (error) {
      console.error('Error registering:', error);
    }
  }, [name, email, password, register, computedProblemIds, copyProblemsToAccount]);

  useEffect(() => {
    if (promptParam === true) {
      setShowMessage(true);
    }
  }, [promptParam]);

  useEffect(() => {
    if (registerData && registerData.register.token) {
      // console.log('registered', registerData);
      navigate("/problem"); 
      setIsLoading(false);
    }
  }, [register, registerData, navigate]);

  useEffect(() => {
    if (registerError) {
      console.error('Registration error:', registerError);
      setError('email');
      setIsLoading(false);
    }
  }, [registerError]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onRegisterClick(); // register when user presses enter key
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onRegisterClick]);

  const onFooterTextClick = () => {
    navigate("/log-in");
  };

  return (
    <SignUpRoot id="sign-up-root">
      <Helmet>
        <title>SeeSolve - Sign Up</title>
        <meta name="description" content="The #1 AI for learning math. Solve any problem. Get unstuck. Learn faster. With interactive visualizations and step-by-step calculators." />
      </Helmet>
      <Logo src="/seesolve-logo.svg" alt="SeeSolve Logo" />
      {showMessage === true && (
        <MessageContainer>
          <MessageAlert 
            titleText={'Join SeeSolve'} 
            bodyText={'Sign up for free to solve more problems!'} 
            // 'One problem down! Don’t stop here—sign up for free to continue solving!'
            color='green'
            onClose={() => {}}
          />
        </MessageContainer>
      )}
      <SignUpParent>
        <SignUp1>Sign Up</SignUp1>
        <Input1>
          <Input
              placeholder="Name"
              icon={'person'}
              onChange={(event) => setName(event.target.value)}
              errorMessage={error == 'name' ? "Please enter your name." : null}
          />
          <Input
              placeholder="Email"
              icon={'mail'}
              onChange={(event) => setEmail(event.target.value)}
              errorMessage={error == 'email' ? "Unable to register with this email address." : null}
          />
          <Input
              placeholder="Password"
              icon={'lock'}
              type={"password"}
              onChange={(event) => setPassword(event.target.value)}
              errorMessage={error == 'password' ? "Your password must be at least 8 characters." : null}
          />
        </Input1>
      <ButtonContainer>
        <Button text="SIGN UP" onClick={onRegisterClick} size={'small'} fixed={true} color={'black'} icon={isLoading ? 'loading' : undefined} />
      </ButtonContainer>
      <BottomFixed >
        <BottomContainer>
          <FooterText>
            Already have an account?
          </FooterText>
          <ButtonLink text="Log In" onClick={onFooterTextClick} />
        </BottomContainer>
      </BottomFixed>
      </SignUpParent>
      <LegalFooterContainer>
        <LegalFooter />
      </LegalFooterContainer>
    </SignUpRoot>
  );
};

export default SignUp;
