// TermsOfService.tsx
import { FunctionComponent } from "react";
import LegalPage from "./LegalPage";

const TermsOfService: FunctionComponent = () => {
  const title = "Terms of Service";
  const subtitle = "Last updated August 26, 2024";
  const content = [
    {
      header: "Introduction",
      body: [
        "Welcome to SeeSolve (“Company”, “we”, “our”, “us”). Please pause, grab a cup of coffee and carefully read the following pages. It will take you approximately 20 minutes to read through.",
        "These Terms of Service (“Terms”) govern your use of our web pages located at https://seesolve.ai and our mobile application SeeSolve (together or individually, the “Service”), operated by SeeSolve, a Delaware corporation.",
        "Our Privacy Policy (“Privacy Policy”) also governs your use of the Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here - https://app.seesolve.ai/privacy-policy.",
        "Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood the Agreements and agree to be bound by them. If you do not agree with (or cannot comply with) the Agreements, then you may not use the Service. These Terms apply to all visitors, users and others who wish to access or use Service. Please email us at support@seesolve.ai  with any questions you may have about the Agreements."
      ],
    },
    {
      header: "Amendments to Terms",
      body: [
        "Except as noted in the “Fee Changes” section below, the Company may revise and update these Terms at any time and for any reason at our sole discretion. We will provide notice of the most recent version of these Terms by updating the “Last Updated” date above and you waive any right to receive specific notice otherwise of changes. All changes are effective immediately upon posting and apply to all access to and use of the Service thereafter. If you don’t like any changes to our Terms, you may stop using the Service.  Your continued access to and use of the Service signifies your acceptance of the revised and then-current Terms."
      ],
    },
    {
      header: "No Use by Minors and Location",
      body: [
        "The Service is intended only for access and use by individuals at least eighteen (18) years old and residents of the United States, including its territories and possessions. By accessing or using the Service, you warrant and represent that you are at least eighteen (18) years of age, a resident of the United States and its territories and possessions, and with the full authority, right, and capacity to enter into the Agreements and abide by all of its terms and conditions. If you cannot comply with the aforementioned warranty and representation, you are prohibited from both the access and usage of the Service."
      ],
    },
    {
      header: "Accounts",
      body: [
        'The Company may require you to register for an account and provide certain information about yourself (such as your name, phone number, street address, and email) in order to use the Service and communicate with us. Account users may be provided or asked to create a username and/or password and we encourage you to safeguard that information to protect your account from unauthorized access. You agree that you will provide truthful information and maintain the accuracy and completeness of your account information with us. You further agree that you are responsible for all use of your account and password, and that you will not share that information with anyone else: your account is personal to you. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.',
        'You always have the right to terminate your account and can do so by accessing your account settings or contacting our Support Team at support@seesolve.ai. If we, in our sole discretion, determine that you have acted inappropriately, we reserve the right to limit, restrict or terminate your account, prohibit you from using the Service, remove any content you may have provided, and take any appropriate legal actions to protect the Company, our partners, or other users. You can read more below in the section titled “Changes and Termination by Us”.'
      ],
    },
    {
      header: "Subscriptions",
      body: [
        "The Service is billed on a subscription basis (“Subscription”). You will be billed in advance at the beginning of each respective month or year on a recurring basis, depending on the type of subscription plan you select and including any feature limitations (“Billing Cycle”).",
        "In order to pay for your Subscription, you will need to create an account with Stripe, the Company’s third-party payment processor. You shall provide Stripe with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information including credit card. By submitting such payment information, you authorize the Company (through Stripe) to charge all Subscription fees incurred through your account to any such payment instruments. Please read Stripe’s Terms of Service carefully before creating your account with them, including how to dispute unauthorized payments and how Stripe maintains your data. For clarity, the Company does not have access to any information you provide to Stripe.",
        "At the end of each Billing Cycle, and except as noted in the “Fee Changes” section below, your Subscription will automatically renew under the exact same conditions unless you cancel it or the Company cancels it. You may cancel your Subscription before its renewal date either through your online account management page or by contacting Customer Support at support@seesolve.ai.",
        "Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice. The Company may charge an administrative fee for processing electronic invoices.",
        "Should you elect to terminate your Subscription while a Billing Cycle is in effect, you will not receive a pro-rata refund of fees paid but will be able to continue accessing the Service until the current Billing Cycle has expired."
      ],
    },
    {
      header: "Free Trial",
      body: [
        "The Company may, at its sole discretion, offer a limited period free trial prior to your first Subscription payment becoming due (“Free Trial”).",
        "You may be required to enter your billing information in order to sign up for the Free Trial, however you will not be charged by the Company until the Free Trial has expired. On the last day of the Free Trial period, unless you have canceled your Subscription you will be automatically charged the applicable Subscription fees for the type of Subscription plan you have selected. Should the Company change the terms of the Free Trial, you will be notified before your first Subscription payment becomes due so you may cancel your account if desired."
      ],
    },
    {
      header: "Fee Changes",
      body: [
        "The Company may modify the Subscription fees and such change will become effective at the end of the then-current Billing Cycle.",
        "The Company will provide you with at least thirty (30) days prior notice, via email or in the Service, of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.",
        "Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount."
      ],
    },
    {
      header: "Content",
      body: [
        "Our Service allows you to store certain information, text, graphics, videos, or other material (“Content”). You are responsible for all Content that you store on or through the Service, including its legality, reliability, and appropriateness.",
        "By storing Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the storing of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.",
        "You retain any and all of your rights to any Content you submit and store on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for the Content. However, by submitting and storing Content using the Service, you grant us the right and license to use the Content to provide, maintain, develop, and improve the Service, comply with applicable law, enforce our terms and policies, and keep the Service safe."
      ],
    },
    {
      header: "Changes and Termination by Us",
      body: [
        "We reserve the right to withdraw or amend our Service, and any service or material we provide via the Service, in our sole discretion and without notice. From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.",
        "We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Agreements. All provisions of the Agreements which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.",
        "Should termination be for breach of the Agreements, you won’t be entitled to a refund of your Subscription fees. However, should the Company terminate your account for convenience, or withdraw or materially amend the Service such that it renders it unusable by you, it will refund you a pro-rata portion of Subscription fees unused."
      ],
    },
    {
      header: "Prohibited Uses",
      body: [
        "You agree not to use the Service in a way that violates any laws, infringes any individual’s or entity’s rights, is inappropriate or offensive, or interferes with the Service (including any technological measures we employ to enforce the Agreements). You agree that the Company is not liable to you or any third party for any access, use, modification, suspension, or discontinuance of the Service.",
        "The respectful use of the Service is important to us. You may not disarm, manipulate, work around, or disable any technological measures we employ to manage and safeguard the Service.",
        "The Company specifically prohibits you from using the Service: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or attempt to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or any websites; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service.",
        "The Company may monitor the Service for violations of the Agreements and take appropriate legal action against anyone who, in our sole discretion, violates the law or the Agreements, including without limitation, reporting such user to law enforcement authorities; refusing, restricting access to, limiting the availability of, or disabling (to the extent technologically feasible) your user account or any of your content; and otherwise manage the Service in a manner designed to protect our rights and to facilitate our business.",
        "By accessing and using the Service, you agree that you and the Company are independent contractors to each other.  You further acknowledge and agree that no additional partnership, joint venture, employee, employer or other relationship is intended or entered into by the Agreements. You agree not to hold yourself out as in any way sponsored by, affiliated with, endorsed by, in partnership with, or as an employee of the Company or any of our affiliates or partners."
      ],
    },
    {
      header: "Links to Other Websites",
      body: [
        "Our Service may contain links to third party web sites or Service that are not owned or controlled by the Company. Such third party websites and materials are not necessarily affiliated with us and we do not endorse, monitor, or have any control or input over such third party websites or materials. We are not responsible for examining or evaluating their content or accuracy. The Company does not warrant, and will not have any liability or responsibility for, any websites, materials, products, or Service of third parties. Any use by you of third party materials is entirely at your own risk and discretion. We encourage you to carefully review the terms, conditions, and policies of any third parties before you engage in any transactions with them. Complaints, claims, concerns, or questions regarding third party websites, policies, products, or Service should be directed to the third party and not to the Company."
      ],
    },
    {
      header: "Analytics",
      body: [
        "We may use third-party service providers to monitor and analyze the use of our Service.",
        "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google Service. Google may use the collected data to contextualize and personalize the ads of its own advertising network.",
        "For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:https://policies.google.com/privacy?hl=en. We also encourage you to review Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245."
      ],
    },
    {
      header: "Communications",
      body: [
        "By creating an account on our Service, you agree to subscribe to newsletters, marketing and promotional materials and other information we may send. You agree that we may communicate with you and provide you with notices by email to the address then-current in your user account. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing. The only communications you may not opt out are related to our provision of the Service to you; to stop receiving those communications, you must terminate your account first."
      ],
    },
    {
      header: "Intellectual Property",
      body: [
        'All trademarks, logos, and service marks (“Marks”) displayed within the Service are the registered and unregistered trademarks of the Company or third parties who have authorized their use by us.  The Service is protected by copyright and other intellectual property rights, including but not limited to the images, likenesses, designs, and the like, except where explicitly noted otherwise (collectively "Intellectual Property"). You may not use, copy, edit, reproduce, republish, upload, post, transmit, distribute, create derivates of, or modify the Intellectual Property in any way without prior written approval from us and any third party owner or licensor. You are prohibited from infringing or violating the Company’s Intellectual Property rights and we will enforce our rights to the fullest extent of the law.',
        "Unless otherwise indicated, the Service is our proprietary property and all materials, source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics, as well as any Intellectual Property are owned or licensed and controlled by us, and are protected under various other intellectual property rights, unfair competition laws of the United States, international copyright laws, and international conventions. These materials are provided by the Company “as is” for your information and personal use only. Except as expressly provided in these Terms, no part of our Service may be used by you for a commercial purpose without our express prior written permission."
      ],
    },
    {
      header: "Error Reporting and Feedback",
      body: [
        "You may provide us either directly at support@seesolve.ai or via third party sites and tools, such as Bugsnag and Sentry, with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) the Company may have development ideas similar to the Feedback; (iii) the Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) the Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable laws, you grant the Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) the Feedback in any manner and for any purpose."
      ],
    },
    {
      header: "Disclaimer of Warranties; Limitation of Liability",
      body: [
        "THE SERVICE IS PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, THEIR CONTENT, OR ANY SERVICE OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICE OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; HOWEVER, WE EXCLUDE ALL WARRANTIES TO THE MAXIMUM EXTENT PERMITTED BY LAW.",
        "EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENTS, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THE AGREEMENTS AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF YOU ARE DISSATISFIED WITH THE SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE THEREOF. OUR AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS ARISING FROM OR RELATED TO THE SERVICE IS LIMITED TO THE LESSER OF (I) THE AMOUNT OF FEES ACTUALLY PAID BY YOU FOR USE OF THE PRODUCTS AND SERVICE, OR (II) ONE HUNDRED DOLLARS (U.S. $100.00).",
        "THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR. IF YOU ARE A RESIDENT OF A STATE WITH PROTECTIONS SIMILAR TO CALIFORNIA CIVIL CODE §1542, YOU HEREBY WAIVE SUCH PROVISIONS OR PROTECTIONS."
      ],
    },
    {
      header: "Indemnification",
      body: [
        "You agree to indemnify, defend, and hold harmless the Company, including our affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of the Agreements, the documents they incorporate by reference, or your violation of any law or the rights of a third party."
      ],
    },
    {
      header: "Dispute Resolution; Arbitration; Governing Law; Waiver",
      body: [
        "If you have a dispute with the Company, we invite you to reach out to us at support@seesolve.ai so that we can try to resolve your concerns.  If after thirty (30) days we are unable to amicably resolve any claim or dispute between us arising under the Agreements, then you agree to have your claim or dispute resolved through confidential, individual, binding arbitration in the County of New Castle, Delaware under the then prevailing commercial arbitration rules of the American Arbitration Association (“AAA”) and waive any right to a jury trial.",
        "The parties agree that one (1) arbitrator, selected jointly by the parties, will arbitrate the dispute. If the parties cannot agree on an arbitrator within twenty (20) days of the demand for arbitration, the arbitrator will be selected from the AAA panels and according to the Rules of the AAA. The decision of the arbitrator will be made in writing, is final and binding, and judgment may be entered in any court of competent jurisdiction.  The decision may not be vacated, modified or appealed, except to the extent permitted by the terms of sections 10 and 11 of the Federal Arbitration.",
        "To the fullest extent of the law, such arbitration will be limited to a specific individual and may not be inferred to or against matters affecting other individuals’ interactions with the Company, thus meaning that you agree not to participate in any joint, consolidated or class action lawsuit or arbitration and you explicitly waive the right to have your claims consolidated into, asserted by, or determined according to a class action lawsuit or arbitration.",
        "The Agreements shall be governed by, construed, and enforced in accordance with the laws of the State of Delaware, without giving effect to any conflict of law provisions. The expenses of arbitration, including reasonable attorneys’ fees and the fees and expenses of the arbitrator, shall be shared equally by the parties.",
        "Notwithstanding this provision, either party may bring a claim related to intellectual property rights or seek temporary and preliminary specific performance and injunctive relief in any court of competent jurisdiction, without the posting of bond or other security.",
        "Our failure to enforce any right or provision of the Agreements will not be considered a waiver of those rights. If any provision of the Agreements is held to be invalid or unenforceable by a court, the remaining provisions of the Agreements will remain in effect. The Agreements constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service."
      ],
    },
    {
      header: "Contact Us",
      body: [
          "If you have any questions or concerns about these Terms please contact us at the following:",
          "A. Via Email: support@seesolve.ai is the fastest and most-preferred way to reach us.",
          "B. Via Mail: SeeSolve: 19 Acadia Park, Unit 3, Somerville, MA 02143",
          "C. Via Phone: (617) 792 9644"
      ],
    }
  ];

  return <LegalPage title={title} subtitle={subtitle} content={content} />;
};

export default TermsOfService;
