// Document.tsx
import React from 'react';
import styled from 'styled-components';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import Latex from './Latex';

const StyledTextarea = styled.textarea<{ $readOnly: boolean; $horizontalPadding: number }>`
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex: 1 1 auto;
  background-color: ${Colors.white};
  border-radius: 8px;
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 24px;
  line-height: 1.4em;
  padding: ${({ $horizontalPadding }) => `${Math.min(26, $horizontalPadding)}px ${Math.max(14, $horizontalPadding)}px`};
  border: none;
  outline: none;
  resize: none;
  box-sizing: border-box;
  border: 1px solid ${Colors.grayPale};
  overflow-y: hidden;

  &:focus {
    border: 1px solid ${Colors.grey1};
  }
`;

const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex: 1;
`;

interface DocumentProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  horizontalPadding?: number;
}

const Document: React.FC<DocumentProps> = ({
  placeholder = '',
  value,
  onChange,
  readOnly = false,
  horizontalPadding = 34,
}) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '100%';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log('DOCUMENT Input Value:', event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = '100%';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    onChange(event.target.value);
  };
  // console.log('document value', value);

  const containsLatex = /\$\$[\s\S]*?\$\$|\\\([\s\S]*?\\\)|\\\[[\s\S]*?\\\]/.test(value);
  const containsMarkdown = /[*#\-_>`\[\]\\]/.test(value);
  // console.log('containsLatex', containsLatex);
  // console.log('containsMarkdown', containsMarkdown);

  if (readOnly && (containsLatex || containsMarkdown)) {
    return (
      <Latex content={value} fontSize={24} padding={`0px ${horizontalPadding}px`}/>
    );
  }

  return (
    <DocumentWrapper>
      <StyledTextarea
        ref={textareaRef}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        $readOnly={readOnly}
        $horizontalPadding={horizontalPadding}
      />
    </DocumentWrapper>
  );
};

export default Document;
