import { problem } from "../Generate";

export const generateMultiplication = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const generateCombinations = (factor1: number, factor2: number) => {
    const product = factor1 * factor2;
    let rows = Math.min(factor1, factor2);
    let cols = Math.max(factor1, factor2);
    if (product > 500 || rows > 17) return;

    count += 1;
    const template = {
      name: "Multiplication",
      text: `${factor1} x ${factor2} =   `,
      visualizations: [
        {
          type: "grid",
          rows: rows,
          cols: cols,
          counters: Array(rows).fill(cols),
          colors: Array.from({ length: rows }, (_, index) => index + 1),
        }
      ],
      trace: {
        solution: `${factor1} x ${factor2} = ${product}`,
        grade_standards: factor1 <= 5 && factor2 <= 5 ? "2.OA.C.4: Work with equal groups of objects to gain foundations for multiplication. This includes using addition to find the total number of objects arranged in rectangular arrays with up to 5 rows and 5 columns, and writing an equation to express the total as a sum of equal addends."
          : "3.OA.A.1: Interpret products of whole numbers, e.g., interpret 5 × 7 as the total number of objects in 5 groups of 7 objects each.\n3.OA.C.7: Fluently multiply and divide within 100, by the end of Grade 3, using strategies such as the relationship between multiplication and division.\n4.OA.A.1: Interpret a multiplication equation as a comparison (e.g., interpret 35 = 5 × 7 as a statement that 35 is 5 times as many as 7 and 7 times as many as 5).",
        plan: `A grid with columns set to ${cols} (the larger of the 2 factors) and rows set to ${rows} (the smaller of the 2 factors) with ${cols} counters repeated ${rows} times, each in its own color, to show a total product of ${product}.`
      }
    };
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      name: `${template.name}: 2 values`,
      numColors: template.visualizations[0].colors.length,
      text: template.text,
      visualizations: template.visualizations,
      trace: template.trace
    });
  };

  for (let i = 1; i <= 50; i++) {
    for (let j = i; j <= 50; j++) {
      generateCombinations(i, j);
      if (problems.length >= maximum) return problems;
    }
  }

  return problems;
};

