import { problem } from "../Generate";
import { smartRound } from "../../../../utils/mathHelpers";

export const generateMultiplication = (maximum: number): problem[] => {
  const problems: problem[] = [];

  const addProblem = (template: any) => {
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      ...template
    });
  };

  const generateUpTo100 = (val1: number, val2: number) => {
    const product = val1 * val2;
    if (product > 100) return;

    const template = {
      name: "Multiplication <100: Number Line",
      numColors: 3,
      variables: {
        val1: "${val2 === 0 ? 0 : product/val2}",
        num_hops: "${val1 === 0 ? 0 : product/val1}",
        val2: "${val1 === 0 ? 0 : smartRound(num_hops, 0)}",
        product: "${val1 * val2}",
        tick: "${Math.max(val1, smartRound(max/14, 0))}"
      },
      text: `${val1} × ${val2} = ?`,
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: product + val1*2,
          maxVar: "max",
          tick: val1,
          tickVar: "tick",
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              startPoint: "dot",
              startColor: 2,
              startDraggable: true,
              startDragMax: "${product}",
              dragTick: 1
            },
            {
              type: "hop",
              start: 0,
              end: product,
              endVar: "product",
              hopInterval: "${val1}",
              endPoint: "dot",
              hopPoints: "arrow",
              hopLabels: "+${val1}",
              lineLabel: "×${val2}",
              endLabel: "${product}",
              startColor: -1,
              lineColor: 1,
              hopColor: 2,
              endColor: 3,
              endDraggable: true,
              endDragMin: "${val1}",
              endDragMax: "${max}",
              dragTick: "${val1}"
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: product + val1*2,
          tick: Math.max(val1, Math.round((product + val1*2)/14)),
          data: [
            {
              type: "hop",
              start: 0,
              end: product,
              hopInterval: val1,
              endPoint: "dot",
              hopPoints: "arrow",
              hopLabels: '+' + val1,
              lineLabel: `×${val2}`,
              endLabel: product,
              startColor: -1,
              lineColor: 1,
              hopColor: 2,
              endColor: 3
            }
          ]
        }
      ],
      answer: {
        multipleChoice: [
          ...[
            (product - val1).toString(),
            (product - val2).toString(),
            (product + val1).toString(),
            (product + val2).toString(),
            (val1 + val2).toString(),
            (val1 * 2).toString(),
            (val2 * 2).toString(),
            (val1).toString(),
            (val2).toString(),
            ...Array(10).fill(0).map(() => Math.max(0, product + Math.floor(Math.random() * 40) - 20).toString())
          ].filter((value, index, self) => self.indexOf(value) === index)
        ]
          .filter(value => parseInt(value) > 0 && value !== product.toString())
          .sort(() => Math.random() - 0.5)
          .slice(0, 5)
          .concat(product.toString())
          .sort((a, b) => parseInt(a) - parseInt(b)),
        multiselect: false,
        correctAnswer: [product.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const PERCENT_TO_GEN = {
    UP_TO_100: 0.1, // 10% chance to generate
  };

  // Smaller test case
  // for (let i = 2; i < 10; i++) {
  //   for (let j = 2; j < 10; j++) {
  //     const product = i * j;
  //     generateUpTo100(i, j);
  //     if (problems.length >= maximum) return problems;
  //   }
  // }

  // Generate problems for multiplication up to 100
  for (let i = 1; i <= 100; i++) {
    for (let j = 1; j <= 100; j++) {
      const product = i * j;
      if (product <= 100) {
        if (Math.random() < PERCENT_TO_GEN.UP_TO_100) { 
          generateUpTo100(i, j);
          if (problems.length >= maximum) return problems;
        }
      }
    }
  }

  return problems;
};
