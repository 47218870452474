import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import Icon from '../../components/common/Icon';
import React from 'react';
import CardButton from '../../components/basic/CardButton';
import { useConfig } from '../../utils/config';

const CalculatorLinkContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
    flex-shrink: 0;
`;

const ScaledCalculatorLink = styled(Link)<{ $isDisabled?: boolean, $isWIP?: boolean }>`
  position: relative;
  display: block;
  color: ${props => 
    props.$isDisabled ? Colors.grey3 : 
    Colors.blue
  };
  text-decoration: none;
  font-family: ${Fonts.lexendSemiLight.fontFamily};
  font-weight: ${Fonts.lexendSemiLight.fontWeight};
  font-size: ${props => props.theme.fontSize}px;
  line-height: 1.2em;
  left: ${props => props.theme.scaledLeft}px;

  padding: 8px 0;
  transition: color 0.2s;
  cursor: ${props => props.$isDisabled ? "default" : "pointer"};

  &::before {
    content: '';
    display: inline-block;
    width: ${props => props.theme.scaledBeforeWidth}px;
    height: 1px;
    visibility: hidden;
  }

  &:hover {
    color: ${props => 
      props.$isDisabled ? Colors.grey3 :
      Colors.blueLight
    };
    text-decoration: ${props => props.$isDisabled ? "none" : "underline"};
  }

  &:active {
    color: ${props => 
      props.$isDisabled ? Colors.grey3 :
      Colors.blueDark
    };
    text-decoration: ${props => props.$isDisabled ? "none" : "underline"};
  }
`;

interface CalculatorLinkProps {
  name: string;
  to: string;
  icon?: string;
  fontSize?: number;
}

const calculateScaledValue = (baseValue: number, baseFontSize: number, currentFontSize: number) => {
  return (baseValue / baseFontSize) * currentFontSize;
};

export const CalculatorLinkComponent: React.FC<CalculatorLinkProps> = ({ name, to, icon, fontSize = 24 }) => {
  const config = useConfig();
  const theme = {
    fontSize,
    scaledLeft: calculateScaledValue(-30, 24, fontSize),
    scaledBeforeWidth: calculateScaledValue(30, 24, fontSize),
  };

  const scaledIconSize = calculateScaledValue(28, 24, fontSize);
  const isWIP = name.endsWith('(?)');

  if (!config.SHOW_WIP_CALCULATORS && (isWIP || to === "")) {
    return null;
  }

  if (config.CALCULATOR_SHOW_LINK_CARD) {
    return (
      <Link to={to} style={{ textDecoration: 'none', margin: '10px 0' }}>
        <CardButton 
          name={name}
          icon={icon}
          fontSize={20}
        />
      </Link>
    );
  }

  return (
    <CalculatorLinkContainer>
      {icon && (
        <IconContainer>
          <Icon icon={icon} size={scaledIconSize} 
            color={to === "" ? Colors.grey3 : 'navy'}  
          />
        </IconContainer>
      )}
      <ScaledCalculatorLink 
        to={to} 
        $isDisabled={to === ""} 
        $isWIP={isWIP}
        theme={theme}
      >
        {name}
      </ScaledCalculatorLink>
    </CalculatorLinkContainer>
  );
};

export default CalculatorLinkComponent;
