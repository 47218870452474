// Logo.tsx
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import { Link } from 'react-router-dom';

const LogoRoot = styled.div<{ $size: number, $hasOnClick?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ $size }) => $size/3}px;
  cursor: ${({ $hasOnClick }) => $hasOnClick ? 'pointer' : 'default'};
`;

const TextContainer = styled.div<{ $size: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.$size}px;
  font-weight: ${(props) => props.$size > 48 ? Fonts.lexendUltraBold.fontWeight : Fonts.lexendBold.fontWeight};
  font-family: ${Fonts.lexendBold.fontFamily};
  letter-spacing: ${(props) => props.$size > 48 ? `-0.05em` : `auto`};
  line-height: 1em;
  user-select: none;
`;

const SeeSolve = styled.span`
  color: ${Colors.black};
`;

const AI = styled.span`
  color: ${Colors.grey4};
`;

const Image = styled.img<{ $size: number }>`
  width: auto;
  height: ${(props) => props.$size}px;
`;

interface LogoProps {
    icon?: boolean;
    size?: number;
    color?: 'black' | 'white';
    ai?: boolean;
    onClick?: () => void;
}

const Logo: FunctionComponent<LogoProps> = ({ icon=true, size=24, color='black', ai=true, onClick }) => {
    return (
      <Link to="/problem" style={{ textDecoration: 'none' }}>
        <LogoRoot $size={size} $hasOnClick={!!onClick} onClick={onClick}>
            {icon && <Image $size={size} src={'/seesolve-logo.svg'} />}
            <TextContainer $size={size}>
                <SeeSolve>seesolve</SeeSolve>
                {ai && <AI>.ai</AI>}
            </TextContainer>
        </LogoRoot>
      </Link>
    );
};

export default Logo;