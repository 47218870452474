import React from 'react';
import styled from 'styled-components';
import Icon from '../common/Icon';
import Fonts from '../common/Fonts';
import Colors, { getRandomColor, blendWithWhite } from '../common/Colors';
import { Card, CardProps, CardActionArea, StyledEngineProvider } from '@mui/material';

const StyledCard = styled(Card)<{ $borderColor: string, $borderHoverColor: string, $borderClickColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    margin: 0;
    padding: 0;
    border: 1px solid ${props => props.$borderColor} !important;
    
    transition: transform 0.2s ease, border-color 0.2s ease;
    &:hover {
        transform: scale(1.02);
        border-color: ${props => props.$borderHoverColor} !important;
    }
    
    &:active {
        border-color: ${props => props.$borderClickColor} !important;
    }
`;

const StyledCardActionArea = styled(CardActionArea)<{ $color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 0;
    margin: 0;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    .MuiCardActionArea-focusHighlight {
        display: none;
    }

    .MuiTouchRipple-root {
        color: ${props => props.$color}20;
    }

    
`;

const IconLeftSection = styled.div<{ $paleColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
    background-color: ${props => props.$paleColor};
    padding: 0;
    margin: 0;
    border-radius: 8px 0 0 8px;
`;

const TextSection = styled.div<{ $fontSize: number, $color: string }>`
    display: block;
    flex: 1;
    min-width: 0;
    min-height: 100%;
    color: ${props => props.$color};
    font-family: ${Fonts.lexendMedium.fontFamily};
    font-weight: ${Fonts.lexendMedium.fontWeight};
    font-size: ${props => props.$fontSize}px;
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 20px;
    margin: 0;
`;

const IconRightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    padding-right: 15px;
    margin: 0;
`;

interface CardButtonProps {
    name: string;
    icon?: string;
    fontSize?: number;
    onClick?: () => void;
}

const CardButton: React.FC<CardButtonProps> = ({ name, icon, fontSize = 24, onClick }) => {
    const color = Colors.blue; // getRandomColor();
    const paleColor = Colors.pale; // blendWithWhite(color, 0.2);
    const textColor = Colors.blueDark;
    const borderColor = Colors.grey1;
    const borderHoverColor = Colors.grey2;
    const borderClickColor = Colors.blue;

    return (
        <StyledCard variant="outlined" $borderColor={borderColor} $borderHoverColor={borderHoverColor} $borderClickColor={borderClickColor}>
            <StyledCardActionArea $color={color} onClick={onClick}>
                {icon && (
                    <IconLeftSection $paleColor={paleColor}>
                        <Icon icon={icon} color={color} size={fontSize*2} />
                    </IconLeftSection>
                )}
                <TextSection $fontSize={fontSize} $color={textColor}>
                    {name}
                </TextSection>
                <IconRightSection>
                    <Icon icon={"right"} color={Colors.grey4} size={fontSize*0.8} />
                </IconRightSection>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default CardButton;
