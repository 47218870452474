import { problem } from "../Generate";

export const generateDivision = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const generateCombinations = (dividend: number, divisor: number) => {
    if (divisor === 0 || problems.length >= maximum) return;

    const quotient = Math.floor(dividend / divisor);
    const remainder = dividend % divisor;

    let cols = Math.max(quotient, divisor);
    let rows = Math.min(quotient, divisor) + (remainder > 0 ? 1 : 0);
    if (quotient <= 1 || quotient > 100) return;

    count += 1;
    const template = {
      name: "Division",
      text: `${dividend} ÷ ${divisor} = __`,
      visualizations: [
        {
            type: "grid",
            rows: rows,
            cols: cols,
            counters: [...Array(rows - (remainder > 0 ? 1 : 0)).fill(cols), ...(remainder > 0 ? [remainder] : [])],
            colors: Array.from({ length: rows }, (_, index) => index < rows - 1 ? 1 : (remainder > 0 ? 2 : 1)),
            // colors: Array.from({ length: rows }, (_, index) => index + 1),
        }
      ],
      trace: {
        solution: `${dividend} ÷ ${divisor} = ${quotient}${remainder > 0 ? ` remainder ${remainder}` : ''}`,
        grade_standards: remainder > 0 || divisor > 10 ? "4.NBT.B.6: Find whole-number quotients and remainders with up to four-digit dividends and one-digit divisors, using strategies based on place value, properties of operations, and/or the relationship between multiplication and division.\n5.NBT.B.6: Find whole-number quotients of whole numbers with up to four-digit dividends and two-digit divisors, using strategies based on place value, properties of operations, and/or the relationship between multiplication and division."
          : "3.OA.A.2: Interpret whole-number quotients of whole numbers, e.g., interpret 56 ÷ 8 as the number of objects in each share when 56 objects are divided equally into 8 shares.\n3.OA.B.6: Understand division as an unknown-factor problem, e.g., find 32 ÷ 8 by finding the number that makes 32 when multiplied by 8.\n3.OA.C.7: Fluently multiply and divide within 100, using strategies such as the relationship between multiplication and division (e.g., knowing that 8 × 5 = 40, one knows 40 ÷ 5 = 8) or properties of operations.",
        plan: `A ${cols} x ${rows} grid with ${cols} counters${rows > 1 ? ` repeated ${remainder > 0 ? rows - 1 : rows} times${remainder > 0 ? ` and ${remainder} more counters` : ''}` : ''}, each in its own color, to show a final answer of ${quotient}${remainder > 0 ? ` remainder ${remainder}` : ''}.`,
      },
      planOLD: `A grid with columns set to ${cols} (the larger of the divisor or quotient) and rows set to ${rows} (the smaller of the divisor or quotient) with ${cols} counters (the larger of the divisor or quotient) ${rows > 1 ? `repeated ${remainder > 0 ? rows - 1 : rows} times, each in its own color,` : ''} to show a final answer of ${quotient}${remainder > 0 ? ` remainder ${remainder}` : ''}.`,
    };
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      name: `${template.name}: 2 values${remainder > 0 ? ' with remainder' : ''}`,
      numColors: Math.max(...template.visualizations[0].colors),
      text: template.text,
      visualizations: template.visualizations,
      trace: template.trace
    });
  };

  for (let i = 1; i <= 50; i++) {
    for (let j = 1; j <= 50; j++) {
      generateCombinations(i, j);
      if (problems.length >= maximum) return problems;
    }
  }

  return problems;
};
