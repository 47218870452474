// useContent.js
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import React, { useMemo, useCallback, useState } from 'react';
import { transformInitialContentFormat, ensureName } from '../pages/shared/TransformContent';

const GET_PROBLEM_BY_URL = gql`
  query getProblemByUrl($url: String!) {
    getProblemByUrl(url: $url) {
      id
      name
      # createdAt
      # updatedAt
      # status
      # refusalReason
      # imageIds
      numColors
      colorOverride
      
      variables
      experimental
      parts 
      # text
      # visualizations
      # expressions
      # solution
      # answers
      # content
      
      # debugFeedback
      # meta
    }
  }
`;

const GET_ALL_CONTENT = gql`
  query getAllContent {
    getAllContent {
      contentId
      problemId
      url
      name
      type
      section
      icon
      related
      description
    }
  }
`;

const PUBLISH_PROBLEM_AND_CONTENT = gql`
  mutation publishProblemAndContent($problem: String!, $content: String!) {
    publishProblemAndContent(problem: $problem, content: $content) {
      problem {
        id
        name
        numColors
        colorOverride
        variables
        experimental
        parts
      }
      content {
        contentId
        problemId
        url
        name
        type
        section
        icon
        related
        description
      }
    }
  }
`;

// Convert ID and URL
const transformContentUrlToId = (content) => {
  let transformedResponse = { ...content };
  if (content.url) {
    const problemId = content.problemId;
    transformedResponse.problemId = problemId;
    const id = content.url;
    transformedResponse.id = id;
  }
  return { ...transformedResponse };
};

const transformContentIdToUrl = (content) => {
  let transformedResponse = { ...content };
  if (content.id) {
    const url = content.id;
    const problemId = content.problemId;
    transformedResponse.url = url;
    transformedResponse.problemId = problemId;
  }
  return { ...transformedResponse };
};

const transformProblemUrlToId = (problem, url) => {
  let transformedResponse = { ...problem };
  if (problem.id) {
    transformedResponse.problemId = problem.id;
    transformedResponse.id = url;
    transformedResponse.url = url;
  }
  return { ...transformedResponse };
};

const transformContentData = (content) => {
  let transformedResponse = { ...content };
  transformedResponse.data = {
    icon: content.icon,
    description: content.description,
    related: content.related,
  };
  return { ...transformedResponse };
};

export function usePublish() {
  const [publishProblemAndContentMutation] = useMutation(PUBLISH_PROBLEM_AND_CONTENT);

  const publishProblemAndContent = useCallback(async (problem, content) => {
    if (problem && content) {
      const preparedProblem = transformContentIdToUrl(problem);
      preparedProblem.id = problem.problemId;

      let preparedContent = transformContentIdToUrl(content);
      preparedContent = transformContentData(preparedContent);
      preparedContent = transformContentData(preparedContent);
      delete preparedContent.id;
      // console.log('publishProblemAndContent input: \n', 'problem', problem, 'content', content);

      const response = await publishProblemAndContentMutation({ 
        variables: { problem: JSON.stringify(preparedProblem), content: JSON.stringify(preparedContent) } 
      });
      if (response && response.data) {
        // console.log('publishProblemAndContent response:', response.data);
        return response.data;
      }
    }
    return Promise.resolve(false);
  }, [publishProblemAndContentMutation]);

  return { publishProblemAndContent };
}

export function useGetProblemByUrl() {
  const [getProblemByUrl, { data, loading, error }] = useLazyQuery(GET_PROBLEM_BY_URL);

  const fetchProblemByUrl = useCallback(async (url) => {
    if (url) {
      const response = await getProblemByUrl({ 
        variables: { url },
        fetchPolicy: 'network-only' // Force network request, skip cache
      });
      if (response && response.data && response.data.getProblemByUrl) {
        const responseWithoutDebug = { ...response.data.getProblemByUrl, debugFeedback: null, meta: null };
        let transformedResponse = transformProblemUrlToId(responseWithoutDebug, url);
        transformedResponse = {
          ...transformedResponse,
          variables: JSON.parse(transformedResponse.variables),
          parts: JSON.parse(transformedResponse.parts)
        };
        transformedResponse = transformInitialContentFormat(transformedResponse);
        // console.log('getProblemByUrl:', transformedResponse);
        return { ...transformedResponse };
      }
    }
    return Promise.resolve(null);
  }, [getProblemByUrl]);

  const fetchRawJsonByURL = useCallback(async (url) => {
    if (url) {
      const response = await getProblemByUrl({ variables: { url } });
      if (response && response.data && response.data.getProblemByUrl) {
        return { ...response.data.getProblemByUrl };
      }
    }
    return Promise.resolve(null);
  }, [getProblemByUrl]);

  return { data, loading, error, fetchProblemByUrl, fetchRawJsonByURL };
}

export function useGetAllContent() {
  const [getAllContent, { loading, error, refetch: originalRefetch }] = useLazyQuery(GET_ALL_CONTENT);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);

  const transformAllContent = useCallback((contents) => {
    return contents.map(content => {
      let transformedContent = ensureName(content);
      transformedContent = transformContentUrlToId(transformedContent);
      if (transformedContent.data) {
        transformedContent.data = JSON.parse(transformedContent.data);
        if (transformedContent.data.icon) transformedContent.icon = transformedContent.data.icon;
        if (transformedContent.data.related) transformedContent.related = transformedContent.data.related;
        if (transformedContent.data.description) transformedContent.description = transformedContent.data.description;
        delete transformedContent.data;
      }
      return transformedContent;
    });
  }, [transformContentUrlToId]);

  const fetchAllContent = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await getAllContent();
      if (response && response.data && Array.isArray(response.data.getAllContent)) {
        const transformed = transformAllContent(response.data.getAllContent);
        setData(transformed);
        // console.log('getAllContent:', transformed);
        return transformed;
      } else {
        setData([]);
        return [];
      }
    } catch (err) {
      setIsError(true);
      setData([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [getAllContent, transformAllContent]);

  const refetch = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await originalRefetch();
      if (response && response.data && Array.isArray(response.data.getAllContent)) {
        const transformed = transformAllContent(response.data.getAllContent);
        setData(transformed);
        // console.log('getAllContent:', transformed);
        return transformed;
      } else {
        setData([]);
        return [];
      }
    } catch (err) {
      setIsError(true);
      setData([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [originalRefetch, transformAllContent]);

  return { data, loading: isLoading, error: isError, fetchAllContent, refetch };
}
