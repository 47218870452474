// ButtonLink.tsx
import { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import Icon from "../common/Icon";

export type ButtonLinkType = {
    text?: string;
    color?: "blue" | "black" | "white" | "grey";
    size?: "small" | "medium" | "large";
    icon?: string | null;
    onClick?: () => void;
};

const getColor = (color: "blue" | "black" | "white" | "grey" = "blue") => {
    switch (color) {
        case "black":
            return Colors.grey9;
        case "white":
            return Colors.grey1;
        case "blue":
            return Colors.blue;
        case "grey":
            return Colors.grey5;
        default:
            return Colors.blue;
    }
};

const getHoverColor = (color: "blue" | "black" | "white" | "grey" = "blue") => {
    switch (color) {
        case "black":
            return Colors.grey9;
        case "white":
            return Colors.grey1;
        case "blue":
            return Colors.blueLight;
        case "grey":
            return Colors.grey5;
        default:
            return Colors.blueLight;
    }
};

const getActiveColor = (color: "blue" | "black" | "white" | "grey" = "blue") => {
    switch (color) {
        case "black":
            return Colors.black;
        case "white":
            return Colors.white;
        case "blue":
            return Colors.blueDark;
        case "grey":
            return Colors.grey6;
        default:
            return Colors.blueDark;
    }
};

const getSize = (size: "small" | "medium" | "large" = "small") => {
    switch (size) {
        case "large":
            return css`
                font-size: 24px;
                line-height: 32px;
                padding: 0px 10px;
                font-family: ${Fonts.lexendSemiBold.fontFamily};
                font-weight: ${Fonts.lexendSemiBold.fontWeight};
                gap: 6px;
            `;
        case "medium":
            return css`
                font-size: 18px;
                line-height: 24px;
                padding: 0px 10px;
                font-family: ${Fonts.lexendSemiBold.fontFamily};
                font-weight: ${Fonts.lexendSemiBold.fontWeight};
                gap: 5px;
            `;
        case "small":
        default:
            return css`
                font-size: 14px;
                line-height: 22px;
                padding: 0px 10px;
                font-family: ${Fonts.lexendMedium.fontFamily};
                font-weight: ${Fonts.lexendMedium.fontWeight};
                gap: 4px;
            `;
    }
};

const ButtonLinkRoot = styled.div<{ color: "blue" | "black" | "white" | "grey"; size: "small" | "medium" | "large" }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    text-align: center;
    text-wrap: nowrap;
    cursor: pointer;
    color: ${({ color }) => getColor(color)};
    ${({ size }) => getSize(size)}

    &:hover {
        color: ${({ color }) => getHoverColor(color)}; 
        text-decoration: underline;
    }

    &:active {
        color: ${({ color }) => getActiveColor(color)};
        text-decoration: underline;
    }

    &:disabled {
        cursor: default;
    }
`;

const ButtonLink: FunctionComponent<ButtonLinkType> = ({ text, onClick = () => {}, color = "blue", size = "small", icon }) => {
    return (
        <ButtonLinkRoot color={color} size={size} onClick={onClick}>
            {icon && <Icon icon={icon} size={size === "small" ? 14 : size === "medium" ? 18 : 24} color={getColor(color)} />}
            {text}
        </ButtonLinkRoot>
    );
};

export default ButtonLink;
