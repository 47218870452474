// utils/beautifyText.js
import * as d3 from 'd3';
import _ from 'lodash';

export const convertToSuperscript = (char) => {
  const superscriptDigits = {
    '0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴', '5': '⁵',
    '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹',
    'a': 'ᵃ', 'b': 'ᵇ', 'c': 'ᶜ', 'd': 'ᵈ', 'e': 'ᵉ', 'f': 'ᶠ',
    'g': 'ᵍ', 'h': 'ʰ', 'i': 'ⁱ', 'j': 'ʲ', 'k': 'ᵏ', 'l': 'ˡ',
    'm': 'ᵐ', 'n': 'ⁿ', 'o': 'ᵒ', 'p': 'ᵖ', 'r': 'ʳ', 's': 'ˢ',
    't': 'ᵗ', 'u': 'ᵘ', 'v': 'ᵛ', 'w': 'ʷ', 'x': 'ˣ', 'y': 'ʸ', 'z': 'ᶻ'
  };

  return char.split('')
              .map(c => superscriptDigits[c] || c)
              .join('');
};

export const convertFraction = (numerator, denominator) => {
  const fractionDigits = {
    '1/2': '½', '1/3': '⅓', '2/3': '⅔', '1/4': '¼', '3/4': '¾',
    '1/5': '⅕', '2/5': '⅖', '3/5': '⅗', '4/5': '⅘',
    '1/6': '⅙', '5/6': '⅚', '1/8': '⅛', '3/8': '⅜', '5/8': '⅝', '7/8': '⅞'
  };

  const fraction = `${numerator}/${denominator}`;
  return fractionDigits[fraction] || `${numerator}/${denominator}`;
};

export const wrapText = (text, width, textAnchor = 'middle', maxLines = 3, lineHeight = 1.2) => {
  text.each(function () {
    const text = d3.select(this);
    const originalText = text.text();
    const words = originalText.split(/\s+/);
    const x = text.attr('x') || 0;
    const y = text.attr('y') || 0;
    const dy = parseFloat(text.attr('dy')) || 0;
    
    // Clear existing content
    text.text(null);
    
    let line = [];
    let lineNumber = 0;
    let tspan = text.append('tspan')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', dy + 'em')
      .attr('text-anchor', textAnchor);

    // Process each word
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      line.push(word);
      tspan.text(line.join(' '));
      
      // If line is too long and it's not a single word
      if (tspan.node().getComputedTextLength() > width && line.length > 1) {
        line.pop(); // Remove the last word
        tspan.text(line.join(' ')); // Set the completed line
        
        // Check if we've hit max lines
        if (lineNumber === maxLines - 1) {
          // Add ellipsis to last line if there are more words
          if (i < words.length - 1) {
            let lastLine = line.join(' ');
            while (tspan.node().getComputedTextLength() > width) {
              lastLine = lastLine.slice(0, -1);
              tspan.text(lastLine + '...');
            }
          }
          break;
        }

        // Start new line
        line = [word];
        tspan = text.append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .attr('text-anchor', textAnchor)
          .text(word);
      }
      // If it's a single word that's too long, keep it on its own line
      else if (tspan.node().getComputedTextLength() > width && line.length === 1) {
        let truncatedWord = word;
        while (tspan.node().getComputedTextLength() > width) {
          truncatedWord = truncatedWord.slice(0, -1);
          tspan.text(truncatedWord + '...');
        }
      }
    }
  });
};

export const shortenText = (text, maxChars) => {
  text.each(function () {
    const thisText = d3.select(this);
    const content = text.text();
    if (content.includes(' ')) {
      if (content.length > maxChars * 2) {
        thisText.text(content.substring(0, maxChars * 2 - 3) + '...');
      }
    } else {
      if (content.length > maxChars) {
        thisText.text(content.substring(0, maxChars - 3) + '...');
      }
    }
  });
};

export const getDecimalPlaces = (inputValue) => {
  const valueString = inputValue.toString();
  const decimalIndex = valueString.indexOf('.');
  let decimalPlaces = 0; // default: there are no decimal places

  if (decimalIndex !== -1) {
    decimalPlaces = valueString.length - decimalIndex - 1; // count decimal places including trailing zeros
  }
  // console.log('decimal places', decimalPlaces);
  return decimalPlaces; 
}

export const getMaxDecimalPlaces = (values) => {
  const decimalPlacesArray = values.map(getDecimalPlaces);
  // console.log('Decimal places:', values, decimalPlacesArray); 
  const maxDecimalPlaces = Math.max(...decimalPlacesArray);
  // console.log('Max decimal places:', maxDecimalPlaces); 
  return maxDecimalPlaces;
};


export const roundToPrecision = (value, decimalPrecision) => {
  if (decimalPrecision === undefined) {
    console.error('decimalPrecision is undefined, returning original value:', value);
    return value;
  }
  
  const factor = Math.pow(10, decimalPrecision);
  const roundedVal = Math.round(value * factor) / factor;
  // console.log('value', value, 'precision', decimalPrecision, 'factor', factor, 'rounded val', roundedVal);
  return roundedVal;
};