// Grid.tsx
import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../../pages/problem/HoverContext';
import { convertAllTypes } from '../../utils/convertTypes';

export interface GridProps {
  rows: number;
  cols: number;
  counters: number[][];
  colors?: string[];
  images?: string[];
  labelCounters?: boolean;
  labelRowsCols?: boolean;
  actualScale?: number;
  onStateChange?: (state: GridProps) => void;
}

interface GridData {
  row: number;
  col: number;
  hasCounter: boolean;
  color: string;
  stage: number;
  image?: string;
}

const gridPropsTypeDefinition: GridProps = {
  rows: 0,
  cols: 0,
  counters: [[0]],
  colors: [''],
  images: [''],
  actualScale: 1,
  onStateChange: () => {},
};

const Grid: React.FC<GridProps> = (props) => {
  props = convertAllTypes(props, gridPropsTypeDefinition);
  const { rows, cols, counters, colors = [], images = [], labelCounters = false, labelRowsCols = false, onStateChange } = props;
  // console.log('Grid Props:', { rows, cols, counters, colors, images });
  const MIN_SCALE = 0.37;
  let { actualScale = 1 } = props;
  if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const { handleMouseEnter, handleMouseLeave } = useHover();

  const padding = 20;
  let width = 700;
  let height = 400;
  const aspectRatio = rows / cols;
  const cellWidth = width / cols;
  const cellHeight = height / rows;
  const cellSize = Math.min(cellWidth, cellHeight);
  const counterRadius = cellSize / 4;
  const imageSize = cellSize * 0.8;
  const labelPadding = labelRowsCols === true ? { x: 40, y: 50 } : { x: 0, y: 0 };
  const totalCells = rows * cols;
  const gridData: GridData[][] = [];

  if (cols > rows) {
    height = width * aspectRatio;
  } else {
    width = height / aspectRatio;
  }

  const updateState = (stage: number) => {
    if (onStateChange) {
      const newState: GridProps = {
        ...props,
        rows,
        cols,
        counters: counters.slice(0, stage + 1),
        colors,
        images
      };
      onStateChange(newState);
    }
  };

  const render = () => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width + 2 * padding + labelPadding.x} ${height + 2 * padding + labelPadding.y}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('max-width', '100%')
      .style('max-height', '600px');

    svg.selectAll('*').remove();

    // Create grid data
    for (let stage = 0; stage < counters.length; stage++) {
      let currentCounterIndex = 0;
      let counterType = 0;

      const stageGridData = Array.from({ length: totalCells }, (_, i) => {
        if (currentCounterIndex >= counters[stage][counterType]) {
          currentCounterIndex = 0;
          counterType++;
        }

        const hasCounter = counterType < counters[stage].length && currentCounterIndex < counters[stage][counterType];
        const color = hasCounter && colors[counterType] ? colors[counterType] : Colors.vizDefault;
        const image = hasCounter && images[counterType] ? images[counterType] : undefined;
        currentCounterIndex++;
        return {
          row: Math.floor(i / cols),
          col: i % cols,
          hasCounter,
          color,
          stage,
          image
        };
      });

      gridData.push(stageGridData);
      // console.log("GRID DATA AFTER", gridData);
    }

    // Draw the outer rectangle
    svg.append('rect')
      .attr('x', padding + labelPadding.x/2)
      .attr('y', padding + labelPadding.y/2)
      .attr('width', cols * cellSize)
      .attr('height', rows * cellSize)
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', 4)
      .attr('rx', 4) // x radius for rounded corners
      .attr('ry', 4); // y radius for rounded corners


    // Create cells
    const cells = svg.selectAll('g')
      .data(gridData[0])
      .enter()
      .append('g')
      .attr('transform', d => `translate(${d.col * cellSize + padding + labelPadding.x/2}, 
        ${d.row * cellSize + padding + labelPadding.y/2})`);

    cells.append('rect')
      .attr('width', cellSize)
      .attr('height', cellSize)
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', 1);

    // Create circles or images
    cells.each(function(d, i) {
      const cell = d3.select(this);
      if (d.image) {
        cell.append('image')
          .attr('xlink:href', d.image)
          .attr('width', imageSize)
          .attr('height', imageSize)
          .attr('x', (cellSize - imageSize) / 2)
          .attr('y', (cellSize - imageSize) / 2)
          .on('click', () => {
            if (d.hasCounter) {
              handleMouseEnter(d.color);
              // console.log('grid image hover: ' + d.color);
            }
          })
          // .on('mouseleave', handleMouseLeave);
      } else {
        cell.append('circle')
          .attr('cx', cellSize / 2)
          .attr('cy', cellSize / 2)
          .attr('r', counterRadius)
          .attr('fill', 'none')
          .on('click', () => {
            if (d.hasCounter) {
              handleMouseEnter(d.color);
              // console.log('grid counter hover: ' + d.color);
            }
          })
          // .on('mouseleave', handleMouseLeave)
          .each(function() {
            const circle = d3.select(this);
            if (d.hasCounter) {
              circle
                .attr('fill', d.color)
            }
          });
        if (labelCounters === true) {
          const counterLabel = cell.append('text')
            .attr('class', 'counter-label')
            .attr('x', cellSize / 2)
            .attr('y', cellSize / 2 + 8)
            .attr('text-anchor', 'middle')
            .style('font-family', Fonts.lexendMedium.fontFamily)
            .style('font-weight', Fonts.lexendMedium.fontWeight)
            .style('font-size', '24px')
            .style('fill', Colors.white)
            .style('opacity', 0)
            .filter((d: GridData) => d.hasCounter)
            .text((d: GridData) => (d.row * cols + d.col + 1).toString());

          cell.on('mouseenter', () => {
            counterLabel.style('opacity', 1);
          }).on('mouseleave', () => {
            counterLabel.style('opacity', 0);
          });
        }
      }
    });

    const labelsGroup = svg.append('g')
      .attr('class', 'labels');

    if (labelRowsCols === true) {
      // Column labels
      labelsGroup.selectAll('.col-label')
        .data(d3.range(cols))
        .enter()
        .append('text')
        .attr('class', 'col-label')
        .attr('y', padding + 8) // above
        .attr('x', (d) => (d * cellSize) + (cellSize / 2) + padding + labelPadding.x/2)
        // .attr('y', height + padding*2 + labelPadding.y/2) // below
        // .attr('x', (d) => (d * cellSize) + (cellSize / 2) + padding)
        .attr('text-anchor', 'middle')
        .style('font-family', Fonts.lexendLight.fontFamily)
        .style('font-weight', Fonts.lexendLight.fontWeight)
        .style('font-size', '24px')
        .style('fill', (d) => (d === cols - 1 ? Colors.grey6 : Colors.grey2))
        .text((d) => `${d + 1}`);

      // Row labels
      labelsGroup.selectAll('.row-label')
        .data(d3.range(rows))
        .enter()
        .append('text')
        .attr('class', 'row-label')
        .attr('x', padding) // left
        .attr('y', (d) => (d * cellSize) + (cellSize / 2) + padding + labelPadding.y/2 + 8)
        // .attr('x', width + padding*1.5 + labelPadding.x/2) // right
        // .attr('y', (d) => (d * cellSize) + (cellSize / 2) + padding)
        .attr('text-anchor', 'middle')
        .style('font-family', Fonts.lexendLight.fontFamily)
        .style('font-weight', Fonts.lexendLight.fontWeight)
        .style('font-size', '24px')
        .style('fill', (d) => (d === rows - 1 ? Colors.grey6 : Colors.grey2))
        .text((d) => `${d + 1}`);
    }

    // Transition function to show different stages with linear easing
    let previousStage = 0;
    const showStage = (stage: number) => {
      cells.each(function(d, i) {
       
        const cell = d3.select(this);
        const currentData = gridData[stage][i];
        const previousData = gridData[previousStage][i];

        if (currentData.hasCounter && !previousData.hasCounter) {
          // Appearing counter
          if (currentData.image) {
            cell.append('image')
              .attr('xlink:href', currentData.image)
              .attr('width', 0)
              .attr('height', 0)
              .attr('x', cellSize / 2)
              .attr('y', cellSize / 2)
              .transition()
              .duration(500)
              .ease(d3.easeCubicIn)
              .attr('width', imageSize)
              .attr('height', imageSize)
              .attr('x', (cellSize - imageSize) / 2)
              .attr('y', (cellSize - imageSize) / 2);
          } else {
            cell.select('circle')
              .transition()
              .duration(500)
              .ease(d3.easeCubicIn)
              .attr('fill', currentData.color)
              .attr('r', counterRadius);
          }
        } else if (!currentData.hasCounter && previousData.hasCounter) {
          // Disappearing counter
          if (previousData.image) {
            cell.select('image')
              .transition()
              .duration(500)
              .ease(d3.easeCubicOut)
              .attr('width', 0)
              .attr('height', 0)
              .attr('x', cellSize / 2)
              .attr('y', cellSize / 2)
              .remove();
          } else {
            cell.select('circle')
              .transition()
              .duration(500)
              .ease(d3.easeCubicOut)
              .attr('fill', previousData.color)
              .attr('r', 0);
          }
        } else if (currentData.hasCounter && previousData.hasCounter) {
          // Update color for existing counters
          if (currentData.image) {
            cell.select('image')
              .attr('xlink:href', currentData.image)
              .attr('width', imageSize)
              .attr('height', imageSize)
              .attr('x', (cellSize - imageSize) / 2)
              .attr('y', (cellSize - imageSize) / 2);
          } else {
            cell.select('circle')
              .attr('fill', currentData.color);
          }
        }
      });

      previousStage = stage;
      if (counters.length > 1) {
        updateState(stage);
      }
    };

    let currentStage = 0;
    const totalStages = counters.length;
    let lastTimestamp = 0;
    let initialRun = true;

    // Automatically transition through stages every 1 second, with a 5-second delay at the end
    // console.log('grid stages', totalStages);
    const advanceStage = (timestamp) => {
      const delay = initialRun ? 1000 : (currentStage === 0 ? 5000 : 1000);
      if (timestamp - lastTimestamp >= delay) {
        showStage(currentStage);
        currentStage = (currentStage + 1) % totalStages;
        lastTimestamp = timestamp;
        initialRun = false;
      }
      requestAnimationFrame(advanceStage);
    };
    requestAnimationFrame(advanceStage);
  };

  useEffect(() => {
    render();
  }, [rows, cols, counters, colors, images, handleMouseEnter, handleMouseLeave]);

  return <svg ref={svgRef}></svg>;
};

export default Grid;
