// Publish.tsx
import React from 'react';
import styled from 'styled-components';
import Button from '../../components/basic/Button';
import { useConfig } from '../../utils/config';
import { usePublish } from '../../apollo/useContent';
import { CalculatorFetch } from '../shared/ContentFetch';

const PublishContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

interface PublishProps {
    url: string;
}

const Publish = ({ url }: PublishProps) => {
  const config = useConfig();
  const { publishProblemAndContent } = usePublish();
  
  if (!config.INTERNAL_PUBLISH) {
    return null;
  } else {
    const { getLocalCalculatorOverview, getRawLocalCalculator } = CalculatorFetch('calculator');
    const calculator = getRawLocalCalculator(url);
    const content = getLocalCalculatorOverview(url);
    
    const onPublishClick = async () => {
        if (content && calculator) {
          content.type = 'calculator';
          console.log('Publish clicked: ', url);
          console.log('Calculator:', calculator);
          console.log('Content:', content);
  
          try {
              const publishedResult = await publishProblemAndContent(calculator, content);
              console.log('Published result:', publishedResult);
          } catch (e) {
              console.error('Error publishing:', e);
          }
        } else {
          console.error('No content to publish');
        }
    };

    return (
        <PublishContainer>
            <Button text={"Publish"} color={'black'} onClick={onPublishClick}/>
        </PublishContainer>
    );
  }
};

export default Publish;