// LegalFooter.tsx
import React from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  gap: 30px;
  background-color: ${Colors.background};
`;

const FooterLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${Colors.grey6};
  font-size: 12px;
  font-weight: ${Fonts.lexendLight.fontWeight};
  font-family: ${Fonts.lexendLight.fontFamily};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${Colors.grey8};
  }
  &:click {
    text-decoration: underline;
    color: ${Colors.black};
  }
`;

const LegalFooter: React.FC = () => {
  return (
    <FooterContainer>
      <FooterLink onClick={() => window.open("/terms-of-service", "_blank")}>
        Terms of Service
      </FooterLink>
      <FooterLink onClick={() => window.open("/privacy-policy", "_blank")}>
        Privacy Policy
      </FooterLink>
    </FooterContainer>
  );
};

export default LegalFooter;
