// Scan.tsx
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Colors from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";
import Scanner from "./Scanner";
import Button from "../../components/basic/Button";
import CaptureButton from "../../components/basic/CaptureButton";
import ScanOverlay from "./ScanOverlay";
import { useImageUpload } from '../../apollo/useImageUpload';
import { useAnalyzeScan } from "../../apollo/useScan";
import CropImage from "./CropImage";
import { useConfig } from "../../utils/config";

const ScanContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  user-select: none;
`;

const BottomContainer = styled.div` 
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  background-color: ${Colors.transparent};
  border-radius: 40px;
  gap: 18%;
  padding: 10px;
  z-index: 1000;
`;

const ErrorMessage = styled.div`
  position: absolute;
  bottom: 120px;
  background-color: ${Colors.red};
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  color: ${Colors.redPale};
  text-align: center;
  z-index: 1000;
  user-select: none;
`;

const TopBar = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 74.5px;
  padding: 10px;
  gap: 10px;
  z-index: 1000;
  user-select: none;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  z-index: 1000;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  font-size: 20px;
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  text-align: center;
  gap: 10px;
  z-index: 1000;
  user-select: none;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  z-index: 1000;
`;

interface ScanProps {
  menuOffset: number;
  onScanComplete: (scanText: string, imageIds?: string[]) => void;
  closeMenu: () => void;
}

const RENDER_CAPTURED_IMAGE = true;
const RENDER_UPLOADCROPPED_IMAGE = false;

const Scan: FunctionComponent<ScanProps> = ({ menuOffset = 0, onScanComplete, closeMenu }) => {
  const config = useConfig();
  const [mode, setMode] = useState<'scan' | 'upload'>('scan');
  const [cameraStarted, setCameraStarted] = useState(false);
  const [capturedImageUrl, setCapturedImageUrl] = useState<string | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const scannerRef = useRef<any>(null);
  const captureZoneRef = useRef<HTMLDivElement>(null);
  const [cornerPositions, setCornerPositions] = useState(null); 
  const { uploadImage, loading: uploadLoading, confirmImageUpload } = useImageUpload();
  const { analyzeScanById, data: analyzeData, loading: analyzeLoading, error: analyzeError } = useAnalyzeScan();
  const [showScanAnimation, setShowScanAnimation] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const errorTimeoutRef = useRef<NodeJS.Timeout>();
  const [isScanning, setIsScanning] = useState(false);
  const [hasClipboardImage, setHasClipboardImage] = useState(false);

  const errorMessages = {
    // generic errors
    upload: "❕ Error uploading image. Please try again.",
    analyze: "❕ Error analyzing image. Please try again.",
     // no text (backend: "Invalid image.")
    analyzeInvalid: "❕ Unable to detect text. Please try again.",
    // includes image (backend: "Visual problems unsupported.")
    analyzeVisual: "❕ Oops! Visual problems not yet supported. Please try again.",
  };

  const handleCaptureClick = () => {
    reset();
    if (scannerRef.current) {
      scannerRef.current.captureAndUpload((imageDataUrl) => {
        setCapturedImageUrl(imageDataUrl);
        setShowScanAnimation(true);
      });
    }
  };

  useEffect(() => {
    if (config.PASTE_IMAGE_MESSAGE) {
      if (mode === 'scan') {
        const checkClipboardForImage = async () => {
          try {
            const clipboardItems = await navigator.clipboard.read();
            for (const item of clipboardItems) {
              for (const type of item.types) {
                if (type.startsWith('image/')) {
                  console.log('Clipboard image detected before paste:', type);
                  setHasClipboardImage(true);
                  return true; // Image found
                }
              }
            }
            return false; // No image found
          } catch (err) {
            // Silently handle permission or other errors
            console.debug('Clipboard access not available:', err);
            return false;
          }
        };

        // Check clipboard on component mount and periodically
        let clipboardCheckInterval: NodeJS.Timeout | null = null;
        
        const startClipboardCheck = async () => {
          const imageFound = await checkClipboardForImage();
          if (imageFound) {
            // Stop checking if an image is found
            if (clipboardCheckInterval) {
              clearInterval(clipboardCheckInterval);
              clipboardCheckInterval = null;
            }
          } else if (!clipboardCheckInterval) {
            // Start checking every 1 second only if not already checking
            clipboardCheckInterval = setInterval(startClipboardCheck, 1000);
          }
        };

        // Initial check
        startClipboardCheck();

        return () => {
          if (clipboardCheckInterval) {
            clearInterval(clipboardCheckInterval);
          }
        };
      } else if (mode === 'upload') {
        setHasClipboardImage(false);
      }
    }
  }, [cameraStarted, mode]);
  
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        handleCaptureClick();
      }
    };

    const handlePaste = (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (!items) return;
    
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          if (file) {
            processImageFile(file);
            break;
          }
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('paste', handlePaste);
    
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('paste', handlePaste);
    };
  }, [handleCaptureClick]);

  const processImageFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageDataUrl = e.target?.result as string;
      reset();
      setMode('upload');
      setUploadedImageUrl(imageDataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handlePasteClick = async () => {
    console.log('handle paste button click');
    try {
      const clipboardItems = await navigator.clipboard.read();
      
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          if (type.startsWith('image/')) {
            const blob = await clipboardItem.getType(type);
            const file = new File([blob], 'pasted-image.png', { type: type });
            
            processImageFile(file);
            return;
          }
        }
      }
      
      console.warn('No image found in clipboard');
    } catch (err) {
      console.error('Failed to read clipboard contents', err);
    }
  };

  const reset = () => {
    closeMenu();
    setError(null); 
    setShowScanAnimation(false);
    if (error) {
      clearTimeout(errorTimeoutRef.current);
    }
  };

  const onImageUpload = () => {
    // console.log('image button clicked');
    closeMenu();
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = async (event) => {
      setCapturedImageUrl(null);
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageDataUrl = e.target?.result as string;
          reset();
          setMode('upload');
          setUploadedImageUrl(imageDataUrl);
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const onCropUploadImage = (croppedImageUrl: string) => {
    // console.log('croppedImageUrl:', croppedImageUrl);
    if (RENDER_UPLOADCROPPED_IMAGE) {
      setUploadedImageUrl(croppedImageUrl);
    }
    reset();
    setShowScanAnimation(true);
    analyzeCapturedImage(croppedImageUrl);
  };

  const onCropBackClick = () => {
    reset();
    setMode('scan');
  };

  const analyzeCapturedImage = async (imageDataUrl: string) => {
    // console.log('Uploading image:', imageDataUrl);
    try {
      const imageUploadResult = await uploadImage(imageDataUrl);
      // console.log('imageUploadResult', imageUploadResult);
      if (imageUploadResult.success && imageUploadResult.imageId) {
        const imageId = imageUploadResult.imageId;
        const processedImageId = imageUploadResult.processedImageId;
        // console.log('Image uploaded successfully:', imageUploadResult, 'imageId:', imageId, 'processedImageId:', processedImageId);
        const analyzeResult = await analyzeScanById(processedImageId);
        // console.log('analyzeResult', analyzeResult);
        if (analyzeResult?.status === 'SUCCESS') {
          if (analyzeResult.text === '' || analyzeResult.text === null) {
            console.error('analyzeScan returned empty:', analyzeResult);
            setError(errorMessages.analyze);
          } else if (analyzeResult.text.toLowerCase().includes('invalid')) {
            setError(errorMessages.analyzeInvalid);
          } else if (analyzeResult.text.toLowerCase().includes('visual')) {
            setError(errorMessages.analyzeVisual);
          } else {
            // console.log('Scan analyzed successfully:', analyzeResult);
            onScanComplete(analyzeResult.text, [processedImageId]);
          }
        } else if (analyzeResult?.status === 'ERROR') {
          setError(errorMessages.analyze);
          console.error('analyzeScan failed:', analyzeResult);
        }
      } else if (imageUploadResult.success === false) {
        setError(errorMessages.upload);
        console.error('Image upload failed:', imageUploadResult.message);
      }
    } catch (error) {
      setError(errorMessages.analyze);
      console.error('Error in analyzeCapturedImage:', error);
    } finally {
      setIsScanning(false);
    }
  };

  useEffect(() => {
    if (error) {
      setShowScanAnimation(false);

      if (capturedImageUrl) {
        setCapturedImageUrl(null);
        errorTimeoutRef.current = setTimeout(() => {
          setError(null);
        }, 5000);

        return () => {
          if (errorTimeoutRef.current) {
            clearTimeout(errorTimeoutRef.current);
          }
        };
      }
    }
  }, [error]);

  return (
    <>
      <ScanOverlay key={'overlay'+mode}
        zIndex={1}
        rootZIndex={mode === 'scan' ? 1 : 101}
        onCornerPositionsChange={setCornerPositions} 
        captureZoneRef={captureZoneRef} 
        showScanAnimation={showScanAnimation}
        renderCapturedImage={RENDER_CAPTURED_IMAGE}
        capturedImageUrl={capturedImageUrl}
      />
      {mode === 'scan' ? (
        <>
          <ScanContainer id="scan-container">
          <Scanner
            {...{
              ref: scannerRef,
              problemId: null,
              imageUploaded: () => { },
              onNewThumbnail: () => { },
              onCameraStart: () => { setCameraStarted(true); },
              cornerPositions,
              menuOffset: menuOffset,
              analyzeCapturedImage: analyzeCapturedImage,
            }}
          />
          </ScanContainer>
          <BottomContainer>
            <Button size={'small'} icon={'image'} color={'ghost white'} onClick={()=>{onImageUpload()}} />
            <CaptureButton onClick={handleCaptureClick} disabled={!cameraStarted || capturedImageUrl !== null} /> 
            <Button size={'small'} icon={'upload'} color={'ghost white'} onClick={()=>{onImageUpload()}} />
          </BottomContainer>
        </>
      ) : (
        <>
          <TopBar>
            <Left>
              <Button size={'small'} icon={'left'} color={'ghost white'} onClick={onCropBackClick} />
            </Left>
            <Title>
              Crop one problem.
            </Title>
            <Right></Right>
          </TopBar>
          <CropImage 
            imageUrl={uploadedImageUrl} 
            onBackClick={onCropBackClick} 
            onConfirmClick={onCropUploadImage} 
            cornerPositions={cornerPositions} 
          />
          <BottomContainer>
            <Button size={'medium'} icon={'scan'} text={'Scan'} color={'white'} onClick={() => {
              if (uploadedImageUrl) {
                setIsScanning(true);
                const cropImageComponent = document.getElementById('cropImageRoot');
                if (cropImageComponent) {
                  const event = new CustomEvent('confirmCrop');
                  cropImageComponent.dispatchEvent(event);
                }
              }
            }} disabled={isScanning}/>
          </BottomContainer>
        </>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <PasteMessage 
        show={hasClipboardImage}
        onButtonClick={handlePasteClick}
      />
    </>
  );
};

export default Scan;

// PASTE MESSAGE -------------------------------------------------------------

const PasteMessageContainer = styled.div`
  position: absolute;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.black};
  border-radius: 8px;
  border: 1px solid ${Colors.white}20;
  padding: 6px 10px;
  gap: 12px;
  z-index: 1100;
`;

const PasteMessageImage = styled.img`
  height: 40px;
`;

const PasteMessageText = styled.div`
  width: 100%;
  font-family: ${Fonts.lexendSemiLight.fontFamily};
  font-weight: ${Fonts.lexendSemiLight.fontWeight};
  font-size: 16px;
  color: ${Colors.white};
  white-space: nowrap;
`;

const PasteMessage = ({ show, onButtonClick }: { show: boolean, onButtonClick: () => void }) => {
  return show ? (
    <PasteMessageContainer>
      <PasteMessageImage src="/ui-assets/ctrl-v.svg" alt="ctrl+v" />
      <PasteMessageText>
        Paste image?
      </PasteMessageText>
      <Button size={'tiny'} text={'Paste'} color={'white'} onClick={()=>{onButtonClick()}} />
    </PasteMessageContainer>
  ) : null;
};