import { problem } from "../Generate";
import { smartRound } from "../../../../utils/mathHelpers";

export const generatePercent = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const generateCombinations = (percent: number, starting_value: number, decimal_precision: number, type_name: string) => {
    count += 1;
    const correct_answer = starting_value * percent / 100;
    const template = {
        name: "Percent Of",
        numColors: 2,
        text: `What is ${percent}% of ${starting_value}?`,
        variables: {
            decimal_precision: "${"+decimal_precision+"}",
            starting_value: "${"+starting_value+"}",
            final_value: "${smartRound(starting_value * percent / 100, decimal_precision)}"
        },
        visualizations: [
            {
                type: "numberLine",
                min: 0,
                max: 100,
                tick: 100,
                data: [
                    {
                        type: "line", 
                        start: 0,
                        end: 100,
                        endVar: "max_percent",
                        endPoint: "dot",
                        endLabel: "${starting_value}",
                        startColor: 1,
                        lineColor: 1,
                        endColor: 1
                    },
                    {
                        type: "line",
                        start: 0,
                        end: percent,
                        endVar: "percent",
                        endPoint: "dot",
                        lineLabel: "${percent}%",
                        endLabel: "${final_value}",
                        startColor: 2,
                        lineColor: 2,
                        endColor: 2,
                        endDraggable: true,
                        dragTick: 1
                    }
                ]
            },
            {
                type: "numberLine",
                min: 0,
                max: 100,
                tick: 100,
                data: [
                    {
                        type: "line", 
                        start: 0,
                        end: 100,
                        endPoint: "dot",
                        endLabel: starting_value,
                        startColor: 1,
                        lineColor: 1,
                        endColor: 1
                    },
                    {
                        type: "line",
                        start: 0,
                        end: percent,
                        endPoint: "dot",
                        lineLabel: percent + "%",
                        endLabel: smartRound(correct_answer, decimal_precision),
                        startColor: 2,
                        lineColor: 2,
                        endColor: 2
                    }
                ]
            }
        ],
        answer: {
            multipleChoice: (() => {
                const options = [
                    `${smartRound(correct_answer, decimal_precision)}`,
                    `${smartRound(correct_answer * 0.92, decimal_precision)}`,
                    `${smartRound(correct_answer * 0.95, decimal_precision)}`,
                    `${smartRound(correct_answer * 0.98, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.02, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.05, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.08, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.12, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.15, decimal_precision)}`,
                    `${smartRound(correct_answer * 1.18, decimal_precision)}`
                ];
                const correctAnswer = options[0];
                const otherOptions = options.slice(1)
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 3);
                return [correctAnswer, ...otherOptions].sort();
            })(),
            multiselect: false,
            correctAnswer: [`${starting_value * percent / 100}`]
        },
        trace: {
        solution: "",
        grade_standards: "",
        plan: ""
        }
    };
    problems.push({
        id: crypto.randomUUID(),
        createdAt: new Date().toISOString(),
        name: `${template.name}: ${type_name} - Number Line`,
        numColors: template.numColors,
        variables: template.variables,
        text: template.text,
        visualizations: template.visualizations,
        answer: template.answer,
        trace: template.trace
    });
  };

  const problemSettings = [
    {
        name: "1-10",
        COUNT_TO_GENERATE: 10,
        getValue: () => Math.floor(Math.random() * 9) + 1,
        getPercent: () => Math.floor(Math.random() * 100),
        decimal_precision: 2
    },
    {
        name: "10-100", 
        COUNT_TO_GENERATE: 10,
        getValue: () => Math.floor(Math.random() * 90) + 10,
        getPercent: () => Math.floor(Math.random() * 100),
        decimal_precision: 2
    },
    {
        name: "100-10000",
        COUNT_TO_GENERATE: 10, 
        getValue: () => Math.floor(Math.random() * 9900) + 100,
        getPercent: () => Math.floor(Math.random() * 100),
        decimal_precision: 2
    },
    {
        name: "Decimal <1",
        COUNT_TO_GENERATE: 10,
        getValue: () => Number(smartRound((Math.random() * 0.99 + 0.01), 4)),
        getPercent: () => Math.floor(Math.random() * 100),
        decimal_precision: 4
    },
    {
        name: "Decimal 1-100", 
        COUNT_TO_GENERATE: 10,
        getValue: () => Number(smartRound((Math.random() * 99 + 1), 2)),
        getPercent: () => Math.floor(Math.random() * 100),
        decimal_precision: 2
    },
    {
        name: "Compare 2",
        COUNT_TO_GENERATE: 10,
        getValue1: () => Math.floor(Math.random() * 90) + 10,
        getValue2: function() {
            return Math.floor(Math.random() * 90) + 10;
        },
        getPercent1: () => Math.floor(Math.random() * 80) + 10,
        getPercent2: function() {
            return Math.floor(Math.random() * 80) + 10;
        },
        getComparison: () => Math.random() < 0.5 ? "larger" : "smaller",
        decimal_precision: 2
    }
  ];

  for (const setting of problemSettings) {
    for (let i = 0; i < setting.COUNT_TO_GENERATE && count < maximum; i++) {
        if (setting.name === "Compare 2") {
            const starting_value1 = setting.getValue1();
            const percent1 = setting.getPercent1();
            const target = starting_value1 * percent1 / 100;
            
            // Randomly choose whether to adjust value or percent
            const adjustValue = Math.random() < 0.5;
            let starting_value2, percent2;
            
            if (adjustValue) {
                // Keep percent2 random and adjust value2
                percent2 = setting.getPercent2();
                starting_value2 = Math.round((target * 100 / percent2) * (0.9 + Math.random() * 0.2));
                // Ensure within bounds
                starting_value2 = Math.max(10, Math.min(100, starting_value2));
            } else {
                // Keep value2 random and adjust percent2
                starting_value2 = setting.getValue2();
                percent2 = Math.round((target * 100 / starting_value2) * (0.9 + Math.random() * 0.2));
                // Ensure within bounds
                percent2 = Math.max(10, Math.min(100, percent2));
            }

            const decimal_precision = setting.decimal_precision;
            const comparison = setting.getComparison();
            
            const final_value1 = smartRound(starting_value1 * percent1 / 100, decimal_precision);
            const final_value2 = smartRound(starting_value2 * percent2 / 100, decimal_precision);
            const relative_max1 = starting_value1 < starting_value2 ? Math.round(starting_value2 / starting_value1 * 100) : 100;
            const relative_max2 = starting_value2 < starting_value1 ? Math.round(starting_value1 / starting_value2 * 100) : 100;

            const compare_diff = Math.abs(final_value1 - final_value2);
            const compare_min = compare_diff > 5 ? Math.floor(Math.min(final_value1, final_value2) / 10) * 10 : Math.floor(Math.min(final_value1, final_value2));
            const compare_max = compare_diff > 5 ? Math.ceil(Math.max(final_value1, final_value2) / 10) * 10 : Math.ceil(Math.max(final_value1, final_value2));
            const compare_tick = compare_diff > 5 ? 10 : 1;

            if ((final_value1 === final_value2) || (percent1 === percent2) || (starting_value1 === starting_value2)) {
                continue;
            }

            const template = {
                name: `Percent ${setting.name}`,
                numColors: 4,
                text: `Which is ${comparison}, ${percent1}% of $${starting_value1} or ${percent2}% of $${starting_value2}?`,
                variables: {
                    decimal_precision: "${"+decimal_precision+"}",
                    starting_value1: "${"+starting_value1+"}",
                    final_value1: "${smartRound(starting_value1 * percent1 / 100, decimal_precision)}",
                    starting_value2: "${"+starting_value2+"}",
                    final_value2: "${smartRound(starting_value2 * percent2 / 100, decimal_precision)}",
                    relative_max1: "${starting_value1 < starting_value2 ? Math.round(starting_value2 / starting_value1 * 100) : 100}",
                    relative_max2: "${starting_value2 < starting_value1 ? Math.round(starting_value1 / starting_value2 * 100) : 100}",
                    compare_diff: "${Math.abs(final_value1 - final_value2)}",
                    compare_min: "${compare_diff > 5 ? Math.floor(Math.min(final_value1, final_value2) / 10) * 10 : Math.floor(Math.min(final_value1, final_value2))}",
                    compare_max: "${compare_diff > 5 ? Math.ceil(Math.max(final_value1, final_value2) / 10) * 10 : Math.ceil(Math.max(final_value1, final_value2))}",
                    compare_tick: "${compare_diff > 5 ? 10 : 1}"
                },
                visualizations: [
                    {
                        type: "numberLine",
                        min: 0,
                        max: "${relative_max1}",
                        tick: "${relative_max1*100}",
                        data: [
                            {
                                type: "line",
                                start: 0,
                                end: 100,
                                endVar: "max_percent1",
                                endPoint: "dot",
                                endLabel: "$${starting_value1}",
                                startColor: 1,
                                lineColor: 1,
                                endColor: 1
                            },
                            {
                                type: "line",
                                start: 0,
                                end: percent1,
                                endVar: "percent1",
                                endPoint: "dot",
                                lineLabel: "${percent1}%",
                                endLabel: "$${final_value1}",
                                startColor: 2,
                                lineColor: 2,
                                endColor: 2,
                                endDraggable: true,
                                dragTick: 1
                            }
                        ]
                    },
                    {
                        type: "numberLine",
                        min: 0,
                        max: "${relative_max2}",
                        tick: "${relative_max2*100}",
                        data: [
                            {
                                type: "line",
                                start: 0,
                                end: 100,
                                endVar: "max_percent2",
                                endPoint: "dot",
                                endLabel: "$${starting_value2}",
                                startColor: 3,
                                lineColor: 3,
                                endColor: 3
                            },
                            {
                                type: "line",
                                start: 0,
                                end: percent2,
                                endVar: "percent2",
                                endPoint: "dot",
                                lineLabel: "${percent2}%",
                                endLabel: "$${final_value2}",
                                startColor: 4,
                                lineColor: 4,
                                endColor: 4,
                                endDraggable: true,
                                dragTick: 1
                            }
                        ]
                    },
                    {
                        type: "numberLine",
                        min: "${compare_min}",
                        max: "${compare_max}",
                        tick: "${compare_tick}",
                        data: [
                            {
                              type: "line",
                              start: "${final_value1}",
                              end: "${final_value2}",
                              startPoint: "dot",
                              startColor: -1,
                              endColor: -1,
                              lineColor: -1,
                              lineLabel: "${compare_diff < 0.1 ? ' ' : '<'}"
                            },
                            {
                              type: "line",
                              start: "${final_value1}",
                              end: "${final_value1}",
                              startPoint: "dot",
                              startColor: 2,
                              endColor: 2,
                              lineColor: 2,
                              lineLabel: "$${final_value1}"
                            },
                            {
                              type: "line",
                              start: "${final_value2}",
                              end: "${final_value2}",
                              startPoint: "dot",
                              startColor: 4,
                              endColor: 4,
                              lineColor: 4,
                              lineLabel: "$${final_value2}"
                            }
                          ]
                    },
                    {
                        type: "numberLine",
                        min: 0,
                        max: relative_max1,
                        tick: relative_max1 * 100,
                        data: [
                            {
                                type: "line",
                                start: 0,
                                end: 100,
                                endPoint: "dot",
                                endLabel: `$${starting_value1}`,
                                startColor: 1,
                                lineColor: 1,
                                endColor: 1
                            },
                            {
                                type: "line",
                                start: 0,
                                end: percent1,
                                endPoint: "dot",
                                lineLabel: `${percent1}%`,
                                endLabel: `$${final_value1}`,
                                startColor: 2,
                                lineColor: 2,
                                endColor: 2
                            }
                        ]
                    },
                    {
                        type: "numberLine",
                        min: 0,
                        max: relative_max2,
                        tick: relative_max2 * 100,
                        data: [
                            {
                                type: "line",
                                start: 0,
                                end: 100,
                                endPoint: "dot",
                                endLabel: `$${starting_value2}`,
                                startColor: 3,
                                lineColor: 3,
                                endColor: 3
                            },
                            {
                                type: "line",
                                start: 0,
                                end: percent2,
                                endPoint: "dot",
                                lineLabel: `${percent2}%`,
                                endLabel: `$${final_value2}`,
                                startColor: 4,
                                lineColor: 4,
                                endColor: 4
                            }
                        ]
                    },
                    {
                        type: "numberLine",
                        min: compare_min,
                        max: compare_max,
                        tick: compare_tick,
                        data: [
                            {
                              type: "line",
                              start: final_value1,
                              end: final_value2,
                              startPoint: "dot",
                              startColor: -1,
                              endColor: -1,
                              lineColor: -1,
                              lineLabel: compare_diff < 0.1 ? ' ' : '<'
                            },
                            {
                              type: "line",
                              start: final_value1,
                              end: final_value1,
                              startPoint: "dot",
                              startColor: 2,
                              endColor: 2,
                              lineColor: 2,
                              lineLabel: '$' + final_value1.toString()
                            },
                            {
                              type: "line",
                              start: final_value2,
                              end: final_value2,
                              startPoint: "dot",
                              startColor: 4,
                              endColor: 4,
                              lineColor: 4,
                              lineLabel: '$' + final_value2.toString()
                            }
                          ]
                    }
                ],
                answer: {
                    multipleChoice: [
                        `${percent1}% of $${starting_value1}`,
                        `${percent2}% of $${starting_value2}`
                    ],
                    multiselect: false,
                    correctAnswer: [
                        (comparison === "larger" ? final_value1 >= final_value2 : final_value1 <= final_value2) ? 
                            `${percent1}% of $${starting_value1}` : 
                            `${percent2}% of $${starting_value2}`
                    ]
                },
                trace: {
                    solution: "",
                    grade_standards: "",
                    plan: ""
                }
            };
            problems.push({
                id: crypto.randomUUID(),
                createdAt: new Date().toISOString(),
                name: `${template.name} - Number Line`,
                numColors: template.numColors,
                variables: template.variables,
                text: template.text,
                visualizations: template.visualizations,
                answer: template.answer,
                trace: template.trace
            });
        } 
        else {
            generateCombinations(
                setting.getPercent(),
                setting.getValue(),
                setting.decimal_precision,
                setting.name
            );
        }
    }
  }

  return problems;
};