// ForgotPassword.tsx
import React, {FunctionComponent, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../../components/basic/Input";
import Button from "../../components/basic/Button";
import ButtonLink from "../../components/basic/ButtonLink";
import Fonts from "../../components/common/Fonts";
import Colors from "../../components/common/Colors";
import { useRequestPasswordReset } from '../../apollo/useAuth';
import Toast from "../../components/basic/Toast";
import { Helmet } from "react-helmet-async";

const ForgotRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.background};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.lexendMedium.fontFamily};
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const CenterContainer = styled.div`
  margin: 0;
  width: 343px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
`;
const ForgotHeader = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.lexendMedium.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
`;
const SubText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  padding-bottom: 26px;
  cursor: text;
`;
const InputContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
`;

const BottomContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const ToastContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
`;

const ForgotPassword: FunctionComponent = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const { requestPasswordReset, error: forgotError, data: forgotData } = useRequestPasswordReset();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const onRequestResetClick = async () => {
      if (isLoading) return;
      setIsLoading(true);

      if (!email.includes('@') || !email.includes('.')) {
        setError('Please enter a valid email.');
        setIsLoading(false);
        return;
      }

      try {
          await requestPasswordReset(email);
          console.log('Password reset email sent.');
          setShowToast(true);
      } catch (error) {
          console.error('Request Reset error:', error);
          if (error.message.includes('User not found')) {
            setError('Email not found. Please sign up first.');
            setIsLoading(false);
            return;
          } else {
            setError('Error sending reset password email.');
            setIsLoading(false);
            return;
          }
      }
    };  

    const onLoginClick = () => {
        navigate("/log-in"); 
    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          onRequestResetClick();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onRequestResetClick]);

    return (
        <ForgotRoot>
          <Helmet>
            <title>SeeSolve - Forgot Password</title>
            <meta name="description" content="The #1 AI for learning math. Solve any problem. Get unstuck. Learn faster. With interactive visualizations and step-by-step calculators." />
          </Helmet>
          <Logo src="/seesolve-logo.svg" alt="SeeSolve Logo" />
            {showToast && (
              <ToastContainer>
                <Toast color={'white'} bodyText={"Password reset requested! Check your email."} icon={'logo'} />
              </ToastContainer>
            )}
            <CenterContainer>
                <ForgotHeader>Forgot Password?</ForgotHeader>
                <SubText>No worries, we’ll email you reset instructions.</SubText>
                <InputContainer>
                    <Input
                        placeholder="Email"
                        icon={'mail'}
                        value={email} 
                        onChange={(event) => setEmail(event.target.value)}
                        errorMessage={error}
                    />
                </InputContainer>
                <ButtonContainer>
                    <Button text="REQUEST RESET" onClick={onRequestResetClick} size={'small'} fixed={true} color={'black'} icon={isLoading ? 'loading' : ''} />
                </ButtonContainer>
            </CenterContainer>
            <BottomContainer>
                <BottomFixed>
                    <FooterText>Back to</FooterText>
                    <ButtonLink text="Log In" onClick={onLoginClick} />
                </BottomFixed>
            </BottomContainer>
        </ForgotRoot>
    );
};

export default ForgotPassword;