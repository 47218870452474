// Input.tsx
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Icon from '../common/Icon';
import Colors from '../common/Colors';
import Fonts from '../common/Fonts';

export type InputType = {
    inputType?: string;
    icon?: string;
    type?: string;
    small?: boolean;
    ghost?: boolean;
    width?: string;
    value?: string | number | readonly string[] | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errorMessage?: string;
    placeholder?: string;
    autoFocus?: boolean;
};

const Input: React.FC<InputType> = ({
    inputType,
    width = '100%', // Default width is 100%
    icon,
    type = 'text', // Default to 'text' if not provided
    small = false,
    ghost = false,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    errorMessage,
    placeholder,
    autoFocus = false,
}) => {
    
    const [isActive, setIsActive] = useState(false);
    const iconColor = errorMessage ? Colors.red : (isActive ? Colors.blue : Colors.grayDark);

    const parentRef = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentWidth(parentRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [value]);

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const [inputWidth, setInputWidth] = useState('146px');
    useEffect(() => {
        const padding = 40;
        const minWidth = 146;
        const maxWidth = parentWidth || minWidth;
        const textWidth = getTextWidth(value as string, small ? 16 : 18, Fonts.lexendMedium.fontWeight, Fonts.lexendMedium.fontFamily) + padding;
    
        let calculatedWidth = Math.max(minWidth, Math.min(textWidth, maxWidth));
        if (parentWidth && parentWidth < parseInt(inputWidth)) {
            calculatedWidth = Math.max(minWidth, Math.min(textWidth, parentWidth));
        }
        setInputWidth(`${minWidth}px`);
    }, [parentWidth, value, small, inputWidth]);

    const getTextWidth = (text: string, fontSize: number, fontWeight: number, fontFamily: string) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
            context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
            const metrics = context.measureText(text);
            return metrics.width;
        }
        return 0;
    };

    return (
        <div ref={parentRef} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <TextField
                value={value}
                onChange={(event) => {
                    if (onChange) onChange(event);
                }}
                onFocus={(event) => {
                    setIsActive(true);
                    if (onFocus) onFocus(event);
                }}
                onBlur={(event) => {
                    setIsActive(false);
                    if (onBlur) onBlur(event);
                }}
                variant="outlined"
                type={type}
                sx={{
                    width: small ? inputWidth : width,
                    paddingBottom: errorMessage ? '0px' : '16px',
                    "& .MuiInputBase-root": {
                        height: small ? '36px' : '52px',
                        backgroundColor: ghost ? 'transparent' : Colors.white,
                        paddingLeft: small ? '10px' : '16px',
                        borderRadius: "121px",
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: small ? "16px" : "18px",
                        lineHeight: small ? "22px" : "28px",
                        fontFamily: Fonts.lexendMedium.fontFamily,
                        fontWeight: Fonts.lexendMedium.fontWeight,
                        color: Colors.grayDark,
                        "& .MuiOutlinedInput-notchedOutline": ghost ? { border: "none", } : {},
                        "&:hover .MuiOutlinedInput-notchedOutline": ghost ? { border: `1px solid ${Colors.grayLight}`, } : {},
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: `2px solid ${Colors.blue}`, },
                    },
                    "& .MuiInputBase-input": {
                        paddingLeft: "4px",
                        paddingTop: small ? "calc((36px - 22px) / 2)" : "calc((52px - 28px) / 2)",
                        paddingBottom: small ? "calc((36px - 22px) / 2)" : "calc((52px - 28px) / 2)",
                        "&::placeholder": {
                            color: Colors.grayMed,
                            opacity: 1,
                        },
                        "&:-webkit-autofill": {
                            fontSize: small ? "16px" : "18px",
                            lineHeight: small ? "22px" : "28px",
                            fontFamily: `${Fonts.lexendMedium.fontFamily} !important`,
                            fontWeight: `${Fonts.lexendMedium.fontWeight} !important`,
                            color: Colors.grayDark,
                            WebkitTextFillColor: `${Colors.grayDark} !important`, 
                        },
                    },
                    "& .MuiFormHelperText-root": {
                        marginLeft: "22px",
                        fontFamily: Fonts.lexendMedium.fontFamily, 
                        fontWeight: Fonts.lexendMedium.fontWeight,
                        fontSize: "10px",
                        marginTop: "0px",
                        color: Colors.red,
                    },
                }}
                InputProps={{
                    startAdornment: icon ? <Icon icon={icon} color={iconColor} size={small ? 20 : 24} /> : null,
                    inputRef: inputRef,
                    onKeyDown: onKeyDown, 
                }}
                error={!!errorMessage}
                helperText={errorMessage}
                placeholder={placeholder}
            />
        </div>
    );
};

export default Input;
