// useAuth.js
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { tokenStorage } from './tokenStorage';

const REGISTER_MUTATION = gql`
  mutation register($name: String, $email: String!, $pass: String!, $subjects: [String], $grades: [String]) {
    register(name: $name, email: $email, pass: $pass, subjects: $subjects, grades: $grades) {
      user {
        id
        name
        email
        role
      }
      token
      waitlist
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $pass: String!) {
    login(email: $email, pass: $pass) {
      user {
        id
        email
        role
      }
      token
      waitlist
    }
  }
`;

const GET_USER_INFO_QUERY = gql`
  query getUserInfo {
    getUserInfo {
      id
      name
      email
      role
      subjects
      grades
    }
  }
`;

export function useGetUserInfo() {
  const { data, loading, error } = useQuery(GET_USER_INFO_QUERY);
  return { data, loading, error };
}

export function getIsLoggedIn() {
  return !!tokenStorage.getToken();
}

export function useLogin() {
    const navigate = useNavigate();
    const [loginMutation, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN_MUTATION, {
        onCompleted: (data) => {
          if (data.login.token) { // Check if token is present
                // console.log("login 4");
                tokenStorage.setToken(data.login.token);
                // console.log("login 5");
            }
        },
    });

    const login = async (email, pass) => {
        try {
            await loginMutation({ variables: { email, pass } });
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return { login, loginData, loginLoading, loginError };
}

export function useRegister() {
    const navigate = useNavigate();
    const [registerMutation, { data: registerData, loading: registerLoading, error: registerError }] = useMutation(REGISTER_MUTATION, {
        onCompleted: (data) => {
            // console.log("Registration complete data", data);
            if (data.register.token) { // Check if token is present 
              tokenStorage.setToken(data.register.token);
            }
        },
    });

    const register = async (name, email, pass, subjects, grades) => {
        try {
            await registerMutation({ variables: { name, email, pass, subjects, grades } });
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return { register, registerData, registerLoading, registerError };
}

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($role: String, $subjects: [String!], $grades: [String!]) {
    updateProfile(role: $role, subjects: $subjects, grades: $grades) {
        id
        email
        role
        subjects
        grades
      }
  }
`;

export function useUpdateProfile() {
  const [updateProfileMutation, { data, loading, error }] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted: (data) => {
      // console.log('Profile update successful:', data);
    },
  });

  const updateProfile = async (role, subjects, grades) => {
    try {
      await updateProfileMutation({ variables: { role, subjects, grades } });
    } catch (error) {
      console.error('Update Profile error:', error);
    }
  };

  return { updateProfile, data, loading, error };
}

export function useLogout() {
    const client = useApolloClient();

    const logout = async () => {
        try {
            // Clear the token from localStorage
            tokenStorage.clearToken();

            // Erase Apollo client cache
            await client.clearStore();

            // Alt optio reset the store and refetch active queries:
            // await client.resetStore();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return { logout };
}

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

const DO_PASSWORD_RESET_MUTATION = gql`
  mutation doPasswordReset($token: String!, $pass: String!) {
    doPasswordReset(token: $token, pass: $pass) {
      user {
        id
        email
      }
      token
    }
  }
`;

export function useRequestPasswordReset() {
  const [requestPasswordResetMutation, { data, loading, error }] = useMutation(REQUEST_PASSWORD_RESET_MUTATION);

  const requestPasswordReset = async (email) => {
      const response = await requestPasswordResetMutation({ variables: { email } });
      if (response) {
        return response;
      }
  };

  return { requestPasswordReset, data, loading, error };
}

export function useDoPasswordReset() {
    const [doPasswordResetMutation, { data, loading, error }] = useMutation(DO_PASSWORD_RESET_MUTATION, {
        onCompleted: (data) => {
            if (data.doPasswordReset.token) {
              tokenStorage.setToken(data.doPasswordReset.token);
            }
        },
    });

    const doPasswordReset = async (token, pass) => {
        const response = await doPasswordResetMutation({ variables: { token, pass } });
        if (response) {
          return response;
        }
    };

    return { doPasswordReset, data, loading, error };
}