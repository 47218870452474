// Tabs.tsx
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled, { css } from 'styled-components';
import Fonts from '../../components/common/Fonts';
import Colors from '../../components/common/Colors';

const StyledTabs = styled(Tabs)`
  max-width: 100%;
  
  .MuiTabs-indicator {
    background-color: transparent;
  }
`;

const StyledTab = styled(Tab)<{ $selected: boolean }>`
  font-family: ${() => `${Fonts.lexendMedium.fontFamily}`};
  font-weight: ${() => `${Fonts.lexendMedium.fontWeight}`};
  font-size: 18px;
  text-transform: none;
  padding: 8px;
  color: ${({ $selected }) => ($selected ? Colors.grey1 : Colors.grey6)};
  background-color: ${Colors.background};
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${Colors.white};
  }

  ${({ $selected }) =>
    css`
      font-weight: ${$selected ? 'bold' : 'normal'};
      border-bottom: 2px solid ${$selected ? Colors.blue : Colors.grey1};
    `}
`;

interface TabsProps {
  labels: string[];
  selectedIndex: number;
  onChange: (index: number) => void;
}

const CustomTabs: React.FC<TabsProps> = ({ labels, selectedIndex, onChange }) => {
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    onChange(newIndex);
  };

  return (
    <StyledTabs
      value={selectedIndex}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons={false}
    >
      {labels.map((label, index) => (
        <StyledTab key={index} label={label} $selected={selectedIndex === index} />
      ))}
    </StyledTabs>
  );
};

export default CustomTabs;
