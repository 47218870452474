// ButtonFeedback.tsx
import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import Icon from "../common/Icon";
import Colors, { blendWithWhite } from "../common/Colors";
import Fonts from "../common/Fonts";

const Text1 = styled.div<{ color: string }>`
    font-size: 16px;
    line-height: 1.4em;
    font-weight: ${Fonts.lexendMedium.fontWeight};
    font-family: ${Fonts.lexendMedium.fontFamily};
    color: ${({ color }) => color};
    text-align: left;
`;

const ButtonPrimaryRoot = styled.button<{
    disabled?: boolean,
    $noText?: boolean,
    $backgroundColor: string,
    $borderColor: string,
    $iconTextColor: string,
}>`
    box-sizing: border-box;
    border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
    color: ${({ $iconTextColor }) => $iconTextColor};
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 14px;
    height: 35px; 
    width: auto;
    padding: ${({ $noText }) => $noText ? '7px' : '7px 12px 7px 12px'};
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 6px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

export interface ButtonFeedbackProps {
    icon?: string;
    text?: string;
    disabled?: boolean;
    selected?: boolean;
    successIcon?: string;
    successText?: string;
    feedbackPermanent?: boolean;
    onClick: (event?: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement> | undefined) => void;
}

const ButtonFeedback: FunctionComponent<ButtonFeedbackProps> = ({ icon, text, disabled = false, selected = false, successIcon, successText, feedbackPermanent = false, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [currentIcon, setCurrentIcon] = useState(icon);
    const [currentText, setCurrentText] = useState(text);
    const [showingFeedback, setShowingFeedback] = useState(false);

    useEffect(() => {
        if (disabled) {
            setIsHovered(false);
            setIsActive(false);
        }
    }, [disabled]);

    useEffect(() => {
        // Show success state when selected
        if (selected) {
            setShowingFeedback(true);
            setCurrentIcon(successIcon);
            setCurrentText(successText);
        } else {
            setShowingFeedback(false);
            setCurrentIcon(icon);
            setCurrentText(text);
        }
    }, [selected, successIcon, successText, icon, text]);

    const onClickRaw = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!disabled && (successIcon || successText)) {
            setShowingFeedback(true);
            setCurrentIcon(successIcon);
            setCurrentText(successText);
            
            if (!feedbackPermanent) {
                setTimeout(() => {
                    setShowingFeedback(false);
                    setCurrentIcon(icon);
                    setCurrentText(text);
                }, 1500);
            }
            onClick(e);
        }
    };

    const state = disabled ? 'disabled' : isActive ? 'active' : isHovered ? 'hover' : 'default';
    const backgroundColor = 
        state === 'hover' ? Colors.black+5 // Colors.white
        : state === 'active' ? Colors.black+10
        : state === 'disabled' ? Colors.transparent
        : Colors.transparent;
    const borderColor = Colors.transparent;
    const iconTextColor = 
        state === 'disabled' ? blendWithWhite(Colors.grey5, 0.4) 
        : state === 'hover' ? Colors.grey6
        : state === 'active' ? Colors.grey7
        : Colors.grey5;
    const iconSize = 18;

    return (
        <ButtonPrimaryRoot
            onClick={onClickRaw}
            disabled={disabled}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            $iconTextColor={iconTextColor}
            $noText={!currentText}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
            onMouseDown={() => !disabled && setIsActive(true)}
            onMouseUp={() => !disabled && setIsActive(false)}
            onTouchStart={() => !disabled && setIsActive(true)}
            onTouchEnd={() => !disabled && setIsActive(false)}
        >
            {currentIcon && <Icon onClick={()=>{}} icon={currentIcon} color={iconTextColor} size={iconSize} />}
            {currentText && <Text1 color={iconTextColor}>{currentText}</Text1>}
        </ButtonPrimaryRoot>
    );
};

export default ButtonFeedback;