// CropImage.tsx
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import Button from '../../components/basic/Button';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100svh;
  background-color: ${Colors.grey8};
  gap: 10px;
  overflow: hidden;
  z-index: 100;
  user-select: none;
`;

const ImageContainer = styled.div<{ $renderDebug: boolean, $cropDimensions?: any }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.grey8};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100svh;
  max-height: 100%;
  z-index: 0;
  overflow: hidden;
  user-select: none;

  img {
    display: inline;
    max-width: 90%;
    max-height: 78%;
    object-fit: contain;
    ${props => props.$renderDebug && props.$cropDimensions && `
      clip-path: inset(
        ${props.$cropDimensions.top}px
        ${props.$cropDimensions.right}px
        ${props.$cropDimensions.bottom}px
        ${props.$cropDimensions.left}px
      );
    `}
  }
`;

const CornerMarker = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.white};
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

interface CropImageProps {
  imageUrl: string;
  onBackClick: () => void;
  onConfirmClick: (croppedImageUrl: string) => void;
  cornerPositions?: any;
}

const calculateMarkerPosition = (cornerPos: { x: number, y: number }, containerRect: DOMRect) => {
    return {
        left: cornerPos.x - containerRect.left,
        top: cornerPos.y - containerRect.top
    };
};

const DEBUG_RENDER_CORNER_MARKERS = false;

const CropImage: FunctionComponent<CropImageProps> = ({ 
  imageUrl, 
  onBackClick, 
  onConfirmClick,
  cornerPositions
}) => {
    const [cropDimensions, setCropDimensions] = useState(null);

    const isValidCornerPositions = cornerPositions && 
      cornerPositions.topLeft && 
      cornerPositions.topRight && 
      cornerPositions.bottomRight && 
      cornerPositions.bottomLeft;

    const calculateCropDimensions = () => {
        if (!isValidCornerPositions) return null;
        
        const img = document.querySelector('#cropImageContainer img') as HTMLImageElement;
        if (!img) return null;

        const imageRect = img?.getBoundingClientRect();
        const { topLeft, topRight, bottomRight, bottomLeft } = cornerPositions;

        if (!imageRect || 
            typeof topLeft.y !== 'number' || 
            typeof topRight.x !== 'number' || 
            typeof bottomRight.y !== 'number' || 
            typeof topLeft.x !== 'number') {
            return null;
        }

        return {
            top: Math.max(0, topLeft.y - imageRect.top),
            right: Math.max(0, imageRect.right - topRight.x),
            bottom: Math.max(0, imageRect.bottom - bottomRight.y),
            left: Math.max(0, topLeft.x - imageRect.left)
        };
    };

    useEffect(() => {
        const img = document.querySelector('#cropImageContainer img') as HTMLImageElement;
        if (!img) return;

        const handleImageLoad = () => {
            setCropDimensions(calculateCropDimensions());
        };

        // Call immediately in case image is already loaded
        if (img.complete) {
            handleImageLoad();
        }

        img.addEventListener('load', handleImageLoad);
        return () => img.removeEventListener('load', handleImageLoad);
    }, [cornerPositions]);

    // Update crop dimensions whenever corner positions change
    useEffect(() => {
        setCropDimensions(calculateCropDimensions());
    }, [cornerPositions]);

    const handleConfirmClick = () => {
        // console.log('Corner positions:', cornerPositions);
        if (!isValidCornerPositions) {
            console.warn('Invalid corner positions, using full image');
            onConfirmClick(imageUrl);
            return;
        }

        const img = document.querySelector('#cropImageContainer img') as HTMLImageElement;
        if (!img || !cropDimensions) {
            console.warn('Missing image or crop dimensions');
            onConfirmClick(imageUrl);
            return;
        }

        // Calculate the cropped dimensions based on the image's natural dimensions
        const imageRect = img.getBoundingClientRect();
        // console.log('Image rect:', imageRect);
        // console.log('Crop dimensions:', cropDimensions);

        // Create a canvas to draw and crop the image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Calculate the cropped dimensions based on the image's natural dimensions
        const imageRatio = img.naturalWidth / imageRect.width;

        // Calculate the actual displayed image dimensions
        const displayedWidth = img.width;
        const displayedHeight = img.height;

        // Calculate crop coordinates relative to the image
        const cropLeft = cropDimensions.left;
        const cropTop = cropDimensions.top;
        const cropRight = cropDimensions.right;
        const cropBottom = cropDimensions.bottom;

        // Scale the crop coordinates to the natural image size
        const scaledLeft = cropLeft * imageRatio;
        const scaledTop = cropTop * imageRatio;
        const scaledRight = cropRight * imageRatio;
        const scaledBottom = cropBottom * imageRatio;

        const croppedWidth = img.naturalWidth - scaledLeft - scaledRight;
        const croppedHeight = img.naturalHeight - scaledTop - scaledBottom;

        // Set canvas size to the cropped dimensions
        canvas.width = croppedWidth;
        canvas.height = croppedHeight;

        // Draw only the cropped portion of the image at full resolution
        ctx.drawImage(
            img,
            scaledLeft,
            scaledTop,
            croppedWidth,
            croppedHeight,
            0,
            0,
            croppedWidth,
            croppedHeight
        );

        // Convert canvas to data URL
        const croppedImageUrl = canvas.toDataURL('image/png', 0.95);
        onConfirmClick(croppedImageUrl);
    };

    const renderCornerMarkers = () => {
        if (!cornerPositions) return null;
    
        const container = document.getElementById('cropImageContainer');
        if (!container) return null;
        
        const containerRect = container.getBoundingClientRect();
        
        return (
            <>
                <CornerMarker style={calculateMarkerPosition(cornerPositions.topLeft, containerRect)} />
                <CornerMarker style={calculateMarkerPosition(cornerPositions.topRight, containerRect)} />
                <CornerMarker style={calculateMarkerPosition(cornerPositions.bottomRight, containerRect)} />
                <CornerMarker style={calculateMarkerPosition(cornerPositions.bottomLeft, containerRect)} />
            </>
        );
    };

    useEffect(() => {
        const root = document.getElementById('cropImageRoot');
        if (!root) return;

        const handleConfirmEvent = () => {
            handleConfirmClick();
        };

        root.addEventListener('confirmCrop', handleConfirmEvent);
        return () => root.removeEventListener('confirmCrop', handleConfirmEvent);
    }, [handleConfirmClick]);

    return (
        <Root id="cropImageRoot">
            <ImageContainer id="cropImageContainer" $cropDimensions={cropDimensions} $renderDebug={DEBUG_RENDER_CORNER_MARKERS}>
                <img src={imageUrl} alt="cropped image" />
                {DEBUG_RENDER_CORNER_MARKERS && renderCornerMarkers()}
            </ImageContainer>
        </Root>
    );
};

export default CropImage;