// ProblemFooter.tsx
import React, { FunctionComponent, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Colors, { getColorViz } from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";
import Button from "../../components/basic/Button";
import ButtonFeedback from "../../components/basic/ButtonFeedback";
import ButtonFeedbackGroup from "../../components/basic/ButtonFeedbackGroup";
import { useSubmitFeedback } from "../../apollo/useFeedback";
import MenuOpen from "../../components/basic/MenuOpen";
import { useConfig } from "../../utils/config";

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: fit-content;
  flex: 1;
  z-index: 10;
  gap: 5px;
`;

const FeedbackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 860px;
  
  padding: 0px 10px;
  @media (min-width: 800px) {
    padding: 0px 32px;
  }
  margin: 5px 10px;
  box-sizing: border-box;
  gap: 10px;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 0px;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 0px;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  color: ${Colors.grey6};
  font-family: ${Fonts.lexendLight.fontFamily};
  font-weight: ${Fonts.lexendLight.fontWeight};
  text-wrap: balance;
`;

interface ProblemFooterProps {  
  problem: any;
}

const ProblemFooter: FunctionComponent<ProblemFooterProps> = ({ problem }) => {
  const config = useConfig();
  const [thumbSelected, setThumbSelected] = useState<'up' | 'down' | null>(null);
  const { submitFeedbackById } = useSubmitFeedback();
  const hasSolution = problem?.parts?.some(part => part.solution);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [copyMenuOpen, setCopyMenuOpen] = useState(false);

  const copyOptions = [
    {
      text: 'Copy Problem',
      icon: 'copy',
      onClick: () => {
        console.log('Copying problem');
        copyToClipboard('question', false);
        handleCopyMenuClose();
      }
    },
    {
      text: 'Copy Problem LaTeX',
      icon: 'copy',
      onClick: () => {
        console.log('Copying problem LaTeX');
        copyToClipboard('question', true);
        handleCopyMenuClose();
      }
    },
    {
      text: 'Copy Answer',
      icon: 'copy',
      onClick: () => {
        console.log('Copying answer');
        copyToClipboard('answer', false);
        handleCopyMenuClose();
      }
    },
    {
      text: 'Copy Answer LaTeX',
      icon: 'copy',
      onClick: () => {
        console.log('Copying answer LaTeX');
        copyToClipboard('answer', true);
        handleCopyMenuClose();
      }
    },
    {
      text: 'Copy Solution',
      icon: 'copy',
      onClick: () => {
        console.log('Copying solution');
        copyToClipboard('solution', false);
        handleCopyMenuClose();
      }
    },
    {
      text: 'Copy Solution LaTeX',
      icon: 'copy',
      onClick: () => {
        console.log('Copying solution LaTeX');
        copyToClipboard('solution', true);
        handleCopyMenuClose();
      }
    }
  ];

  const onShareClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      // console.log('Link copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const onThumbClick = async (type: 'up' | 'down') => {
    // console.log(`thumbs ${type} clicked`);
    setThumbSelected(type);
    try {
      const feedbackResponse = await submitFeedbackById(problem?.id, type === 'up' ? 'POSITIVE' : 'NEGATIVE');
      // console.log('feedbackResponse', feedbackResponse);
    } catch (error) {
      console.error('Error submitting feedback: ', error);
    }
  };

  const onCopyOptionsClick = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    // if (event && 'currentTarget' in event) {
      setAnchorEl(event.currentTarget);
    // }
    setCopyMenuOpen(true);
  };

  const handleCopyMenuClose = () => {
    setCopyMenuOpen(false);
    setAnchorEl(null);
  };

  const copyToClipboard = ( content?: 'question' | 'solution' | 'answer', latex?: boolean ) => {
    // TODO: question, answer, latex

    // Get all solution elements
    const solutionElements = document.querySelectorAll(`#solution`);
    if (solutionElements.length > 0) {
      // Get text content from all solution elements
      const solutionText = Array.from(solutionElements)
      .map(el => el.textContent?.trim())
      .filter(text => text) // Remove any null/empty strings
      .join('\n\n'); // Join with newlines to preserve formatting
        
      // Copy to clipboard
      navigator.clipboard.writeText(solutionText).then(() => {
        // console.log('Solution copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy solution: ', err);
      });
    }
  };

  return (
    <BottomContainer>
        <FeedbackContainer>
            <Left>
              {config.COPY_OPTIONS ? (
                <>
                <ButtonFeedback icon={'copy'} 
                  successIcon={hasSolution ? 'check-slim' : null} 
                  successText={hasSolution ? 'Copied' : null} 
                  feedbackPermanent={false} 
                  onClick={(event)=>{onCopyOptionsClick(event)}} 
                  disabled={!hasSolution}
                />
                <MenuOpen 
                  anchorEl={anchorEl}
                  open={copyMenuOpen}
                  onClose={handleCopyMenuClose}
                  options={copyOptions}
                />
                </>
              ) : (
                <ButtonFeedback icon={'copy'} 
                  successIcon={hasSolution ? 'check-slim' : null} 
                  successText={hasSolution ? 'Copied' : null} 
                  feedbackPermanent={false} 
                  onClick={()=>copyToClipboard()} 
                  disabled={!hasSolution}
                />
              )}
              
              {problem?.status !== 'ERROR' && (
                <ButtonFeedback text={"Share"} icon={'link'} 
                successIcon={'check-slim'} 
                successText={'Copied'} 
                feedbackPermanent={false} 
                onClick={()=>{onShareClick()}} />
              )}
            </Left>
            <Right>
                <ButtonFeedbackGroup options={[
                  {
                    icon: 'thumbs-up-outline',
                    successIcon: 'thumbs-up-fill',
                    feedbackPermanent: true,
                    selected: thumbSelected === 'up'
                  },
                  {
                    icon: 'thumbs-down-outline', 
                    successIcon: 'thumbs-down-fill',
                    feedbackPermanent: true,
                    selected: thumbSelected === 'down'
                  }
                ]}
                onChange={(selectedIndex: number) => {
                  onThumbClick(selectedIndex === 0 ? 'up' : 'down');
                }}
              />
            </Right>
        </FeedbackContainer>
        <Footer>AI can make mistakes. Double check important info.</Footer>
    </BottomContainer>
  );
};

export default ProblemFooter;