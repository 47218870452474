// TabPanel.tsx
import React, { FunctionComponent, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Tabs, Tab as BaseTab, TabsList as BaseTabsList, tabClasses, buttonClasses } from '@mui/base';
import Colors from '../common/Colors';
import Fonts from '../common/Fonts';
import Icon from '../common/Icon';

const TabsList = styled(BaseTabsList)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  background-color: ${Colors.grey1};
  border-radius: 40px;
  z-index: 1000;
`;

const Tab = styled(BaseTab)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.transparent};
  border: none;
  border-radius: 40px;
  padding: 12px 20px;
  margin: 4px;
  font-size: 18px;
  color: ${Colors.grey9}; 
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.whiteTransparent};
  }

  &.${tabClasses.selected} {
    background-color: ${Colors.white};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.4;
    cursor: default;
  }
`;

const TabContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

interface TabPanelProps {
  tabs: Array<{ name: string; icon: string; disabled?: boolean }>;
  onSelect: (selectedTabName: string) => void;
  defaultTabName?: string;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ tabs, onSelect, defaultTabName }) => {
  const defaultTabIndex = tabs.findIndex(tab => tab.name === defaultTabName);
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex >= 0 ? defaultTabIndex : 0);

  useEffect(() => {
    setSelectedTabIndex(defaultTabIndex >= 0 ? defaultTabIndex : 0);
  }, [defaultTabName]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    const selectedTab = tabs[newValue];
    if (selectedTab && onSelect) {
      onSelect(selectedTab.name);
    }
  };

  return (
    <Tabs value={selectedTabIndex} onChange={handleTabChange}>
      <TabsList>
        {tabs.map((tab, index) => (
          <Tab key={index} value={index} disabled={tab.disabled}>
            <TabContent>
              <Icon icon={tab.icon} color={Colors.grey9} size={22} />
              {tab.name}
            </TabContent>
          </Tab>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default TabPanel;
