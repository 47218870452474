// Latex.tsx
import React from 'react';
import styled, { css } from 'styled-components';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import InlineInput from './InlineInput';

const StyledMarkdownContainer = styled.div<{ $fontSize: number; $padding: string; $fontColor: string, $fontWeight: number }>`
  display: inline-block;
  width: 100%;
  height: 100%;

  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${props => props.$fontWeight};
  font-size: ${props => props.$fontSize}px;
  color: ${props => props.$fontColor};
  background-color: ${Colors.transparent};
  padding: ${props => props.$padding};
  text-align: left;
  white-space: normal;
  line-height: 1.4em;

  .katex, .katex-html {
    font-size: ${props => props.$fontSize}px;
    font-family: ${Fonts.lexendMedium.fontFamily} !important;
    font-weight: ${Fonts.lexendMedium.fontWeight};
    letter-spacing: normal;
    font-style: normal;
    white-space: normal;
    line-height: 1.4em;
  }

  .katex * {
    font-family: initial !important;
    font-weight: initial !important;
    font-style: initial !important;
    line-height: 1.4em !important;
    letter-spacing: initial !important;
    text-align: center;
  }

  code {
    font-size: ${props => props.$fontSize}px;
    font-family: ${Fonts.lexendMedium.fontFamily} !important;
    font-weight: ${Fonts.lexendMedium.fontWeight};
    line-height: inherit !important;
    background-color: transparent;
    white-space: pre-wrap;
  }

  .katex .mopen, .katex .mclose {
    color: inherit;
    font-family: KaTeX_Main !important;
  }

  .katex .mord {
    font-family: ${Fonts.lexendMedium.fontFamily} !important;
    font-weight: ${Fonts.lexendMedium.fontWeight} !important;
  }

  /* Ensure special math symbols use KaTeX fonts */
  .katex .mord.mathdefault,
  .katex .mord.mathit,
  .katex .mbin,
  .katex .mrel,
  .katex .mpunct {
    font-family: KaTeX_Main !important;
    font-weight: initial !important;
  }

  .katex .mopen, 
  .katex .mclose,
  .katex .delimsizing,
  .katex .delimcenter {
    font-family: KaTeX_Size1, KaTeX_Main !important;
    padding: 0px 0.02em;
  }

  /* Specific styling for braces */
  .katex .delimsizing.size3 {
    font-family: KaTeX_Size3 !important;
  }
  .katex .delimsizing.size4 {
    font-family: KaTeX_Size4 !important;
  }
  .katex .delim-size1 > span {
    font-family: KaTeX_Size1 !important;
  }
  .katex .delim-size2 > span {
    font-family: KaTeX_Size2 !important;
  }
  .katex .delim-size3 > span {
    font-family: KaTeX_Size3 !important;
  }
  .katex .delim-size4 > span {
    font-family: KaTeX_Size4 !important;
  }
`;

const LineBreak = styled.div`
  height: 1em;
  width: 100%;
`;

interface LatexProps {
  content: string;
  fontSize?: number;
  padding?: string;
  fontColor?: string;
  fontWeight?: number;
  onInputChange?: (key: string, value: string) => void;
}

const Latex: React.FC<LatexProps> = ({ 
  content, 
  fontSize = 24, 
  padding = '0px 30px',
  fontColor = Colors.black,
  fontWeight = Fonts.lexendMedium.fontWeight,
  onInputChange
}) => {
    if (!content) return null;

    const convertedContent = convertSyntax(content);
    const contentWithInputs = parseInputs(convertedContent);
    const contentWithLineBreaks = contentWithInputs; // parseLineBreaks(contentWithInputs);

    // console.log('Original:', content);
    // console.log('ConvertSyntax:', convertedContent);
    // console.log('ParseInputs:', contentWithInputs);
    // console.log('ParseLineBreaks:', contentWithLineBreaks);

    function convertSyntax(content: string): string {
        try {
          // Preserve markdown links first
          const linkRegex = /(\[.*?\]\(.*?\))/g;
          const links: string[] = [];
          
          // Extract and replace links with placeholders
          content = content.replace(linkRegex, (match) => {
            links.push(match);
            return `__MARKDOWN_LINK_${links.length - 1}__`;
          });

          // Convert dash separator to horizontal line
          content = content?.replace('\n\n—\n\n', '\n\n---\n\n');

          // fix neq
          content = content?.replace(/\\+neq/g, '\\neq');
    
          // Apply other LaTeX conversions
          content = content?.replace(/(?:\\)+\((.*?)(?:\\)+\)/g, '$$$1$$'); // Convert inline math delimiters
          content = content?.replace(/(?:\\)+\[((?:.|\n)*?)(?:\\)+\]/g, '$$$$$1$$$$'); // Convert display math delimiters
          content = content?.replace(/\$\$([^\n](?:.|\n)*?[^\n])\$\$/g, '$$$$\n$1\n$$$$'); // Add newlines into display math only if they're missing

          content = content?.replace(/@\{\}/g, '');  // Remove @{} spacing commands unsupported by katex
          
          // Convert cases to matrix with explicit left and right braces
          content = content?.replace(/\\begin\{cases\}([\s\S]*?)\\end\{cases\}/g, '\\left\\{\\begin{matrix}$1\\end{matrix}\\right\\}');
          
          // Restore markdown links
          links.forEach((link, index) => {
            content = content.replace(`__MARKDOWN_LINK_${index}__`, link);
          });
          
          return content;
        } catch (error) {
            console.error('Error converting LaTeX delimiters:', error);
            return content;
        }
    };

    function parseLineBreaks(content: React.ReactNode[]): React.ReactNode[] {
      const parts: React.ReactNode[] = [];
      
      content.forEach((part, index) => {
        if (typeof part === 'string') {
          // Split the content on double newlines first
          const sections = part.split(/\n\n+/);
          
          sections.forEach((section, sectionIndex) => {
            const trimmedSection = section.trim();
            if (trimmedSection) {
              // Check if the section is an em dash
              const isEmDash = trimmedSection === '—';
              // Check if the section is horizontal line
              const isHorizontalLine = trimmedSection === '---';
              // Check if the section looks like a markdown header, list, or LaTeX line
              const isSpecialFormat = /^(#{1,6}\s|[-*+]\s|\d+\.\s|\\begin{|\\end{|\$\$|\$)/.test(trimmedSection);
              
              // Check if previous section was special format
              const prevSection = sectionIndex > 0 ? sections[sectionIndex - 1].trim() : '';
              const prevIsSpecialFormat = prevSection && /^(#{1,6}\s|[-*+]\s|\d+\.\s|\\begin{|\\end{|\$\$|\$)/.test(prevSection);
              
              // Always add line break before em dash or horizontal line if it's not the first section
              if ((isEmDash || isHorizontalLine) && sectionIndex > 0) {
                parts.push(<LineBreak key={`line-break-before-dash-${index}-${sectionIndex}`} />);
              }
              
              parts.push(trimmedSection);
              
              // Add line break after sections
              if (sectionIndex < sections.length - 1) {
                // Check if next section is special format
                const nextSection = sections[sectionIndex + 1].trim();
                const nextIsSpecialFormat = /^(#{1,6}\s|[-*+]\s|\d+\.\s|\\begin{|\\end{|\$\$|\$)/.test(nextSection);
                
                // Always add line break after em dash, horizontal line, or after regular text when neither current nor next is special format
                if ((isEmDash || isHorizontalLine) || (!isSpecialFormat && !nextIsSpecialFormat)) {
                  parts.push(<LineBreak key={`line-break-${index}-${sectionIndex}`} />);
                }
              }
            }
          });
        } else {
          parts.push(part);
        }
      });
      
      // return parts;
      
      // Clean up multiple consecutive line breaks
      const cleanedParts: React.ReactNode[] = [];
      let lastWasLineBreak = false;
      
      parts.forEach((part, index) => {
        if (React.isValidElement(part) && part.type === LineBreak) {
          if (!lastWasLineBreak) {
            cleanedParts.push(part);
            lastWasLineBreak = true;
          }
        } else {
          cleanedParts.push(part);
          lastWasLineBreak = false;
        }
      });
      
      return cleanedParts;
    }

    function parseInputs(content: string): React.ReactNode[] {
      const inputRegex = /\\input\{([^}]+)\}/g;
      const parts: React.ReactNode[] = [];
      let lastIndex = 0;
      let inputCounter = 0;

      const processMatch = (match: string, inputKey: string, index: number) => {
        // Add text before the input
        if (index > lastIndex) {
          parts.push(content.slice(lastIndex, index));
        }

        // Trim the input key and handle template literals
        const cleanKey = inputKey.trim().replace(/^\${/, '').replace(/}$/, '');
        const uniqueKey = `input_${inputCounter++}_${cleanKey}`;

        // Create a dynamic input component
        parts.push(
          <InlineInput 
            key={uniqueKey}
            value={cleanKey} 
            onChange={(e) => {
              onInputChange?.(uniqueKey, e.target.value);
            }}
          />
        );

        lastIndex = index + match.length;
        return match;
      };

      content.replace(inputRegex, processMatch);

      // Add any remaining text after the last input
      if (lastIndex < content.length) {
        parts.push(content.slice(lastIndex));
      }

      return parts.length > 0 ? parts : [content];
    }

    const katexOptions: KatexOptions = {
        output: 'html',
        minRuleThickness: 0.08,
        throwOnError: false,
        strict: false,
        displayMode: true,
        trust: true,
    };

    return (
      <StyledMarkdownContainer $fontSize={fontSize} $padding={padding} $fontColor={fontColor} $fontWeight={fontWeight}>
        {contentWithLineBreaks.map((part, index) => 
          typeof part === 'string' ? (
            <ReactMarkdown
              key={`markdown-${index}`}
              remarkPlugins={[remarkMath, remarkGfm]}
              rehypePlugins={[[rehypeKatex, katexOptions]]}
              components={{
                h1: ({node, ...props}) => <h1 {...props} />,
                h2: ({node, ...props}) => <h2 {...props} />,
                h3: ({node, ...props}) => <h3 {...props} />,
                h4: ({node, ...props}) => <h4 {...props} />,
                h5: ({node, ...props}) => <h5 {...props} />,
                h6: ({node, ...props}) => <h6 {...props} />,
                p: ({node, ...props}) => <p {...props} />,
                li: ({node, ...props}) => <li {...props} />,
                ul: ({node, ...props}) => <ul {...props} />,
                ol: ({node, ...props}) => <ol {...props} />,
                // p: ({node, children}) => (
                //   <span style={{ display: 'inline', margin: 0, padding: 0 }}>
                //     {children}
                //   </span>
                // )
              }}
            >
              {part}
            </ReactMarkdown>
          ) : ( part )
        )}
      </StyledMarkdownContainer>
    );
};

export default Latex;
