import { createGlobalStyle } from 'styled-components';
import Fonts from './Fonts';
import { createTheme } from "@mui/material";

export const GlobalStyle = createGlobalStyle`
  
`;

export const MuiTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
          overscroll-behavior-y: none;
          height: 100%;
          margin: 0;
          padding: 0;
          font-family: ${Fonts.lexendMedium.fontFamily};
          font-weight: ${Fonts.lexendMedium.fontWeight};
        }
        h1, h2, h3, h4, h5, h6, strong, b,
        li > strong, li > b, ul > strong, ul > b, ol > strong, ol > b {
          font-family: ${Fonts.lexendBold.fontFamily};
          font-weight: ${Fonts.lexendBold.fontWeight};
        }
      `,
    },
  },
});
