// Button.tsx
import React, { FunctionComponent, useState, useEffect, MouseEvent, TouchEvent as ReactTouchEvent } from "react";
import styled from "styled-components";
import Icon from "../common/Icon";
import Colors, { blendWithWhite } from "../common/Colors";
import Fonts from "../common/Fonts";
import _ from 'lodash';
import { default as styledComponents } from 'styled-components';

const Text1 = styled.div<{ color: string; size: string | undefined }>`
    font-size: ${({ size }) => (size === 'large' ? '28px' : size === 'medium' ? '22px' : size === 'small' ? '18px' : size === 'tiny' ? '14px' : '18px')};
    line-height: 1.4em;
    font-weight: ${Fonts.lexendMedium.fontWeight};
    font-family: ${Fonts.lexendMedium.fontFamily};
    color: ${({ color }) => color};
    text-align: left;
`;

const ButtonPrimaryRoot = styledComponents.button.withConfig({
    shouldForwardProp: (prop) =>
        !['backgroundColor', 'borderColor', 'iconTextColor', 'noText', 'fixed'].includes(prop)
})<{
    size?: 'tiny' | 'small' | 'medium' | 'large',
    disabled?: boolean,
    noText?: boolean,
    backgroundColor: string,
    borderColor: string,
    iconTextColor: string,
    fixed?: boolean,
}>`
    box-sizing: border-box;
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    color: ${({ iconTextColor }) => iconTextColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ noText }) => noText ? '50%' : '42px'};
    min-width: ${({ noText, size, fixed }) => (fixed ? '300px' 
      : noText ? (size === 'large' ? '72px' : size === 'medium' ? '52px' : size === 'small' ? '40px' : size === 'tiny' ? '30px' : '40px') 
      : 'fit-content')};
    min-height: ${({ noText, size, fixed }) => (fixed ? '52px' 
      : noText ? (size === 'large' ? '72px' : size === 'medium' ? '52px' : size === 'small' ? '40px' : size === 'tiny' ? '30px' : '40px') 
      : 'fit-content')};
    width: auto;
    height: auto;
    padding: ${({ noText, size }) => {
        if (noText) {
            return '10px';
        } else if (size === 'large') {
            return '10px 22px 10px 24px';
        } else if (size === 'medium') {
            return '8px 16px 8px 18px';
        } else if (size === 'small') {
            return '7px 12px 7px 13px';
        } else {
            return '7px 12px 7px 13px';
        }
    }};
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: ${({ size }) =>
        size === 'large' ? '10px' :
        size === 'medium' ? '8px' : 
        size === 'small' ? '5px' : 
        size === 'tiny' ? '2px' : 
        '5px'};
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

export type ButtonType = {
    text?: string | null;
    icon?: string | null;
    onClick: (event?: MouseEvent<HTMLButtonElement> | ReactTouchEvent<HTMLButtonElement> | undefined) => void;
    disabled?: boolean;
    color?: string; // 'white' | 'black' | 'red' | 'ghost' | 'ghost pale' | 'ghost white' | 'blue' | 'pale blue' ;
    size?: 'tiny' | 'small' | 'medium' | 'large';
    fixed?: boolean;
};

const Button: FunctionComponent<ButtonType> = ({
    text = null,
    icon,
    onClick,
    disabled = false,
    color = null,
    size = 'small',
    fixed = false
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
  
    useEffect(() => {
      if (disabled) {
        setIsHovered(false);
        setIsActive(false);
      }
    }, [disabled]);
  
    const handleClick = _.debounce(() => {
      onClick();
    }, 500);
  
    const onClickRaw = (e: MouseEvent<HTMLButtonElement> | ReactTouchEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!disabled) {
        // Type guard to ensure correct event type
        if (e.type === 'click' || e.type === 'mousedown' || e.type === 'mouseup') {
          onClick(e as MouseEvent<HTMLButtonElement>);
        } else if (e.type === 'touchstart' || e.type === 'touchend') {
          onClick(e as ReactTouchEvent<HTMLButtonElement>);
        }
      }
    };    
  
    const noText = text === null;
  
    const setBackgroundColor = (color: string | null, state: string) => {
      let backgroundColor = Colors.blue;
      switch (color) {
        case 'blue':
          backgroundColor = state === 'hover' ? Colors.blueLight
            : state === 'active' ? Colors.blueDark
            : state === 'disabled' ? blendWithWhite(Colors.blue, 0.4)
            : Colors.blue;
          break;
        case 'white':
          backgroundColor = state === 'hover' ? Colors.white
            : state === 'active' ? Colors.grayVeryPale
            : Colors.white;
          break;
        case 'black':
          backgroundColor = state === 'hover' ? Colors.grey8
            : state === 'active' ? Colors.black
            : state === 'disabled' ? blendWithWhite(Colors.grey8, 0.4)
            : Colors.grey9;
          break;
        case 'red':
          backgroundColor = state === 'hover' ? Colors.redLight
            : state === 'active' ? Colors.redDark
            : state === 'disabled' ? blendWithWhite(Colors.red, 0.4)
            : Colors.red;
          break;
        case 'ghost':
        case 'ghost pale':
          backgroundColor = state === 'hover' ? Colors.white
            : state === 'active' ? Colors.grayPale
            : state === 'disabled' ? Colors.transparent
            : Colors.transparent;
          break;
        case 'ghost white':
          backgroundColor = state === 'hover' ? Colors.white+10
            : state === 'active' ? Colors.whiteTransparent
            : state === 'disabled' ? Colors.transparent
            : Colors.transparent;
          break;
        case 'pale blue':
          backgroundColor = state === 'disabled' ? blendWithWhite(Colors.pale, 0.4)
            : Colors.pale;
          break;
        default:
          backgroundColor = Colors.blue;
      }
      return backgroundColor;
    };
  
    const setBorderColor = (color: string | null, state: string) => {
      let borderColor = Colors.blue;
      switch (color) {
        case 'blue':
          borderColor = state === 'hover' ? Colors.blueLight
            : state === 'active' ? Colors.blueDark
            : state === 'disabled' ? blendWithWhite(Colors.blue, 0.4)
            : Colors.blue;
          break;
        case 'white':
          borderColor = state === 'hover' ? Colors.grayPale
            : state === 'active' ? Colors.grey2
            : state === 'disabled' ? blendWithWhite(Colors.grey1, 0.4)
            : Colors.grey1;
          break;
        case 'black':
          borderColor = state === 'hover' ? Colors.grey8
            : state === 'active' ? Colors.black
            : state === 'disabled' ? blendWithWhite(Colors.grey6, 0.4)
            : Colors.grey9;
          break;
        case 'red':
          borderColor = state === 'hover' ? Colors.redLight
            : state === 'active' ? Colors.redDark
            : state === 'disabled' ? blendWithWhite(Colors.red, 0.4)
            : Colors.red;
          break;
        case 'ghost':
        case 'ghost pale':
        case 'ghost white':
          borderColor = Colors.transparent
          break;
        case 'pale blue':
          borderColor = state === 'hover' ? Colors.paleBright
            : state === 'active' ? Colors.blueDark
            : Colors.pale;
          break;
        default:
          borderColor = Colors.white;
      }
      return borderColor;
    };
  
    const setIconTextColor = (color: string | null, state: string) => {
      let iconTextColor = Colors.blue;
      switch (color) {
        case 'blue':
          iconTextColor = Colors.white;
          break;
        case 'white':
          iconTextColor = state === 'hover' ? Colors.grey6
            : state === 'active' ? Colors.black
            : state === 'disabled' ? blendWithWhite(Colors.grey6, 0.4)
            : Colors.grey8;
          break;
        case 'black':
          iconTextColor = Colors.white;
          break;
        case 'red':
          iconTextColor = Colors.white;
          break;
        case 'ghost':
          iconTextColor = state === 'disabled' ? blendWithWhite(Colors.grayMed, 0.4)
            : Colors.grayMed;
          break;
        case 'ghost pale':
          iconTextColor = state === 'disabled' ? blendWithWhite(Colors.grey4, 0.4)
            : Colors.grey4;
          break;
        case 'ghost white':
          iconTextColor = Colors.white;
          break;
        case 'pale blue':
          iconTextColor = state === 'hover' ? Colors.blueLight
            : state === 'active' ? Colors.blueDark
            : state === 'disabled' ? blendWithWhite(Colors.blue, 0.4)
            : Colors.blue;
          break;
        default:
          iconTextColor = Colors.white;
      }
      return iconTextColor;
    };
  
    const state = disabled ? 'disabled' : isActive ? 'active' : isHovered ? 'hover' : 'default';
    const backgroundColor = setBackgroundColor(color, state);
    const borderColor = setBorderColor(color, state);
    const iconTextColor = setIconTextColor(color, state);
    const iconSize = size === 'large' ? 40 : size === 'medium' ? 24 : size === 'small' ? 22 : size === 'tiny' ? 16 : 22;
  
    return (
      <ButtonPrimaryRoot
        onClick={onClickRaw}
        disabled={disabled}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        iconTextColor={iconTextColor}
        noText={noText}
        size={size}
        fixed={fixed}
        onMouseEnter={() => !disabled && setIsHovered(true)}
        onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
        onMouseDown={() => !disabled && setIsActive(true)}
        onMouseUp={() => !disabled && setIsActive(false)}
        onTouchStart={() => !disabled && setIsActive(true)}
        onTouchEnd={() => !disabled && setIsActive(false)}
      >
        {text && <Text1 color={iconTextColor} size={size}>{text}</Text1>}
        {icon && <Icon onClick={()=>{}} icon={icon} color={iconTextColor} size={iconSize} />} 
      </ButtonPrimaryRoot>
    );
};

export default Button;