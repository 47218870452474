// DebugOverlay.tsx
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import Button from '../../components/basic/Button';
import ButtonToggleGroup from '../../components/basic/ButtonToggleGroup';
import { useNavigate } from 'react-router-dom';
import { sortProblemsData } from '../../utils/sortProblems';
import { useSubmitDebugFeedback } from '../../apollo/useDebug';
import Tabs from './Tabs';
import Accordion from './Accordion';
import DebugInput from './DebugInput';
import Latex from '../../components/basic/Latex';
import Generate from './generate/Generate';
import { useConfig } from '../../utils/config';

interface DebugOverlayProps {
  enableDebug: boolean;
  problem: any;
  problemsData: { id: string }[];
  rawProblem: any;
  fetchAndSetProblemById: (id: string | null) => void;
}

const DebugOverlay: FunctionComponent<DebugOverlayProps> = ({ enableDebug, problem, problemsData, rawProblem, fetchAndSetProblemById }) => {
  const modes = ['score', 'feedback', 'reasoning', 'select'];
  const [rightSelectedTab, setRightSelectedTab] = useState(0);
  const debugMode = modes[rightSelectedTab];
  const [leftSelectedTab, setLeftSelectedTab] = useState(0);

  const [selectedViz, setSelectedViz] = useState([]);
  const [problemScore, setProblemScore] = useState([]);
  const [vizSelectionScore, setVizSelectionScore] = useState([]);
  const [vizDetailScore, setVizDetailScore] = useState([]);
  const [expressionScore, setExpressionScore] = useState([]);
  const [noteValue, setNoteValue] = useState('');
  const [feedbackValue, setFeedbackValue] = useState('');
  const [planScore, setPlanScore] = useState([]);
  const [planContent, setPlanContent] = useState('');
  const [metaInstructions, setMetaInstructions] = useState('');

  const isSaveDisabled = problemScore.length === 0 && vizSelectionScore.length === 0 && vizDetailScore.length === 0 && expressionScore.length === 0 && selectedViz.length === 0 && planScore.length === 0;
  const config = useConfig();
  const navigate = useNavigate();
  const [isFirstProblem, setIsFirstProblem] = useState(false);
  const [isLastProblem, setIsLastProblem] = useState(false);
  const sortedProblemsData = useMemo(() => sortProblemsData(problemsData), [problemsData]);
  const { data: debugData, loading: debugLoading, error: debugError, submitDebugById } = useSubmitDebugFeedback();
  const [rawJson, setRawJson] = useState(null);
  const [metaJson, setMetaJson] = useState(null);
  const [traceJson, setTraceJson] = useState(null);

  useEffect(() => {
      const currentIndex = sortedProblemsData.findIndex(p => p.id === problem.id);
      setIsFirstProblem(currentIndex === 0);
      setIsLastProblem(currentIndex === sortedProblemsData.length - 1);

      // Reset selected buttons and input fields when problem changes
      const initialVizSelection = getJsonVizSelection();
      setSelectedViz([initialVizSelection]);
      setProblemScore([])
      setVizSelectionScore([]);
      setVizDetailScore([]);
      setExpressionScore([]);
      setNoteValue('');
      setFeedbackValue('');
      setMetaInstructions('');
      setPlanScore([]);
      setPlanContent('');
      if (rawProblem?.debugFeedback) {
        // console.log(rawProblem);
        if (rawProblem.debugFeedback.problemFlags) setProblemScore(rawProblem.debugFeedback.problemFlags);
        if (rawProblem.debugFeedback.visualizationSelection) setVizSelectionScore(rawProblem.debugFeedback.visualizationSelection);
        if (rawProblem.debugFeedback.visualizationDetail) setVizDetailScore(rawProblem.debugFeedback.visualizationDetail);
        if (rawProblem.debugFeedback.expression) setExpressionScore(rawProblem.debugFeedback.expression);
        if (rawProblem.debugFeedback.notes) setNoteValue(rawProblem.debugFeedback.notes);
        if (rawProblem.debugFeedback.feedback) setFeedbackValue(rawProblem.debugFeedback.feedback);
        if (rawProblem.debugFeedback.metaInstructions) setMetaInstructions(rawProblem.debugFeedback.metaInstructions);
        if (rawProblem.debugFeedback.planScore) setPlanScore(rawProblem.debugFeedback.planScore);
        if (rawProblem.debugFeedback.plan) setPlanContent(rawProblem.debugFeedback.plan);
      }
      if (!rawProblem?.debugFeedback?.plan && traceJson?.filter(trace => trace.name.includes('Strategy'))) {
        const strategyTraces = traceJson.filter(trace => trace.name.includes('Strategy'));
        if (strategyTraces.length > 0) {
          if (debugMode === 'reasoning') {
            const temp = strategyTraces[strategyTraces.length - 1].data?.trim() || null
            setPlanContent(temp);
          }
        }
      }
  }, [problem, sortedProblemsData, rawProblem, traceJson, debugMode]);

  useEffect(() => {
    if (enableDebug && rawProblem) {
      if (rawProblem.meta && rawProblem.meta.reasoningTrace) {
        const { meta, ...rawJsonWithoutMeta } = rawProblem;
        const { reasoningTrace, ...metaWithoutTrace } = meta; 
        setRawJson({ ...rawJsonWithoutMeta, meta: metaWithoutTrace });
        setMetaJson(meta);
        setTraceJson(reasoningTrace);
      } else {
        setRawJson(rawProblem);
      }
    }
  }, [problem, rawProblem]);

  const getJsonVizSelection = () => {
    return problem.parts[0]?.visualizations[0]?.type || 'NULL';
  };

  const onSaveClick = () => {
    const submitDebugFeedback = async () => {
      console.log('save', JSON.stringify(newData));
      const debugFeedback = JSON.stringify(newData);
      try {
        const response = await submitDebugById(problem.id, debugFeedback);
        // console.log('submitDebugById response:', response);
      } catch (error) {
        console.error('Error submitting debug feedback:', error);
      }
    };

    let newData = {
      id: problem.id,
      problemFlags: problemScore,
      visualizationSelection: vizSelectionScore,
      visualizationDetail: vizDetailScore,
      expression: expressionScore,
      notes: noteValue,
      feedback: feedbackValue,
      metaInstructions: metaInstructions,
      planScore: planScore,
      plan: debugMode === 'reasoning' ? planContent : undefined,
      selectedViz: selectedViz
    };
    submitDebugFeedback();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey && !event.metaKey) {
        if (document.activeElement) {
          event.preventDefault();
          onSaveClick();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSaveClick]);  

  const goToPreviousProblem = () => {
    const currentIndex = sortedProblemsData.findIndex(p => p.id === problem.id);
    const previousIndex = currentIndex === 0 ? sortedProblemsData.length - 1 : currentIndex - 1;
    const previousProblemId = sortedProblemsData[previousIndex].id;
    navigate(`/problem/${previousProblemId}${enableDebug ? '?debug=true' : ''}`, { replace: true });
    fetchAndSetProblemById(previousProblemId);
  };

  const goToNextProblem = () => {
    const currentIndex = sortedProblemsData.findIndex(p => p.id === problem.id);
    const nextIndex = (currentIndex + 1) % sortedProblemsData.length;
    const nextProblemId = sortedProblemsData[nextIndex].id;
    navigate(`/problem/${nextProblemId}${enableDebug ? '?debug=true' : ''}`, { replace: true });
    fetchAndSetProblemById(nextProblemId);
  };

  return (
    <>
        {enableDebug &&
            <>
            <DebugTop>
              <TopContainer>
                {config.DEBUG_GENERATE && <Generate/>}
                <TextContainer>{problem.name}</TextContainer>
                <TextContainer>{problem.id}</TextContainer>
              </TopContainer>
            </DebugTop>
            <DebugLeft key={debugMode}>
                <Tabs labels={['JSON', 'X-form', 'Trace']} selectedIndex={leftSelectedTab} onChange={setLeftSelectedTab} />
                <JsonContainer>
                  {leftSelectedTab === 0 ? (
                    <Json>
                      {JSON.stringify(rawJson, null, 2)}
                    </Json>
                  ) : leftSelectedTab === 1 ? (
                    <Json>
                      {JSON.stringify(problem, null, 2)}
                      {/* {`numColors: ${problem.numColors !== null ? JSON.stringify(problem.numColors, null, 2) : "null"}\n`}
                      {JSON.stringify(problem.parts, null, 2)} */}
                    </Json>
                  ) : (
                    <>
                    {traceJson ? ( 
                      <Accordion items={traceJson?.filter(trace => !trace.name.startsWith('Error')).map((trace, index) => ({
                        title: trace.name.replace('Step - Get ', ''),
                        content: trace.name.includes('Solution') ? 
                          <Json><Latex content={trace.data} fontSize={16} padding={'0'} /></Json> 
                          : <Json>{JSON.stringify(trace.data, null, 2)}</Json>,
                        open: (debugMode === 'score' && trace.name.includes('Solution')) || 
                              (debugMode === 'feedback' && trace.name.includes('Strategy')) || 
                              (debugMode === 'reasoning' && trace.name.includes('Strategy')) || 
                              (debugMode === 'select' && trace.name.includes('Select')) ? true : false,
                      }))} size={16} />
                    ) : null}
                    </>
                  )}
                </JsonContainer>
            </DebugLeft>
            <DebugRight key={problem.id}>
              <Tabs labels={['Score', 'Feedback', 'Reason', 'Select']} selectedIndex={rightSelectedTab} onChange={setRightSelectedTab} />
              {/* '✅', '📝', '🧠', '📊' */}
              <AccordionContainer key={problem.id+debugMode}>
              {debugMode === 'score' &&
                <Accordion key={problem.id+debugMode} items={[
                  { 
                      title: 'Problem Flags', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['🚩 Flag', '⛔ No Valid', '📌 Train', '🚫 Null', '❓ Bad Q']} initialValue={problemScore} multiselect={true} onChange={(selected) => setProblemScore(selected)} key={problem.id + '_problemScore'} />
                          </SelectContainer>, 
                      open: false 
                  },
                  {
                      title: 'Visualization Selection', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={vizSelectionScore} multiselect={false} onChange={(selected) => setVizSelectionScore(selected)} key={problem.id + '_vizSelection'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  {
                      title: 'Visualization Detail', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={vizDetailScore} multiselect={false} onChange={(selected) => setVizDetailScore(selected)} key={problem.id + '_vizDetail'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  // {
                  //     title: 'Expression', 
                  //     content: 
                  //         <SelectContainer>
                  //             <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={expressionScore} multiselect={false} onChange={(selected) => setExpressionScore(selected)} key={problem.id + '_expression'} />
                  //         </SelectContainer>, 
                  //     open: false 
                  // },
                  {
                    title: 'Notes', 
                    content: 
                        <SelectContainer>
                            <DebugInput onChange={(event) => setNoteValue(event.target.value)} value={noteValue} key={problem.id + '_note'} />
                        </SelectContainer>, 
                    open: true 
                  },
                  
                ]} 
                />
              }
              {debugMode === 'feedback' &&
                <Accordion key={problem.id+debugMode} items={[
                  { 
                      title: 'Problem Flags', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['🚩 Flag', '⛔ No Valid', '📌 Train', '🚫 Null', '❓ Bad Q']} initialValue={problemScore} multiselect={true} onChange={(selected) => setProblemScore(selected)} key={problem.id + '_problemScore'} />
                          </SelectContainer>, 
                      open: false 
                  },
                  {
                      title: 'Visualization Selection', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={vizSelectionScore} multiselect={false} onChange={(selected) => setVizSelectionScore(selected)} key={problem.id + '_vizSelection'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  {
                      title: 'Visualization Detail', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={vizDetailScore} multiselect={false} onChange={(selected) => setVizDetailScore(selected)} key={problem.id + '_vizDetail'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  {
                      title: 'AI Feedback', 
                      content: 
                          <SelectContainer>
                              <DebugInput onChange={(event) => setFeedbackValue(event.target.value)} value={feedbackValue} key={problem.id + '_feedback'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  {
                      title: 'Meta Instructions', 
                      content: 
                          <SelectContainer>
                              <DebugInput onChange={(event) => setMetaInstructions(event.target.value)} value={metaInstructions} key={problem.id + '_metaInstructions'} />
                          </SelectContainer>, 
                      open: true 
                  }
                ]} 
                />
              }
              {debugMode === 'reasoning' &&
                <Accordion key={problem.id+debugMode} items={[
                  {
                      title: 'Plan Score', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['⭐ GOLD', '✅ Pass', '🔍 Close', '❌ Wrong', '🤯 WTF']} initialValue={planScore} multiselect={false} onChange={(selected) => setPlanScore(selected)} key={problem.id + '_planScore'} />
                          </SelectContainer>, 
                      open: true 
                  },
                  {
                      title: 'Plan', 
                      content: 
                          <SelectContainer>
                              <DebugInput onChange={(event) => setPlanContent(event.target.value)} value={planContent} key={problem.id + '_planContent'} />
                          </SelectContainer>, 
                      open: true 
                  },
                ]} 
                />
              }
              {debugMode === 'select' &&
                <Accordion key={problem.id+debugMode} items={[
                  { 
                      title: 'Manual Viz Select', 
                      content: 
                          <SelectContainer>
                              <ButtonToggleGroup options={['barGraph', 'coordinatePlane', 'grid', 'lineGraph', 'numberBar', 'numberCircle', 'numberLine', 'pictureGraph', 'table', 'shape', 'NULL']}
                                  multiselect={true} onChange={(selected) => setSelectedViz(selected)} initialValue={selectedViz} key={problem.id + '_vizSelection'} />
                          </SelectContainer>, 
                      open: true 
                  }
                ]} 
                />
              }
              </AccordionContainer>
            </DebugRight>
            <BottomContainer>
                <Left>
                    <Button size={'medium'} icon={'left'} color={'pale blue'} onClick={goToPreviousProblem} disabled={isFirstProblem}/>
                </Left>
                <Middle>
                    <Button size={'medium'} text={'Save'} color={'black'} onClick={onSaveClick} disabled={isSaveDisabled} />
                </Middle>
                <Right>
                    <Button size={'medium'} icon={'right'} color={'pale blue'} onClick={goToNextProblem} disabled={isLastProblem} />
                </Right>
            </BottomContainer>
            </>
        }
    </>
  );
};

export default DebugOverlay;

const DebugTop = styled.div`
  position: fixed;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 22px;
  padding: 10px;
  gap: 20px;
  z-index: 1000;
  user-select: text;
`;

const DebugLeft = styled.div`
  position: fixed;
  left: 0px;
  top: 80px;
  height: calc(100svh - 80px);
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 18px;
  gap: 2px;
  z-index: 100;
`;

const DebugRight = styled.div`
  position: fixed;
  right: 0px;
  top: 80px;
  height: calc(100svh - 80px - 72px);
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px 0px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 18px;
  gap: 2px;
  overflow-y: auto;
  z-index: 100;
`;

const AccordionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`; 

const Section = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`; 

const SelectContainer = styled.div`
  display: inline-flex;
  width: 100%;
`; 

const TitleContainer = styled.div`
  display: inline-flex;
  font-size: 24px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendBold.fontFamily};
  font-weight: ${Fonts.lexendBold.fontWeight};
  gap: 10px;
`;

const TextContainer = styled.div`
  display: inline-flex;
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  text-wrap: nowrap;
  gap: 10px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 100px;
`; 

const JsonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  padding: 0px 10px;
`;

const Json = styled.div<{ $fontWeight? }>`
  display: block;
  font-size: 16px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${({ $fontWeight }) => $fontWeight || Fonts.lexendMedium.fontWeight};
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.4;

   h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
    // margin-top: 0;
    // margin-bottom: 0;
    // padding-top: 0;
    // padding-bottom: 0;
  }

  p, ul, ol, li {
    line-height: 1.4;
    // margin-top: 0;
    // margin-bottom: 0;
    // padding-top: 0;
    // padding-bottom: 0;
  }
`;

const JsonSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const BottomContainer = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0px;
  display: flex;
  width: 300px;
  height: 72px;
  padding: 10px;
  box-sizing: border-box; 
  background-color: ${Colors.background};
  z-index: 100;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Middle = styled.div`
  display: flex;
  margin: 0 auto;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;