// NumberCircle.tsx
import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../../pages/problem/HoverContext';
import Stepper from './interactives/Stepper';
import Dragger from './interactives/Dragger';
import { getMaxDecimalPlaces } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

export interface NumberCircleProps {
  values: number[];
  totalValue: number;
  labels?: string[];
  tick?: number;
  colors?: string[];
  
  draggable?: boolean[];
  dragTick?: number;
  dragOverrideOtherValues?: boolean;
  tutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;

  steppable?: 'total' | 'tick' | null;
  stepMin?: number;
  stepMax?: number;
  stepUp?: string;
  stepDown?: string;

  totalValueInitial?: number;
  tickInitial?: number;

  actualScale?: number;
  onStateChange?: (state: NumberCircleProps, skipLogic?: string) => void;
}

const numberCirclePropsTypeDefinition: NumberCircleProps = {
  values: [0],
  totalValue: 0,
  labels: [''],
  tick: 0,
  colors: [''],

  draggable: [false],
  dragTick: 0,

  steppable: 'total',
  stepMin: 0,
  stepMax: 0,

  totalValueInitial: 0,
  tickInitial: 0,

  actualScale: 1,
  onStateChange: () => {},
};

const NumberCircle: React.FC<NumberCircleProps> = (props) => {
  props = convertAllTypes(props, numberCirclePropsTypeDefinition);
  let {values, totalValue = 100, labels, tick, colors, totalValueInitial, tickInitial,
    steppable, stepMin, stepMax, stepUp, stepDown, draggable, dragTick, dragOverrideOtherValues, tutorial,
    onStateChange} = props;

  const MIN_SCALE = 0.37;
  let { actualScale = 1 } = props;
  if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;
  
  useEffect(() => {
    // console.log('numberCircle values', values);
    // console.log('numberCircle total value', totalValue);
  }, [values, totalValue]);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [decimalPrecision, setDecimalPrecision] = useState(getMaxDecimalPlaces([
    ...(tick !== undefined ? [tick] : []), 
    ...values.filter(v => v !== undefined), 
    ...(totalValue !== undefined ? [totalValue] : []),
    ...(dragTick !== undefined ? [dragTick] : [])
  ]));  

  const updateState = (newState: Partial<NumberCircleProps>, skipLogic?: string) => {
    // console.log(`NUMBERCIRCLE newValuesData: ${newState.values}, newTotalValueData: ${newState.totalValue}, newTickData: ${newState.tick}`);
    values = newState.values ?? values;
    totalValue = newState.totalValue ?? totalValue;
    tick = newState.tick ?? tick;

    if (skipLogic) setHasInteracted(true);

    const updatedState = {
      ...props,
      values: newState.values ?? values,
      totalValue: newState.totalValue ?? totalValue,
      tick: newState.tick ?? tick,
      labels,
      colors,
      steppable,
      draggable,
      dragTick,
      onStateChange
    };
  
    if (onStateChange) {
      onStateChange(updatedState, skipLogic);
    }
  };
  
  const handleDrag = (index: number, newValue: number) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    updateState({ values: updatedValues }, `values[${index}]`);
  };

  const handleStepperClick = (newValue: number) => {
    if (steppable === 'total') {
      updateState({ totalValue: newValue }, 'totalValue');
    } else if (steppable === 'tick') {
      updateState({ tick: newValue }, 'tick');
    }
  };

  // useEffect(() => {
  //   console.log('total', totalValue, 'tick every', tick, 'tick value', totalValue/tick);
  // }, [totalValue, tick]);

  const render = () => {
    if (!svgRef.current) return;

    // const padding = { top: 10, right: 10, bottom: steppable ? 70 : 10, left: 10 };
    const padding = {
      top: 10,
      right: 10 / actualScale,
      bottom: steppable ? 70 / actualScale : 10 / actualScale,
      left: 10 / actualScale,
    };
    const width = 300;
    const height = 300;
    const radius = (width - padding.left - padding.right) / 2;
    const horizontalCenter = (width + padding.left + padding.right) / 2;
    const circleCenter = (height / 2) + padding.top

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width + padding.left + padding.right} ${height + padding.top + padding.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', '100%')
      .append('g')
      .attr('transform', `translate(${horizontalCenter}, ${circleCenter})`);

    const arc = d3.arc<d3.PieArcDatum<number>>()
      .innerRadius(0)
      .outerRadius(radius);

    const pie = d3.pie<number>()
      .value(d => d)
      .sort(null);

    const totalValuesSum = values.reduce((a, b) => a + b, 0);
    const remainingValue = totalValue - totalValuesSum;

    const data = remainingValue > 0 ? [...values, remainingValue] : values;
    const dataColors = colors ? [...colors, 'white'] : colors;

    const slices = svg.selectAll('.slice')
      .data(pie(data));

    slices.enter()
      .append('path')
      .merge(slices as d3.Selection<SVGPathElement, d3.PieArcDatum<number>, SVGGElement, unknown>)
      .attr('d', arc as any)
      .attr('fill', (d, i) => dataColors ? dataColors[i] : Colors.vizDefault)
      .attr('stroke', steppable ? 'none' : 'black')
      .classed('slice', true)
      .each(function (d, i) {
        d3.select(this)
          .on('click', () => {
            handleMouseEnter(dataColors ? dataColors[i] : Colors.vizDefault);
          });
      });

    slices.exit().remove();

    if (tick && tick > 0 && tick !== totalValue) {
      for (let i = 0; i < totalValue; i += tick) {
        const angle = (i / totalValue) * 2 * Math.PI - Math.PI / 2;
        const xEnd = radius * Math.cos(angle);
        const yEnd = radius * Math.sin(angle);
        const [xStart, yStart] = [0, 0];
        svg.append('line')
          .attr('x1', xStart)
          .attr('x2', xEnd)
          .attr('y1', yStart)
          .attr('y2', yEnd)
          .attr('vector-effect', 'non-scaling-stroke')
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          .attr('opacity', 1.0);
      }
    }

    svg.append('circle')
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('stroke', 'black')
      .attr('stroke-width', 3);

    values.forEach((value, index) => {
      const label = labels ? labels[index] : undefined;
      const fontSize = 24 / actualScale;
      if (label) {
        const middleArc = pie(data)[index];
        if (middleArc) {
          const centroid = arc.centroid(middleArc);
    
          const text = svg.append('text')
            .attr('x', centroid[0])
            .attr('y', centroid[1])
            .style('font-family', Fonts.lexendMedium.fontFamily)
            .style('font-weight', Fonts.lexendMedium.fontWeight)
            .style('font-size', `${fontSize}px`)
            .attr('text-anchor', 'middle')
            .attr('dy', '.35em')
            .attr('fill', 'black')
            .text(label);
    
          const bbox = text.node()!.getBBox();
    
          if (value !== 0) {
            svg.insert('rect', 'text')
            .attr('x', bbox.x-5)
            .attr('y', bbox.y)
            .attr('rx', 10).attr('ry', 10)
            .attr('width', bbox.width+10)
            .attr('height', bbox.height)
            .attr('fill', dataColors ? dataColors[index] : Colors.vizDefault);
          }
        }
      }

      if (draggable && draggable[index]) {
        const middleArc = pie(data)[index];
        if (middleArc) {
          const angle = middleArc.endAngle - Math.PI / 2;
          const x = horizontalCenter + radius * Math.cos(angle);
          const y = circleCenter + radius * Math.sin(angle);
          const color = dragOverrideOtherValues || draggable.length === 1 ? Colors.black : dataColors[index]; 
          Dragger({
            svgRef: svgRef,
            id: `number-circle-segment-${index}`,
            color: color,
            direction: 'clockwise',
            x,
            y,
            center: { x: horizontalCenter, y: circleCenter },
            radius: radius,
            value: value,
            minVal: 0,
            maxVal: totalValue,
            prevSumValue: index > 0 ? values.slice(0, index).reduce((acc, cur) => acc + cur, 0) : 0,
            postSumValue: index + 1 < values.length ? values.slice(index + 1).reduce((acc, cur) => acc + cur, 0) : 0,
            tick: dragTick,
            dragOverrideOtherValues: dragOverrideOtherValues,
            tutorial: hasInteracted ? null : tutorial!,
            decimalPrecision: dragTick ? decimalPrecision : null,
            actualScale,
            handleDrag: (newValue) => handleDrag(index, newValue),
          });
        }
      }
    });

    if (steppable) {
      const valueProp = steppable === 'total' ? totalValue : tick;
      const sumValueData = values.reduce((acc, value) => acc + value, 0);
      const maxTicks = 20;
      let minProp = steppable === 'total' ? Math.max(sumValueData, tickInitial) : tickInitial;
      let maxProp = steppable === 'total' ? maxTicks * tickInitial : totalValueInitial;
      if (stepMin) minProp = stepMin;
      if (stepMax) maxProp = stepMax;
      const stepTick = tick ?? 1;

      Stepper({
        svgRef: svgRef.current!,
        x: horizontalCenter,
        y: height + padding.top + padding.bottom/2,
        value: valueProp,
        min: minProp,
        max: maxProp,
        tick: stepTick,
        tickInitial: tickInitial,
        decimalPrecision: tick ? decimalPrecision : null,
        showLabel: true,
        stepUp: stepUp!,
        stepDown: stepDown!,
        actualScale,
        onClick: (newValue) => handleStepperClick(newValue)
      });
    }
  };

  useEffect(() => {
    render();
  }, [values, totalValue, labels, tick, colors, steppable, draggable, dragTick, handleMouseEnter, handleMouseLeave, onStateChange]);

  return <svg ref={svgRef}></svg>;
};

export default NumberCircle;
