// useQuestion.js
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import React, { useMemo, useCallback, useState } from 'react';
import { transformInitialContentFormat, ensureName } from '../pages/shared/TransformContent';

const COMPUTE_QUESTION_RESPONSE_MUTATION = gql`
  mutation computeQuestionResponse($question: String!, $imageIds: [ID!]) {
    computeQuestionResponse(question: $question, imageIds: $imageIds) {
      id
      name
      createdAt
      updatedAt
      status
      numColors
      colorOverride
      text
      visualizations
      expressions
      solution
      imageIds
    }
  }
`;

const GET_QUESTION_RESPONSE_BY_ID = gql`
  query getQuestionResponseById($id: String!) {
    getQuestionResponseById(id: $id) {
      id
      name
      createdAt
      updatedAt
      status
      refusalReason
      imageIds
      numColors
      colorOverride
      
      variables
      experimental
      parts
      text
      visualizations
      expressions
      solution

      debugFeedback
      meta
    }
  }
`;

const GET_ALL_PROBLEMS = gql`
  query getAllProblems {
    getAllProblems {
      id
      name
      createdAt
      updatedAt
      text
    }
  }
`;

const COPY_PROBLEM_TO_ACCOUNT = gql`
  mutation copyProblemToAccount($id: ID!) {
    copyProblemToAccount(id: $id)
  }
`;

export function useCopyProblemToAccount() {
  const [copyProblemToAccountMutation] = useMutation(COPY_PROBLEM_TO_ACCOUNT);

  const copyProblemToAccount = useCallback(async (id) => {
    if (id) {
      const response = await copyProblemToAccountMutation({ variables: { id } });
      if (response && response.data) {
        return response.data.copyProblemToAccount;
      }
    }
    return Promise.resolve(false);
  }, [copyProblemToAccountMutation]);

  return { copyProblemToAccount };
}

export function useComputeQuestionResponse() {
  const [computeQuestionResponse, { data, loading, error }] = useMutation(COMPUTE_QUESTION_RESPONSE_MUTATION);

  const computeResponse = useCallback(async (question, imageIds) => {
    if (question) {
      const response = await computeQuestionResponse({ variables: { question, imageIds } });
      if (response && response.data && response.data.computeQuestionResponse) {
        const transformedResponse = transformInitialContentFormat(response.data.computeQuestionResponse);
        return { ...transformedResponse };
      }
    }
    return Promise.resolve();
  }, [computeQuestionResponse]);

  return { data, loading, error, computeResponse };
}

export function useGetQuestionResponseById() {
  const [getQuestionResponseById, { data, loading, error }] = useLazyQuery(GET_QUESTION_RESPONSE_BY_ID);

  const fetchQuestionById = useCallback(async (id) => {
    if (id) {
      const response = await getQuestionResponseById({ 
        variables: { id },
        fetchPolicy: 'network-only' // Force network request, skip cache
      });
      if (response && response.data && response.data.getQuestionResponseById) {
        const responseWithoutDebug = { ...response.data.getQuestionResponseById, debugFeedback: null, meta: null };
        const transformedResponse = transformInitialContentFormat(responseWithoutDebug);
        return { ...transformedResponse };
      }
    }
    return Promise.resolve(null);
  }, [getQuestionResponseById]);

  const fetchRawJsonById = useCallback(async (id) => {
    if (id) {
      const response = await getQuestionResponseById({ variables: { id } });
      if (response && response.data && response.data.getQuestionResponseById) {
        return { ...response.data.getQuestionResponseById };
      }
    }
    return Promise.resolve(null);
  }, [getQuestionResponseById]);

  return { data, loading, error, fetchQuestionById, fetchRawJsonById };
}

export function useGetAllProblems() {
  const [getAllProblems, { loading, error, refetch: originalRefetch }] = useLazyQuery(GET_ALL_PROBLEMS);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);

  const transformProblems = useCallback((problems) => {
    return problems.map(problem => {
      let transformedProblem = ensureName(problem);
      return transformedProblem;
    });
  }, []);

  const fetchAllProblems = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await getAllProblems();
      if (response && response.data && Array.isArray(response.data.getAllProblems)) {
        const transformed = transformProblems(response.data.getAllProblems);
        setData(transformed);
        return transformed;
      } else {
        setData([]);
        return [];
      }
    } catch (err) {
      setIsError(true);
      setData([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [getAllProblems, transformProblems]);

  const refetch = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await originalRefetch();
      if (response && response.data && Array.isArray(response.data.getAllProblems)) {
        const transformed = transformProblems(response.data.getAllProblems);
        setData(transformed);
        return transformed;
      } else {
        setData([]);
        return [];
      }
    } catch (err) {
      setIsError(true);
      setData([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [originalRefetch, transformProblems]);

  return { data, loading: isLoading, error: isError, fetchAllProblems, refetch };
}
