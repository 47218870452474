// useStream.tsx
import { useEffect, useState, useCallback, useRef } from 'react';
import { useConfig } from '../utils/config';
import { debugContentOptions } from '../pages/internal/Stream';

interface StreamContent {
    renderContent: string;
    rawContent: string;
    messageBuffer?: { message: string; order: number }[];
}

interface UseStreamResult {
    getRenderContent: (id: string) => string;
    getRawContent: (id: string) => string;
    startStream: (id: string) => void;
    endStream: (id: string) => void;
}

const WS_URL = process.env.REACT_APP_WEBSOCKET_URL || 'wss://lmhtzjy1kd.execute-api.us-east-1.amazonaws.com/dev/';

interface StreamState {
    socket: WebSocket;
    id: string;
}

const debugContent = null; // debugContentOptions.latex3;
const debugSpeed = 30; // smaller ms is faster
const debugStartCharacter = 300;
// const debugStartCharacter = debugContentOptions.latex3.length;

export function useStream(): UseStreamResult {
    const config = useConfig();
    const streamRef = useRef<StreamState | null>(null);
    const [currentStream, setCurrentStream] = useState<StreamState | null>(null);
    const [streams, setStreams] = useState<Record<string, StreamContent>>({});

    const cursorStates = [' ', '|'];
    const ellipsisStates = [' . ', ' . . ', ' . . . ', ];
    const markdownChars = ['#', '##', '###', '####', '#####', '*', '**', '***', '_', '__', '___'];
    const charsToHide = [ `\\`, ];

    const prevRawStepsCountRef = useRef<number>(0);
    const prevRawSolutionCountRef = useRef<number>(0);   
    const prevPartDoneCountRef = useRef<number>(0);   
    useEffect(() => {
        if (!currentStream) return;
        if (config.CONSOLE_LOG_STREAM_RAW === 'none') return;

        const id = currentStream.id;
        const raw = getRawContent(id);
        
        if (config.CONSOLE_LOG_STREAM_RAW === 'every-tick') {
            console.log('INITIAL CONTENT', raw);
        }

        if (config.CONSOLE_LOG_STREAM_RAW === 'parts') {
            // Count the number of [RAWSTEPS], [PARTDONE] and [DONE] in the content
            const rawStepsCount = (raw.match(/\[RAWSTEPS\]/g) || []).length;
            const rawSolutionCount = (raw.match(/\[RAWSOLUTION\]/g) || []).length;
            const partDoneCount = (raw.match(/\[PARTDONE\]/g) || []).length;
            const isDone = raw.includes("[DONE]");
            // console.log(`[RAWSTEPS] count: ${rawStepsCount}, [RAWSOLUTION] count: ${rawSolutionCount}, [PARTDONE] count: ${partDoneCount}, [DONE] detected: ${isDone}`);

            // Log when any of the counters increment or when done
            if (rawStepsCount > prevRawStepsCountRef.current) {
                console.log(`RAW CONTENT: \n\n${raw}`);
                prevRawStepsCountRef.current = rawStepsCount;
            }

            if (rawSolutionCount > prevRawSolutionCountRef.current) {
                console.log(`RAW CONTENT: \n\n${raw}`);
                prevRawSolutionCountRef.current = rawSolutionCount;
            }

            if (partDoneCount > prevPartDoneCountRef.current) {
                console.log(`RAW CONTENT: \n\n${raw}`);
                prevPartDoneCountRef.current = partDoneCount;
            }

            if (isDone) {
                console.log(`RAW CONTENT: \n\n${raw}`);
            }
        }
    }, [currentStream, streams, config.CONSOLE_LOG_STREAM_RAW]);

    const formatStream = (id, content, renderContent, ellipsisIndex) => {
        if (config.CONSOLE_LOG_STREAM_RAW === 'every-tick') {
            console.log('INITIAL CONTENT', content);
        }
        let formattedContent = renderContent;

        const indicatorPart = '[PART]';
        const indicatorSteps = '[RAWSTEPS]';
        const indicatorSolution = '[RAWSOLUTION]';
        const indicatorPartDone = '[PARTDONE]';
        const indicatorDone = '[DONE]';
        const indicatorStepsHeader = "### STEPS:";
        const indicatorStep = "#### Step";
        const indicatorAnswerHeader = "### ANSWER:";

        let preProcessedContent = content
            // Fix latex \neq
            .replaceAll(/\\neq/g, '\\neq')

            // Ensure exactly one newline after \[ if none exists
            .replaceAll(/\\\[(\s*)(?!\n)/g, '\\[\n')
            // Replace multiple newlines after \[ with a single newline
            .replaceAll(/\\\[(\n\s*)+/g, '\\[\n')
            // Ensure exactly one newline before \] if none exists
            .replaceAll(/(?<!\n)(\s*)\\\]/g, '\n\\]')
            // Replace multiple newlines before \] with a single newline
            .replaceAll(/(\n\s*)+\\]/g, '\n\\]')
            // Ensure exactly two newlines before \[ (outside)
            .replaceAll(/(?<!\n\n)\\\[/g, '\n\n\\[')
            .replaceAll(/(\n){3,}\\\[/g, '\n\n\\[')
            // Ensure exactly two newlines after \] (outside)
            .replaceAll(/\\\](?!\n\n)/g, '\\]\n\n')
            .replaceAll(/\\\](\n){3,}/g, '\\]\n\n');
        // console.log(`latex healed: \n\n${preProcessedContent}`);

        const mistakes: string[] = [];
        const fixedContent = preProcessedContent
            .replaceAll(`SUBBULLET`, (match) => {
                mistakes.push(match);
                return ``;
            })
            .replaceAll(`THIRDBULLET`, (match) => {
                mistakes.push(match);
                return ``;
            });
        if (preProcessedContent !== fixedContent) {
            console.warn(`mistakes detected: \n\n${mistakes.join('\n')}`);
            console.log(`mistakes hidden: \n\n${preProcessedContent}`);
        }
        preProcessedContent = fixedContent;

        preProcessedContent = preProcessedContent
            .replaceAll(`\n\n\n\n`, `\n\n`)
            .replaceAll(`\n\n${indicatorPart}`, `${indicatorPart}`)
            .replaceAll(`${indicatorPart}\n\n\n\n${indicatorSteps}`, `${indicatorPart}\n\n${indicatorSteps}`)
            .replaceAll(`\n${indicatorPartDone}`, `\n\n${indicatorPartDone}`)
            .replaceAll(`${indicatorPartDone}${indicatorPart}`, `${indicatorPartDone}\n\n---\n\n${indicatorPart}`)
            .replaceAll(`${indicatorPartDone}\n${indicatorDone}`, `${indicatorPartDone}\n\n${indicatorDone}`);
        // console.log(`FIXED: \n\n${JSON.stringify(preProcessedContent)}`);

        const rawSplitLines = preProcessedContent.split('\n\n');
        // console.log('rawSplitLines', rawSplitLines);
        // console.log('rawSplitLines', JSON.stringify(rawSplitLines));

        const currentIndexBeingStreamed = rawSplitLines.length - 1;
        // console.log('current index being streamed', currentIndexBeingStreamed)

        const interWeave = (rawSplitLines, currentlyStreamingIndex) => {
            let formattedLines = rawSplitLines;
            // console.log('initial intweave raw lines', formattedLines);

            const indicatorPartIndex = formattedLines.findIndex(line => line.includes(indicatorPart));
            const indicatorStepsIndex = formattedLines.findIndex(line => line.includes(indicatorSteps));
            const indicatorSolutionIndex = formattedLines.findIndex(line => line.includes(indicatorSolution));
            const indicatorAnswerIndex = formattedLines.findIndex(line => line.includes(indicatorAnswerHeader));
            const indicatorPartDoneIndex = formattedLines.findIndex(line => line.includes(indicatorPartDone));
            const indicatorDoneIndex = formattedLines.findIndex(line => line.includes(indicatorDone));

            // console.log('indicatorPartIndex', indicatorPartIndex);
            // console.log('indicatorStepsIndex', indicatorStepsIndex);
            // console.log('indicatorSolutionIndex', indicatorSolutionIndex);
            // console.log('indicatorAnswerIndex', indicatorAnswerIndex);
            // console.log('indicatorPartDoneIndex', indicatorPartDoneIndex);
            // console.log('indicatorDoneIndex', indicatorDoneIndex);

            if (formattedLines.length === 0) console.error('Content not found');
            else if (indicatorPartIndex === -1) console.error('[PART] not found');

            const stepIndexesInitial = formattedLines.map((line, index) => {
                if (index > indicatorStepsIndex && index < indicatorSolutionIndex && 
                // if (index > indicatorStepsIndex && (indicatorSolutionIndex === -1 || index < indicatorSolutionIndex) && 
                (line.includes(indicatorStepsHeader) || line.includes(indicatorStep) || line.includes(indicatorAnswerHeader))) {
                    return index;
                }
            }).filter(index => index !== undefined);
            //  console.log('stepIndexesInitial', stepIndexesInitial);

            const initialSteps = formattedLines.filter((line, index) => stepIndexesInitial.includes(index));
            // console.log('initialSteps', initialSteps);

            const stepsFinished = initialSteps.some(line => line.includes(indicatorAnswerHeader));
            const partFinished = formattedLines.some(line => line.includes(indicatorPartDone));

            if (!partFinished) {
                // console.log('SOLUTION IN PROGRESS');

                const stepIndexesNew = formattedLines.map((line, index) => {
                    const isCurrentLine = index === formattedLines.length - 1;
                    const charsToCheck = line.length ? line.length : 1;
                    const stepHeaderPartial = line.includes(indicatorStepsHeader.substring(0, charsToCheck));
                    const stepNumPartial = line.includes(indicatorStep.substring(0, charsToCheck));
                    const answerHeaderPartial = line.includes(indicatorAnswerHeader.substring(0, charsToCheck));
                    // console.log(`isCurrentLine: ${isCurrentLine}, index: ${index}, length: ${formattedLines.length - 1}, charsToCheck: ${charsToCheck}, stepHeaderPartial: ${stepHeaderPartial}, stepNumPartial: ${stepNumPartial}, answerHeaderPartial: ${answerHeaderPartial}`);

                    if (index > indicatorSolutionIndex &&
                    ((isCurrentLine && (stepHeaderPartial || stepNumPartial || answerHeaderPartial)) || 
                    (line.includes(indicatorStepsHeader) || line.includes(indicatorStep) || line.includes(indicatorAnswerHeader)))) {
                        return index;
                    }
                }).filter(index => index !== undefined);
                 // console.log('stepIndexesNew', stepIndexesNew);

                const newSteps = formattedLines.filter((line, index) => stepIndexesNew.includes(index));
                // console.log('newSteps', newSteps);

                const formattedSteps = initialSteps.map((line, index) => {
                    // console.log('CHECKING INDEX: ', index);
                    // const linesMatch = initialSteps[index] === newSteps[index];
                    
                    const initialStepIndex = stepIndexesInitial[index];
                    const newStepIndex = stepIndexesNew[index];
                    const stepExistsInSolution = formattedLines[newStepIndex] !== undefined;
                    // console.log('stepExistsInSolution', stepExistsInSolution, 'at index', index, '\ninitialStepIndex', initialStepIndex, 'newStepIndex', newStepIndex);

                    const isStreamingTHISLine = currentlyStreamingIndex === newStepIndex;
                    // console.log('isStreamingTHISLine', isStreamingTHISLine, 'currentlyStreamingIndex', currentlyStreamingIndex, 'newStepIndex', newStepIndex);

                    const combinedLine = Array.from({ length: Math.max(line.length, newSteps[index]?.length || 0) })
                    .map((_, i) => (newSteps[index]?.[i] !== undefined ? newSteps[index][i] : line[i] || ''))
                    .join('');

                    // console.log('stepExistsInSolution', stepExistsInSolution);

                    if (stepExistsInSolution) {
                        let updatedLine = combinedLine;
                        if (!isStreamingTHISLine) {
                            const finishedLine = newSteps[index];
                            // console.log('finishedLine', finishedLine);
                            updatedLine = finishedLine;
                        }
                        // console.log('updatedLine', updatedLine);
                        formattedLines[newStepIndex] = updatedLine;
                        return updatedLine;
                    } else {
                        formattedLines.push("");
                        formattedLines.push(combinedLine);
                        return combinedLine;
                    }
                });
                // console.log('interleaved latest steps & detail', formattedSteps);
            } else {
                // console.log('SOLUTION COMPLETED');
            }

            // console.log('INITIAL formatted lines', formattedLines);
            const partStartLineIndex = formattedLines.findIndex(line => line.includes(indicatorPart));
            const partStartLine = formattedLines[partStartLineIndex];
            const questionLine = partStartLine?.split(indicatorPart).join('').split(indicatorSteps).join('');
            // console.log('partStartLine', partStartLineIndex, partStartLine);
            // console.log('questionLine', questionLine);
            if (partStartLineIndex !== -1) {
                formattedLines[partStartLineIndex] = questionLine;
            }
            // console.log('updated formatted lines', formattedLines);

            if (!stepsFinished) {
                // console.log('STEPS IN PROGRESS');

                const stepIndexesOfficial = formattedLines.map((line, index) => {
                    if (index > indicatorStepsIndex && (indicatorSolutionIndex === -1 || index < indicatorSolutionIndex) && 
                    (line.includes(indicatorStepsHeader) || line.includes(indicatorStep) || line.includes(indicatorAnswerHeader))) {
                        return index;
                    }
                }).filter(index => index !== undefined);
                //  console.log('stepIndexesOfficial', stepIndexesOfficial);
    
                const initialOfficialSteps = formattedLines.filter((line, index) => stepIndexesOfficial.includes(index));
                // console.log('initialOfficialSteps', initialOfficialSteps);

                // delete everything after indicatorStepsIndex
                formattedLines = formattedLines.slice(0, indicatorStepsIndex);
                // add initialSteps
                formattedLines.push(...initialOfficialSteps);

                // console.log('displaying only official steps', formattedLines);

                // if (config.ELLIPSIS_IN_PROGRESS) {

                //     const numberedStepIndexesInitial = formattedLines.map((line, index) => {
                //         if (index > indicatorStepsIndex && line.includes(indicatorStep)) {
                //             return index;
                //         }
                //     }).filter(index => index !== undefined);
                //     console.log('numberedStepIndexesInitial', numberedStepIndexesInitial);

                //     const numberedInitialSteps = formattedLines.filter((line, index) => numberedStepIndexesInitial.includes(index));
                //     console.log('numberedInitialSteps', numberedInitialSteps);

                //     const contentToPush = ' . . . \n\n';

                //     for (let i = 0; i < numberedStepIndexesInitial.length; i++) {
                //         const stepIndex = numberedStepIndexesInitial[i];
                //         const stepIsDone = formattedLines[stepIndex+1] !== undefined;
                //         if (stepIsDone) {
                //             console.log('step', i + 1, 'done');
                //             formattedLines.splice(stepIndex + 1, 0, contentToPush);
                //         }
                //     }

                //     const answerIndex = formattedLines.findIndex(line => line.includes(indicatorAnswerHeader));

                //     if (answerIndex !== -1) {
                //         const answerDone = formattedLines[answerIndex+1] !== undefined;
                //         if (answerDone) {
                //             console.log('answer done at index', answerIndex);
                //             formattedLines.splice(answerIndex + 1, 0, contentToPush);
                //         }
                //     }

                //     console.log('Updated ellipses', formattedLines);
                // }
            } else {
                // console.log('STEPS FINISHED');
                formattedLines = formattedLines.slice(0, indicatorStepsIndex)
                    .concat(questionLine).concat(formattedLines.slice(indicatorSolutionIndex));
                // console.log('removed initial steps', formattedLines);
            }

            formattedLines = formattedLines.filter(line => ![indicatorSteps, indicatorSolution].some(indicator => line.includes(indicator)));
            if (formattedLines.some(line => [indicatorPartDone, indicatorDone].some(indicator => line.includes(indicator)))) {
                formattedLines.push("");
            }
            formattedLines = formattedLines.filter(line => ![indicatorPartDone, indicatorDone].some(indicator => line.includes(indicator)));
            let result = formattedLines.join('\n\n');

            // console.log('formattedLines', formattedLines);
            // console.log('FINAL INTERWEAVE', result);
            return result;
        };

        const delayLatex = (line) => {
            let fixedLine = line;
            if (config.DELAY_STREAM_LATEX) {
                // Find the position of the last LaTeX delimiter
                const findLastPosition = (str: string, regex: RegExp): number => {
                    let lastPosition = -1;
                    let match;
                    while ((match = regex.exec(str)) !== null) {
                        lastPosition = match.index;
                    }
                    return lastPosition;
                };

                const inlineOpenPos = findLastPosition(line, /(?:\\)+\(/g);
                const inlineClosePos = findLastPosition(line, /(?:\\)+\)/g);
                const displayOpenPos = findLastPosition(line, /(?:\\)+\[/g);
                const displayClosePos = findLastPosition(line, /(?:\\)+\]/g);
                // const displayClosePos = findLastPosition(line, /(?:\\)+\]\n/g); // with the \n at the end for the last one to make sure it doesn't flash red
                
                // Ensure we don't break LaTeX delimiters across chunks
                const incompleteInline = (inlineOpenPos > inlineClosePos);
                const incompleteDisplay = (displayOpenPos > displayClosePos);
                const hasIncompleteLatex = incompleteInline || incompleteDisplay;

                // console.log('checking latex on line', line);

                if (hasIncompleteLatex) {
                    // console.log('INCOMPLETE LaTeX DETECTED');
                    // console.log('LaTeX delimiters:', {
                    //     '\\(': inlineOpenPos,
                    //     '\\)': inlineClosePos,
                    //     '\\[': displayOpenPos,
                    //     '\\]': displayClosePos
                    // });
                    
                    // Find the position of the first unclosed delimiter
                    const firstUnclosedPos = Math.max(inlineOpenPos, displayOpenPos);
                    
                    // Use line length to determine ellipsis state, updating every x stream updates to slow it down
                    ellipsisIndex = Math.floor(line.length / 8) % ellipsisStates.length;
                    let ellipsisToAdd = ellipsisStates[ellipsisIndex];
                    if (incompleteInline) {
                        ellipsisToAdd = '\\( \\text{ ' + ellipsisToAdd + ' } \\)';
                    } else if (incompleteDisplay) {
                        // if (ellipsisIndex === 0) ellipsisToAdd = ' \\\\ ';
                        ellipsisToAdd = '\n\\[\n \\text{ ' + ellipsisToAdd + ' } \n\\]\n';
                    }
                    // console.log(ellipsisToAdd);
                    fixedLine = line.substring(0, firstUnclosedPos) + ellipsisToAdd;
                    // console.log('Formatted Latex rendering from index 0 to index', firstUnclosedPos, fixedLine, 'original line', line);
                }
            }
            return fixedLine;
        };

        const delayMarkdown = (line) => {
            if (config.DELAY_STREAM_MARKDOWN) {
                // Find incomplete markdown characters at the end of line
                const lines = line.split('\n');
                const lastLine = lines[lines.length - 1];
                
                // Check if the line starts with any markdown character and has no content after it
                const hasIncompleteMarkdown = markdownChars.some(char => {
                    const startsWithChar = lastLine.trimStart().startsWith(char);
                    const hasNoContent = lastLine.trimStart().length === char.length;
                    return startsWithChar && hasNoContent;
                });

                // console.log('Markdown detection:', {
                //     lastLine: lastLine,
                //     trimmedLine: lastLine.trimStart(),
                //     hasIncompleteMarkdown,
                //     markdownChars
                // });
                
                if (hasIncompleteMarkdown) {
                    // Find the last complete line's position
                    const lastCompleteLineEnd = line.lastIndexOf('\n');
                    const lastNonMarkdownPos = lastCompleteLineEnd === -1 ? 0 : lastCompleteLineEnd + 1;
                    
                    // console.log('Incomplete markdown found:', {
                    //     lastCompleteLineEnd,
                    //     lastNonMarkdownPos,
                    //     hiddenContent: line.substring(lastNonMarkdownPos)
                    // });
                    
                    line = line.substring(0, lastNonMarkdownPos);

                    // console.log('Markdown detection:', {
                    //     lastLine: lastLine,
                    //     trimmedLine: lastLine.trimStart(),
                    //     hasIncompleteMarkdown,
                    //     markdownChars
                    // });
                    // console.log('Formatted markdown:', {line});
                }
            }
            return line;
        };

        const hideTilNextChar = (line) => {
            let updatedLine = line;
            if (config.HIDE_TIL_NEXT_CHAR) {
                const hasUgly = charsToHide.some(char => {
                    const containsChar = updatedLine.includes(char);
                    return containsChar;
                });
        
                // Find the last occurrence of any character in charsToHide
                const lastUglyIndex = Math.max(...charsToHide.map(char => updatedLine.lastIndexOf(char)));
        
                // Check if there are any characters after the last occurrence of any character in charsToHide
                const hasContentAfterLastUgly = lastUglyIndex !== -1 && lastUglyIndex < updatedLine.length - 1;
        
                if (hasUgly && !hasContentAfterLastUgly) {
                    updatedLine = updatedLine.replace(charsToHide[0], '');
        
                    // console.log('Hide ugly characters:', {
                    //     originalLine: line,
                    //     hasUgly: hasUgly,
                    //     hasContentAfterLastUgly: hasContentAfterLastUgly,
                    //     fixedLine: updatedLine
                    // });
                }
            }
            return updatedLine;
        };

        let rawSplitParts = preProcessedContent.split(indicatorPart).slice(1);
        rawSplitParts = rawSplitParts.map(part => indicatorPart + part);
        // console.log('rawSplitParts', rawSplitParts);

        const rawSplitPartLines = rawSplitParts.map((_, index) => {
            const rawSplitPartLines = rawSplitParts[index];
            return rawSplitPartLines.split('\n\n');
        });
        // console.log('rawSplitPartLines', rawSplitPartLines);

        const formattedPartsLines = rawSplitPartLines.map((rawPartLines, i) => {
            // Calculate the total number of lines in parts before the current part (i)
            const prevLineCount = rawSplitPartLines
                .slice(0, i) // Only consider parts before the current part
                .map(part => {
                    // console.log('Lines being counted:', part);
                    return part.length;
                }) // Get the number of lines in each part
                .reduce((a, b) => a + b, 0) - i; // Sum up the line counts
            
             // console.log('prevLineCount', prevLineCount);
             // console.log('OVERALL currentIndexBeingStreamed', currentIndexBeingStreamed);
            const currentlyStreamingIndex = currentIndexBeingStreamed === -1 ? -1 : currentIndexBeingStreamed - prevLineCount;
             // console.log('OVERALL currentlyStreamingIndex', currentlyStreamingIndex);

            return interWeave(rawPartLines, currentlyStreamingIndex);
        })
        formattedContent = formattedPartsLines.join('\n\n');

        formattedContent = formattedContent.split('\n\n').map(line => {
            let formattedLine = delayLatex(line);
            formattedLine = delayMarkdown(formattedLine);
            formattedLine = hideTilNextChar(formattedLine);

            // console.log('line before', line);
            // console.log('line after', formattedLine);
            // console.log('line before', JSON.stringify(line));
            // console.log('line after', JSON.stringify(formattedLine));

            return formattedLine;
        }).join('\n\n');

        const isDone = content.includes(indicatorDone);
        // console.log('isDone', isDone);
        // console.log('checked for', indicatorDone, ' in ', content);

        if (config.SHOW_STREAM_CURSOR && !isDone) { // && ellipsisIndex === 0
            if (currentIndexBeingStreamed === rawSplitLines.length - 1) {
                const cursorIndex = 1; // Math.floor(formattedContent.length / 1) % cursorStates.length;
                formattedContent = formattedContent + cursorStates[cursorIndex];
            }
        }

        return { formattedContent, ellipsisIndex };
    };

    const startStream = useCallback((id: string) => {
        // console.log('Starting stream for id:', id);
        // Initialize stream content
        setStreams(prev => ({
            ...prev,
            [id]: { renderContent: "", rawContent: "" }
        }));

        if (id === 'test') {
            let currentIndex = 0;
            const testStreamInterval = setInterval(() => {
                const charactersToAdd = currentIndex === 0 ? debugStartCharacter : 1;
                if (currentIndex + charactersToAdd < debugContent.length + 1) {

                    setStreams(prevStreams => {
                        const prevStream = prevStreams[id] || { renderContent: "", rawContent: "" };
                        const newCharacters = debugContent.substring(currentIndex, currentIndex + charactersToAdd);
                        const newRawContent = prevStream.rawContent + newCharacters;
                        currentIndex += charactersToAdd;
                        
                        const formattedResult = formatStream(id, newRawContent, prevStream.renderContent, 0);
                        return {
                            ...prevStreams,
                            [id]: { renderContent: formattedResult.formattedContent, rawContent: newRawContent }
                        };
                    });
                } else {
                    clearInterval(testStreamInterval);
                }
            }, debugSpeed);
            const newStream: StreamState = { socket: null, id };
            setCurrentStream(newStream);
            return;
        } else {
            // Close existing socket if any
            if (streamRef.current?.socket) {
                // console.log('Closing existing stream:', streamRef.current.id);
                streamRef.current.socket.close();
            }

            // Initialize WebSocket connection
            const ws = new WebSocket(WS_URL);

            const newStream: StreamState = { socket: ws, id };
            streamRef.current = newStream;

            ws.onopen = () => {
                // console.log('WebSocket Connected for id:', id);
                // Send initial message with id
                ws.send(JSON.stringify({
                    action: "sendMessage",
                    message: id
                }));
            };

            ws.onmessage = (event) => {
                // Only process messages if this is still the current stream
                if (streamRef.current?.socket !== ws) {
                    // console.log('Ignoring message from old stream');
                    return;
                }

                try {
                    // console.log('Raw incoming data:', event.data);
                    
                    setStreams(prevStreams => {
                        const prevStream = prevStreams[id] || { renderContent: "", rawContent: "", messageBuffer: [] };
                        const raw = JSON.parse(event.data);
                        const message = raw.message;
                        const order = raw.sequenceNumber;

                        if (config.CONSOLE_LOG_STREAM_RAW === 'raw') {
                            console.log('RAW MESSAGE', message);
                        }
                        
                        // Add the new message to the buffer and sort
                        const updatedBuffer = [...(prevStream.messageBuffer || []), { message, order }]
                            .sort((a, b) => a.order - b.order);

                        // Reconstruct raw content from sorted buffer
                        const newRawContent = updatedBuffer.map(item => item.message).join('');

                        // console.log('Incoming message:', { message, order });
                        // console.log('Updated message buffer:', updatedBuffer);
                        // console.log('newRawContent', newRawContent);
                        
                        const formattedResult = formatStream(id, newRawContent, prevStream.renderContent, 0);
                        return {
                            ...prevStreams,
                            [id]: { 
                                renderContent: formattedResult.formattedContent, 
                                rawContent: newRawContent,
                                messageBuffer: updatedBuffer 
                            }
                        };
                    });
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.onclose = () => {
                // console.log('WebSocket disconnected for id:', id);
                // Clear current stream if it's the one being closed
                if (streamRef.current?.socket === ws) {
                    // console.log('Clearing stream state for id:', id);
                    streamRef.current = null;
                    setCurrentStream(null);
                    setStreams(prev => {
                        const { [id]: removed, ...rest } = prev;
                        return rest;
                    });
                }
            };

            setCurrentStream(newStream);
        }
    }, []);

    const endStream = useCallback((id: string) => {
        // console.log('Attempting to end stream for id:', id);
        if (streamRef.current?.id === id) {
                if (streamRef.current?.socket) {
                    streamRef.current.socket.close();
                }
                streamRef.current = null;
                setCurrentStream(null);
                setStreams(prev => {
                    const { [id]: removed, ...rest } = prev;
                    return rest;
                });
                // console.log('Socket closed for id:', id);
        } else {
            // console.log('No matching stream found for id:', id);
        }
    }, []);

    const getRenderContent = useCallback((id: string): string => {
        const rc = streams[id]?.renderContent || "";
        // console.log('getRenderContent', id, rc);
        return rc;
    }, [streams]);

    const getRawContent = useCallback((id: string): string => {
        const rc = streams[id]?.rawContent || "";
        // console.log('getRawContent', id, rc);
        return rc;
    }, [streams]);

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (streamRef.current) {
                // console.log('Cleanup: closing stream for id:', streamRef.current.id);
                streamRef.current.socket.close();
                streamRef.current = null;
            }
        };
    }, []); // Empty dependency array since we only need cleanup on unmount

    return { getRenderContent, getRawContent, startStream, endStream };
}
