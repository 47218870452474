import { problem } from "../Generate";

export const generateAddition = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const addProblem = (template: any) => {
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      ...template
    });
  };

  const generateUpTo10 = (val1: number, val2: number, maxSum: number) => {
    const sum = val1 + val2;
    if (sum > maxSum) return;

    count += 1;
    const template = {
      name: "Addition <= 10: Number Line",
      numColors: 3,
      text: `${val1} + ${val2} = ${Math.random() < 0.5 ? '?' : '_'}`,
      variables: {
        val2: "${sum - val1}"
      },
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: maxSum,
          tick: 1,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: sum,
              endVar: "sum",
              hopInterval: "${sum === val1 ? 0 : 1}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "${sum >= val1 ? '+' : ''}${val2}",
              endLabel: "${sum}",
              startColor: 1,
              lineColor: 2,
              endColor: 3,
              startDraggable: true,
              endDraggable: true,
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: maxSum,
          tick: 1,
          data: [
            {
              type: "hop",
              start: val1,
              end: sum,
              hopInterval: sum === val1 ? 0 : 1,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: "+" + val2.toString(),
              endLabel: sum.toString(),
              startColor: 1,
              lineColor: 2,
              endColor: 3
            }
          ]
        }
      ],
      answer: {
        multipleChoice: Array.from({ length: maxSum + 1 }, (_, i) => i.toString()),
        multiselect: false,
        correctAnswer: [sum.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const generateUpTo100 = (val1: number, val2: number) => {
    const sum = val1 + val2;
    if (sum > 100) return;

    count += 1;
    const val2_num_tens = Math.floor(val2/10);
    const val2_tens = val2 >= 0 ? Math.floor(val2/10) * 10 : Math.ceil(val2/10) * 10;
    const val2_ones = val2 % 10;
    const tens_sum = val1 + val2_tens;

    const template = {
      name: "Addition 11 - 100: Number Line",
      numColors: 3,
      variables: {
        val2: "${sum - val1}",
        val2_num_tens: "${Math.floor(val2/10)}",
        val2_tens: "${val2 >= 0 ? Math.floor(val2/10) * 10 : Math.ceil(val2/10) * 10}",
        val2_ones: "${val2 % 10}",
        tens_sum: "${val1 + val2_tens}"
      },
      text: `${val1} + ${val2} = ?`,
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: "${tens_sum}",
              hopInterval: 10,
              startPoint: "dot",
              endPoint: "${val2_tens !== 0 ? 'dot' : undefined}",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "${val2_tens !== 0 ? (sum >= val1 ? '+' : '') + val2_tens : ' '}",
              endLabel: "${val2_tens !== 0 ? tens_sum : ''}",
              startColor: 1,
              lineColor: 2,
              endColor: -1,
              startDraggable: true,
              dragTick: 1
            },
            {
              type: "hop",
              start: "${tens_sum}",
              end: sum,
              endVar: "sum",
              hopInterval: "${val2_ones !== 0 || val2_tens !== 0 ? 1 : 0}",
              startPoint: "${val2_tens !== 0 ? 'dot' : undefined}",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val2_tens !== 0 ? tens_sum : ''}",
              lineLabel: "${val2_ones !== 0 || (val2_tens === 0 && val2_ones === 0) ? (sum >= val1 ? '+' : '') + val2_ones : ' '}",
              endLabel: "${sum}",
              startColor: -1,
              lineColor: 2,
              endColor: 3,
              endDraggable: true,
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              end: tens_sum,
              hopInterval: 10,
              startPoint: "dot",
              endPoint: val2_tens !== 0 ? "dot" : undefined,
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: val2_tens !== 0 ? (sum >= val1 ? "+" : "") + val2_tens.toString() : " ",
              endLabel: val2_tens !== 0 ? tens_sum.toString() : "",
              startColor: 1,
              lineColor: 2,
              endColor: -1
            },
            {
              type: "hop",
              start: tens_sum,
              end: sum,
              hopInterval: val2_ones !== 0 || val2_tens !== 0 ? 1 : 0,
              startPoint: val2_tens !== 0 ? "dot" : undefined,
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: val2_tens !== 0 ? tens_sum.toString() : "",
              lineLabel: val2_ones !== 0 || (val2_tens === 0 && val2_ones === 0) ? (sum >= val1 ? "+" : "") + val2_ones.toString() : " ",
              endLabel: sum.toString(),
              startColor: -1,
              lineColor: 2,
              endColor: 3
            }
          ]
        }
      ],
      answer: {
        multipleChoice: (() => {
          const correctAnswer = sum.toString();
          const options = new Set<string>();
          
          options.add(correctAnswer);
          options.add((sum - 10).toString());
          options.add((sum + 10).toString());
          
          while (options.size < 6) {
            const offset = Math.floor(Math.random() * 19) - 9;
            if (offset !== 0 && offset !== -10 && offset !== 10) {
              options.add((sum + offset).toString());
            }
          }
          
          return Array.from(options).sort((a, b) => parseInt(a) - parseInt(b));
        })(),
        multiselect: false,
        correctAnswer: [sum.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const generateMultipleAddends = (addends: number[]) => {
    if (addends.length !== 3) {
      throw new Error("This template requires exactly 3 addends");
    }
    
    const [val1, val2, val3] = addends;
    const sum1_2 = val1 + val2;
    const sum_all = sum1_2 + val3;
    
    count += 1;
    const template = {
      name: "Addition <= 100: 3 Addends",
      numColors: addends.length+1,
      text: `${val1} + ${val2} + ${val3} = ?`,
      variables: {
        val1: "${sum1_2 - val2}",
        val2: "${sum1_2 - val1}",
        val3: "${sum_all - sum1_2}",
        sum1_2: "${val1 + val2}",
        sum_all: "${val1 + val2 + val3}"
      },
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: sum1_2,
              endVar: "sum1_2",
              hopInterval: "${val2}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "+${val2}",
              endLabel: "${sum1_2}",
              startColor: 1,
              lineColor: 2,
              endColor: -1,
              startDraggable: true,
              startDragMax: "${sum1_2}",
              endDraggable: true,
              endDragMin: "${val1}",
              endDragMax: "${sum_all}",
              dragTick: 1
            },
            {
              type: "hop",
              start: "${sum1_2}",
              end: sum_all,
              endVar: "sum_all",
              hopInterval: "${val3}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${sum1_2}",
              lineLabel: "+${val3}",
              endLabel: "${sum_all}",
              startColor: -1,
              lineColor: 3,
              endColor: 4,
              endDraggable: true,
              endDragMin: "${sum1_2}",
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              end: sum1_2,
              hopInterval: val2,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: "+" + val2.toString(),
              endLabel: sum1_2.toString(),
              startColor: 1,
              lineColor: 2,
              endColor: -1
            },
            {
              type: "hop",
              start: sum1_2,
              end: sum_all,
              hopInterval: val3,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: sum1_2.toString(),
              lineLabel: "+" + val3.toString(),
              endLabel: sum_all.toString(),
              startColor: -1,
              lineColor: 3,
              endColor: 4
            }
          ]
        }
      ],
      answer: {
        multipleChoice: Array.from(new Set([
          sum_all.toString(),
          sum1_2.toString(),
          (val2 + val3).toString(),
          (sum_all - 10).toString(),
          (sum_all + 10).toString(),
          ...Array(3).fill(0).map(() => (sum_all + (Math.random() < 0.5 ? -1 : 1) * (1 + Math.floor(Math.random() * 9))).toString())
        ])).slice(0, 6).sort((a, b) => parseInt(a) - parseInt(b)),
        multiselect: false,
        correctAnswer: [ sum_all.toString() ]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const PERCENT_TO_GEN = {
    UP_TO_10: 1, // 100% chance to generate
    UP_TO_100: 0.01, // 1% chance to generate
    THREE_ADDENDS: 0.001 // 0.1% chance to generate
  };

  // Generate all possible combinations of addition up to 10
  for (let i = 0; i <= 10; i++) {
    for (let j = 0; j <= 10 - i; j++) {
      if (Math.random() < PERCENT_TO_GEN.UP_TO_10) {
        generateUpTo10(i, j, 10);
        if (problems.length >= maximum) return problems;
      }
    }
  }

  // Generate combinations for numbers between 11 and 100
  for (let sum = 12; sum <= 100; sum++) {
    for (let i = 1; i < sum; i++) {
      const j = sum - i;
      if (Math.random() < PERCENT_TO_GEN.UP_TO_100) {
        generateUpTo100(i, j);
        if (problems.length >= maximum) return problems;
      }
    }
  }

  // Generate problems with 3 addends
  const minAddendVal = 5;
  const maxSum = 100;
  for (let sum = 3 * minAddendVal; sum <= maxSum; sum++) {
    for (let val1 = minAddendVal; val1 <= sum - 2 * minAddendVal; val1++) {
      for (let val2 = minAddendVal; val2 <= sum - val1 - minAddendVal; val2++) {
        const val3 = sum - val1 - val2;
        if (val3 >= minAddendVal && Math.random() < PERCENT_TO_GEN.THREE_ADDENDS) {
          const addends = [val1, val2, val3];
          generateMultipleAddends(addends);
          if (problems.length >= maximum) return problems;
        }
      }
    }
  }

  return problems;
};
