// TopBar.tsx
import React, { FunctionComponent, useState, MouseEvent, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import Button from '../../components/basic/Button';
import ButtonLink from '../../components/basic/ButtonLink';
import TabPanel from '../../components/basic/TabPanel';
import MenuOpen from '../../components/basic/MenuOpen';
import { useLogout, useGetUserInfo, getIsLoggedIn } from '../../apollo/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

const TopContainer = styled.div<{ mode: string, inputMode: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  background-color: ${Colors.transparent};
  z-index: 100;
  padding: 10px;
  gap: 5px;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 5px;
`;

const Middle = styled.div`
  display: flex;
  height: 54.5px;
  margin: 0 auto;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 5px;
`;

const Title = styled.div<{ $inputmode: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  color: ${props => props.$inputmode === 'Upload' ? Colors.white : Colors.grey8};
  font-size: 20px;
  font-family: ${Fonts.lexendSemiBold.fontFamily};
  font-weight: ${Fonts.lexendSemiBold.fontWeight};
  text-align: center;
  margin: 0;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface TopBarProps {
  mode: 'example' | 'new' | 'solution';
  problemId: string | undefined;
  handleNewProblemClick?: () => void;
  handleMenuClick?: () => void;
  handleTabSelect?: (selectedTabName: string) => void;
  handleUploadBackClick?: () => void;
  loggedIn?: boolean;
  userInfo?: any;
  isMobile: boolean;
  menuOpen: boolean;
  inputMode: 'Type' | 'Scan' | 'Upload';
  promptLogin: boolean;
  NEW_ACCOUNT_UI: boolean;
}

const TopBar: FunctionComponent<TopBarProps> = ({
  mode,
  problemId,
  handleNewProblemClick,
  handleMenuClick,
  handleTabSelect,
  handleUploadBackClick,
  loggedIn,
  userInfo,
  isMobile,
  menuOpen,
  inputMode,
  promptLogin,
  NEW_ACCOUNT_UI,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useLogout();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  // Close account menu when mode, inputMode, or location changes
  useEffect(() => {
    setAccountMenuOpen(false);
    setAccountMenuAnchorEl(null);
  }, [mode, inputMode, location, menuOpen]);

  const onLoginClick = () => {
    navigate("/log-in");
  };

  const onSignUpClick = () => {
    navigate("/sign-up");
  };

  const onAccountClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
    setAccountMenuOpen(!accountMenuOpen);
  };

  const accountMenuOptions = [
    ...(loggedIn && userInfo?.email ? [{
      text: userInfo?.email,
      icon: "person",
    }] : []),
    // {
    //   text: '🚀 Upgrade',
    //   // icon: 'buy',
    //   onClick: () => console.log('upgrade clicked'),
    // },
    ...(isMobile ? [{
      text: "Join Discord",
      icon: "discord",
      onClick: () => onDiscordClick(),
    }] : []),
    {
      text: "Log Out",
      icon: "logout",
      onClick: () => onLogoutClick(),
    },
  ];

  const onDiscordClick = () => {
    window.open('https://discord.gg/96PVDXMhkc', '_blank');
  };

  const onLogoutClick = async () => {
    await logout();
    navigate("/problem");
  };

  return (
    <TopContainer id="topContainer" mode={mode} inputMode={inputMode} key={inputMode}>
      {inputMode === 'Upload' ? (
        <>
          <Left>
            <Button size={'small'} icon={'left'} color={'ghost white'} onClick={handleUploadBackClick} />
          </Left>
          <Middle>
            <Title $inputmode={inputMode}>Crop one problem.</Title>
          </Middle>
          <Right></Right>
        </>
      ) : (
        <>
          <Left>
            <Button
              size={'small'}
              icon={!menuOpen ? 'menu' : 'delete'}
              color={mode === 'new' && inputMode === 'Scan' ? 'ghost white' : 'ghost'}
              onClick={handleMenuClick}
            />
          </Left>
          <Middle>
            {mode === 'example' ? (
              <Title $inputmode={inputMode}>Example Problems</Title>
            ) : mode === 'new' ? (
              <TabPanel tabs={[{ name: 'Type', icon: 'keyboard' }, { name: 'Scan', icon: 'camera', disabled: promptLogin }]} onSelect={handleTabSelect} defaultTabName={inputMode} />
            ) : null}
          </Middle>
          <Right>
            <>
            {mode === 'new' ? (
              (!NEW_ACCOUNT_UI || (NEW_ACCOUNT_UI && !isMobile)) && (
                <Button size={'small'} icon={'discord'} color={inputMode === 'Scan' ? 'ghost white' : 'ghost'} onClick={onDiscordClick} />
              )
            ) : (
              <Button
                size={'small'}
                icon={mode === 'solution' ? 'plus' : mode === 'example' ? 'delete' : ''}
                color={'ghost'}
                onClick={handleNewProblemClick}
              />
            )}
            {NEW_ACCOUNT_UI && (
              mode !== 'example' && loggedIn === false && !isMobile? (
                <ButtonContainer>
                  <Button size={'tiny'} text={'Log In'} color={'ghost'} onClick={onLoginClick} />
                  <Button size={'tiny'} text={'Sign Up'} color={'black'} onClick={onSignUpClick} />
                </ButtonContainer>
              ) : mode !== 'example' && loggedIn === true ? (
                <>
                  <Button 
                    size={'small'} 
                    icon={'person-fill'} 
                    color={inputMode === 'Scan' ? 'ghost white' : 'ghost'} 
                    onClick={onAccountClick} 
                  />
                  <MenuOpen 
                    anchorEl={accountMenuAnchorEl} 
                    open={accountMenuOpen} 
                    options={accountMenuOptions}
                    color={inputMode === 'Scan' ? 'black' : 'white'}
                  />
                </>
              ) : null
            )}
            </>
          </Right>
        </>
      )}
    </TopContainer>
  );
};

export default TopBar;
