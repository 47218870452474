import React, { createContext, useContext, ReactNode } from 'react';

interface ConfigContextType {
  DEBUG_GENERATE: boolean;
  INTERNAL_PUBLISH: boolean;
  CONSOLE_LOG_STREAM_RAW: 'every-tick' | 'parts' | 'raw' | 'none';

  CALCULATORS_IN_MENU: boolean;
  SHOW_WIP_CALCULATORS: boolean;
  LOCAL_CALCULATORS: boolean;
  CALCULATOR_SHOW_LINK_CARD: boolean;
  
  LOCAL_PROBLEMS: boolean;
  EXAMPLES_IN_MENU: boolean;
  
  NEW_ACCOUNT_UI: boolean;
  NUM_NOT_LOGGED_IN_PROBLEMS: number;
  COPY_OPTIONS: boolean;
  PASTE_IMAGE_MESSAGE: boolean;

  SWITCH_POLL_TO_STREAM: boolean;
  SHOW_STREAM_CURSOR: boolean;
  DELAY_STREAM_LATEX: boolean;
  DELAY_STREAM_MARKDOWN: boolean;
  FORMAT_STREAM: boolean;
  HIDE_TIL_NEXT_CHAR: boolean;
  ELLIPSIS_IN_PROGRESS: boolean;
}

const config: ConfigContextType = {
  DEBUG_GENERATE: false,
  INTERNAL_PUBLISH: false,
  CONSOLE_LOG_STREAM_RAW: 'none',
  
  CALCULATORS_IN_MENU: true,
  SHOW_WIP_CALCULATORS: false,
  LOCAL_CALCULATORS: true,
  CALCULATOR_SHOW_LINK_CARD: true,
  
  LOCAL_PROBLEMS: false,
  EXAMPLES_IN_MENU: false,
  
  NEW_ACCOUNT_UI: true,
  NUM_NOT_LOGGED_IN_PROBLEMS: 1,
  COPY_OPTIONS: false,
  PASTE_IMAGE_MESSAGE: true,

  SWITCH_POLL_TO_STREAM: true,
  SHOW_STREAM_CURSOR: false,
  DELAY_STREAM_LATEX: true,
  DELAY_STREAM_MARKDOWN: true,
  FORMAT_STREAM: true,
  HIDE_TIL_NEXT_CHAR: true,
  ELLIPSIS_IN_PROGRESS: false,
};

const ConfigContext = createContext<ConfigContextType>(config);

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

// Static config values that don't need context
export const defaultConfig = {
  DEBUG_GENERATE: config.DEBUG_GENERATE,
  INTERNAL_PUBLISH: config.INTERNAL_PUBLISH,
  CONSOLE_LOG_STREAM_RAW: config.CONSOLE_LOG_STREAM_RAW,

  CALCULATORS_IN_MENU: config.CALCULATORS_IN_MENU,
  SHOW_WIP_CALCULATORS: config.SHOW_WIP_CALCULATORS,
  LOCAL_CALCULATORS: config.LOCAL_CALCULATORS,
  CALCULATOR_SHOW_LINK_CARD: config.CALCULATOR_SHOW_LINK_CARD,

  LOCAL_PROBLEMS: config.LOCAL_PROBLEMS,
  EXAMPLES_IN_MENU: config.EXAMPLES_IN_MENU,

  NEW_ACCOUNT_UI: config.NEW_ACCOUNT_UI,
  NUM_NOT_LOGGED_IN_PROBLEMS: config.NUM_NOT_LOGGED_IN_PROBLEMS,
  COPY_OPTIONS: config.COPY_OPTIONS,
  PASTE_IMAGE_MESSAGE: config.PASTE_IMAGE_MESSAGE,

  SWITCH_POLL_TO_STREAM: config.SWITCH_POLL_TO_STREAM,
  SHOW_STREAM_CURSOR: config.SHOW_STREAM_CURSOR,
  DELAY_STREAM_LATEX: config.DELAY_STREAM_LATEX,
  DELAY_STREAM_MARKDOWN: config.DELAY_STREAM_MARKDOWN,
  FORMAT_STREAM: config.FORMAT_STREAM,
  HIDE_TIL_NEXT_CHAR: config.HIDE_TIL_NEXT_CHAR,
  ELLIPSIS_IN_PROGRESS: config.ELLIPSIS_IN_PROGRESS
};