import { useState, useEffect, useMemo, useCallback } from 'react';
import { transformInitialContentFormat } from './TransformContent';
import { useGetAllProblems } from "../../apollo/useQuestion";
import { useGetAllContent, useGetProblemByUrl } from '../../apollo/useContent';
import { useConfig } from '../../utils/config';

import Example from '../problem/json/Example.json';
import Modes from '../problem/json/Modes.json';
// import Eval from '../pages/problem/json/eval/Eval.json';
// import Hard from '../pages/problem/json/Hard.json';
// import AI from '../pages/problem/json/AI-Static.json';
// import YC from '../pages/problem/json/YC.json';
// import Template from '../internal/generate/Template.json';
import Dev from '../problem/json/Dev.json';
import Logic from '../problem/json/Logic.json';
// import Generate from "../internal/generate/generate.json";

import Calculators from '../content/json/Calculators.json';
import CalculatorsWIP from '../content/json/Calculators_WIP.json';
import AllCalculatorsData from '../content/json/All-Calculators.json';

export type ContentType = 'problem' | 'calculator';

// PROBLEMS ---------------------------------------------------------------------

export interface ProblemFetchResult {
  localProblems?: any[];
  exampleProblems?: any[];
  menuProblems?: any[];
  allProblemCount?: number;
  allProblemsLoading?: boolean;
  fetchAllProblemsForMenu?: () => Promise<void>;
}

export function ProblemFetch(type: ContentType): ProblemFetchResult {
  const config = useConfig();
  const problemSource = null; // Eval, Dev, Hard, YC, Logic, AI, Generate, Modes
  const [menuProblems, setMenuProblems] = useState([]);
  const [allProblemCount, setAllProblemCount] = useState(0);
  const { data: backendAllProblemsData, loading: allProblemsLoading, error: allProblemsError, fetchAllProblems, refetch: refetchAllProblems } = useGetAllProblems();

  useEffect(() => { 
    if (!menuProblems[0]?.id) {
      fetchAllProblemsForMenu();
    }
  }, [menuProblems[0]]); // init menu problems

  const transformedJsonProblems = useMemo(() => {
    const problems = (problemSource?.PROBLEMS || []).map(problem => {
      const transformedProblem = transformInitialContentFormat(problem);
      // console.log('CONTENT FETCH: Transformed JSON problem data:', transformedProblem);
      return transformedProblem;
    });
    return problems;
  }, [problemSource]);
  
  const transformedExampleProblems = useMemo(() => {
    if (Example) {
      let problems = Example.PROBLEMS || [];
      problems = problems.map(problem => {
        const transformedProblem = transformInitialContentFormat(problem);
        // console.log('CONTENT FETCH: Transformed example problem data:', transformedProblem);
        return transformedProblem;
      });
      // console.log("Finished transformation for example problems...");
      return problems;
    }
    return [];
  }, [Example, problemSource, transformedJsonProblems]);
  
  const exampleProblems = useMemo(() => {
    const hasDisplayProperty = transformedExampleProblems.some(problem => 'display' in problem);
    const filteredProblems = hasDisplayProperty 
      ? transformedExampleProblems.filter(problem => problem?.display !== false)
      : transformedExampleProblems;
    // console.log('CONTENT FETCH: Final example problems:', filteredProblems);
    return filteredProblems;
  }, [transformedExampleProblems]);

  const fetchAllProblemsForMenu = async() => {
    try {
      const backendAllProblems = await refetchAllProblems();
      // console.log('fetchAllProblemsForMenu', backendAllProblems);
      let allProblems = config.LOCAL_PROBLEMS ? transformedJsonProblems : [...backendAllProblems];
      if (config.EXAMPLES_IN_MENU) {
        allProblems = [...allProblems, ...exampleProblems];
      }
      setMenuProblems(allProblems);
      setAllProblemCount(allProblems.length);
      // console.log("Menu problems set", allProblems.length, allProblems);
    } catch (error) {
      console.error("Error fetching all problems for menu:", error);
    }
  };

  return {
    localProblems: transformedJsonProblems,
    exampleProblems,
    menuProblems,
    allProblemCount,
    allProblemsLoading,
    fetchAllProblemsForMenu
  };
}

// CALCULATORS ---------------------------------------------------------------------

export interface CalculatorFetchResult {
  currentCalculator?: any;
  fetchAndSetCalculatorByUrl?: (url: string) => Promise<any | null>;
  resetCurrentCalculator?: () => void;

  menuCalculators?: any[];
  menuSections?: string[];
  fetchAllCalculatorsForMenu?: () => Promise<void>;
  findRelatedCalculators?: (calculatorName: string) => any[];

  getLocalCalculatorOverview?: (id: string) => any;
  getRawLocalCalculator?: (id: string) => any;
}

export function CalculatorFetch(type: ContentType): CalculatorFetchResult {
  const config = useConfig();
  const localCalculatorSource = config.SHOW_WIP_CALCULATORS ? 
    { PROBLEMS: [...Calculators.PROBLEMS, ...CalculatorsWIP.PROBLEMS] } : Calculators;

  const [menuCalculators, setMenuCalculators] = useState<any[]>([]);
  const [menuSections, setMenuSections] = useState<string[]>([]);
  const [currentCalculator, setCurrentCalculator] = useState(null);

  const { data: backendAllCalculatorsData, loading: allCalculatorsLoading, error: allCalculatorsError, fetchAllCalculators, refetch: refetchAllCalculators } = useGetAllContent();
  const { data: backendCalculatorData, loading: calculatorLoading, error: calculatorError, fetchProblemByUrl, fetchRawJsonByURL } = useGetProblemByUrl();

  useEffect(() => { 
    if (config.LOCAL_CALCULATORS) {
      const sections = Object.keys(AllCalculatorsData.CALCULATORS);
      setMenuSections(sections);
      
      const allCalculators = sections.flatMap(section => 
        AllCalculatorsData.CALCULATORS[section].map(calc => ({
          ...calc,
          section
        }))
      );
      setMenuCalculators(allCalculators);
    } else {
      if (menuCalculators.length === 0) {
        fetchAllCalculatorsForMenu();
      }
    }
  }, [config.LOCAL_CALCULATORS]);

  const transformedLocalCalculators = useMemo(() => {
    const problems = (localCalculatorSource?.PROBLEMS || []).map(problem => {
      const transformedProblem = transformInitialContentFormat(problem);
      // console.log('CONTENT FETCH: Transformed JSON calculator data:', transformedProblem);
      return transformedProblem;
    });
    return problems;
  }, [localCalculatorSource]);

  const fetchAllCalculatorsForMenu = async() => {
    try {
      const backendAllCalculators = await refetchAllCalculators();
      // console.log('fetchAllCalculatorsForMenu', backendAllCalculators);
      let allCalculators = config.LOCAL_CALCULATORS ? transformedLocalCalculators : [...backendAllCalculators];
      
      const sections = Array.from(new Set(allCalculators.map(calc => calc.section)));
      setMenuSections(sections);
      setMenuCalculators(allCalculators);
      // console.log("Menu calculators set", menuCalculators.length, menuSections, menuCalculators);
    } catch (error) {
      console.error("Error fetching all calculators for menu:", error);
    }
  };

  const getDescription = useCallback((id: string) => {
    let calc = null;
    if (config.LOCAL_CALCULATORS) {
      const sections = Object.keys(AllCalculatorsData.CALCULATORS);
      const allCalculators = sections.flatMap(section => 
        AllCalculatorsData.CALCULATORS[section].map(calc => ({
          ...calc,
          section
        }))
      );
      calc = allCalculators.find(calc => calc.id === id);
    } else  {
      calc = backendAllCalculatorsData?.find(calc => calc.id === id);
    }
    const description = calc?.description || null;
    // console.log('getContentDescription', id, calc, description);
    return description;
  }, [AllCalculatorsData, backendAllCalculatorsData]);

  const getRawLocalCalculator = useCallback((id: string) => {
    const calc = (localCalculatorSource?.PROBLEMS || []).find(calc => calc.id === id);
    return calc || null;
  }, [localCalculatorSource]);

  const getLocalCalculatorOverview = useCallback((id: string) => {
    const sectionKeys = Object.keys(AllCalculatorsData.CALCULATORS);
    const calculatorOverview = sectionKeys.reduce((foundCalculator, sectionKey) => {
      if (foundCalculator) {
        return foundCalculator;
      }
      const section = AllCalculatorsData.CALCULATORS[sectionKey];
      const calcOverview = section.find(calc => calc.id === id);
      return calcOverview || null;
    }, null);
    return calculatorOverview;
  }, [AllCalculatorsData])

  const fetchAndSetCalculatorByUrl = useCallback(async (url: string) => {
    // console.log('fetchAndSetCalculatorByUrl', url);
    let calc = null;
    if (config.LOCAL_CALCULATORS && url) {
      // console.log('local', url);
      const foundCalculator = transformedLocalCalculators.find(calculator => calculator.id === url);
      if (foundCalculator) {
        // console.log('localCalculator', foundCalculator);
        calc = foundCalculator;
      }
    } else if (!config.LOCAL_CALCULATORS && url) {
      // console.log('backend', url);
      try {
        const backendCalculator = await fetchProblemByUrl(url);
        if (backendCalculator) {
          // console.log('backendCalculator', backendCalculator);
          calc = backendCalculator;
        }
      } catch (error) {
        console.error("Error fetching calculator from backend:", error);
      }
    }

    const desc = getDescription(url);
    if (calc && desc) {
      calc.description = desc;
      // console.log('fetchAndSetCalculatorByUrl', calc, 'description', desc);
    }

    // console.log('fetchAndSetCalculatorByUrl', calc);
    setCurrentCalculator(calc);
    return calc;
  }, [config.LOCAL_CALCULATORS, transformedLocalCalculators, fetchProblemByUrl, menuCalculators]);

  const findRelatedCalculators = useCallback((calculatorName: string) => {
    const normalizedName = calculatorName.replace(/\s*Calculator\s*$/i, '').trim().toLowerCase();
    for (const category in AllCalculatorsData.CALCULATORS) {
      const calculators = AllCalculatorsData.CALCULATORS[category];
      const currentCalc = calculators.find(calc => 
        calc.name.replace(/\s*Calculator\s*$/i, '').trim().toLowerCase().includes(normalizedName)
      );
      
      if (currentCalc && currentCalc.related) {
        return currentCalc.related.map(relatedName => {
          for (const cat in AllCalculatorsData.CALCULATORS) {
            const foundCalc = AllCalculatorsData.CALCULATORS[cat].find(calc => 
              calc.name.replace(/\s*\*?\s*$/i, '').trim().toLowerCase().includes(
                relatedName.replace(/\s*\*?\s*$/i, '').trim().toLowerCase()
              )
            );
            
            if (foundCalc) {
              return {
                name: foundCalc.name,
                id: foundCalc.id || '',
                icon: foundCalc.icon || null
              };
            }
          }
          return null;
        }).filter(calc => calc !== null);
      }
    }
    return [];
  }, []);

  const resetCurrentCalculator = useCallback(() => {
    setCurrentCalculator(null);
  }, []);

  return {
    currentCalculator,
    fetchAndSetCalculatorByUrl,
    resetCurrentCalculator,

    menuCalculators,
    menuSections,
    fetchAllCalculatorsForMenu,
    findRelatedCalculators,

    getLocalCalculatorOverview,
    getRawLocalCalculator
  };
}
