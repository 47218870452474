// HoverContext.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import Colors, { getColorViz } from '../../components/common/Colors';

interface HoverContextProps {
  hoveredColor: string | null;
  hoveredId: string | null;
  handleMouseEnter: (color: string, id?: string) => void;
  handleMouseLeave: () => void;
  getImagePath: (basePath: string, color: string) => string;
  adjustColorForHover: (color: string) => string;
  updateColorMap: (colors: string[]) => string[];
  getColorNameByValue: (value: string) => string;
  getColorValueByName: (name: string) => string;
  overrideColors: (colors: string[], currentProblem: any) => string[];
}

const HoverContext = createContext<HoverContextProps | undefined>(undefined);

export const HoverProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [hoveredColor, setHoveredColor] = useState<string | null>(null);
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const handleMouseEnter = (color: string, id?: string) => {
    let colorValue = color !== null ? getColorValueByName(color).toLowerCase() : null;
    let hoveredColorValue = hoveredColor !== null ? getColorValueByName(hoveredColor).toLowerCase() : null;
  
    if (colorValue === Colors.grey2 || colorValue === hoveredColorValue) {
      colorValue = null;
    }
    // console.log('mouse enter: ', color, colorValue, hoveredColorValue);

    setHoveredColor(colorValue);
    if (id && colorValue !== null) {
      setHoveredId(id);
    }
    else {
      setHoveredId(null);
    }
  };  

  const handleMouseLeave = () => {
    setHoveredColor(null);
    setHoveredId(null);
  };

  const adjustColorForHover = (color: string) => {
    if (hoveredColor && color.toLowerCase() !== hoveredColor.toLowerCase()) {
      return Colors.grey2;
    }
    return color;
  };

  const getColorNameByValue = (value: string) => {
    if (value.toLowerCase() === Colors.grey2.toLowerCase()) {
      return 'grey2';
    }

    let entry = Object.entries(getColorViz).find(([_, v]) => v?.toLowerCase() === value?.toLowerCase());
    if (!entry) {
      const colorVizEntry = getColorViz().find(color => color.value?.toLowerCase() === value?.toLowerCase());
      if (colorVizEntry) {
        return colorVizEntry.name.toLowerCase();
      }
    }
    return entry ? entry[0] : value;
  };

  const getColorValueByName = (name: string) => {
    if (name.toLowerCase() === Colors.grey2.toLowerCase()) {
      return Colors.grey2;
    }

    let entry = Object.entries(getColorViz).find(([n]) => n?.toLowerCase() === name?.toLowerCase());
    if (!entry) {
      const colorVizEntry = getColorViz().find(color => color.name?.toLowerCase() === name?.toLowerCase());
      if (colorVizEntry) {
        return colorVizEntry.value;
      }
    }
    return entry ? entry[1] : name;
  };

  const getImagePath = (basePath: string, color?: string) => {
    let imagePath = '';
    if (color) {
      const colorName = getColorNameByValue(color);
      imagePath = `${process.env.PUBLIC_URL}/images/${basePath}-${colorName.toLowerCase()}.png`;
    } else {
      imagePath = `${process.env.PUBLIC_URL}/images/${basePath}.png`;
    }
    return imagePath;
  };

  const updateColorMap = (colors: string[]) => {
    const colorMap = hoveredColor
      ? colors.map(c => c === hoveredColor ? c : Colors.grey2)
      : colors;
    return colorMap;
  };

  const overrideColors = (colors: string[], currentProblem: any): string[] => {
    if (!colors || !Array.isArray(colors)) return [];
    if (currentProblem?.colorOverride) {
      return currentProblem.colorOverride.map((color: string) => getColorValueByName(color));
    }
    return colors;
  };  

  // console.log('HOVERED COLOR: ', hoveredColor? getColorNameByValue(hoveredColor) : null);

  return (
    <HoverContext.Provider value={{ hoveredColor, hoveredId, handleMouseEnter, handleMouseLeave, getImagePath, adjustColorForHover, updateColorMap, getColorNameByValue, getColorValueByName, overrideColors }}>
      {children}
    </HoverContext.Provider>
  );
};

export const useHover = () => {
  const context = useContext(HoverContext);
  if (context === undefined) {
    throw new Error('useHover must be used within a HoverProvider');
  }
  return context;
};
