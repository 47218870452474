// Problem.tsx
import React, { FunctionComponent, useState, useEffect, useRef, useCallback, useLayoutEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Colors, { getComplementaryColors } from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";
import { useHover } from './HoverContext';
import { useGetUserInfo, getIsLoggedIn } from "../../apollo/useAuth";
import { useComputeQuestionResponse, useGetQuestionResponseById } from "../../apollo/useQuestion";
import LoadingSpinner from "../../components/basic/LoadingSpinner";
import ProblemContent from "./ProblemContent";
import TopBar from "./TopBar";
import Menu from './Menu';
import { useMediaQuery, useTheme } from '@mui/material';
import Scan from "./Scan";
import DebugOverlay from "../internal/DebugOverlay";
import { StateProvider } from "../shared/State";
import { useComputedProblems } from "./ComputedProblemsContext";
import { ProblemFetch } from '../shared/ContentFetch';
import { defaultConfig, useConfig } from '../../utils/config';
import { Helmet } from "react-helmet-async";
import { useStream } from "../../apollo/useStream";

const Root = styled.div<{ $mode: string, $isMenuOpen: boolean, $isMobile: boolean, $inputMode: 'Type' | 'Scan', $bottomOffset: number }>`
  width: 100%;
  min-height: 100svh;
  max-height: 100svh;
  position: relative;
  background-color: ${({ $mode, $inputMode }) => 
    $mode === 'new' && $inputMode === 'Scan' ? Colors.grey8 
    : $mode === 'new' && $inputMode === 'Type' ? Colors.background 
    : Colors.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  padding-left: ${({ $isMenuOpen, $isMobile }) => ($isMenuOpen && !$isMobile ? '300px' : '0')};
  transition: padding-left 0.3s ease;
  overflow: hidden;

  ${({ $mode }) => $mode === 'example' && `
    animation: slideInFromBottom 0.3s ease-out;
  `}
  @keyframes slideInFromBottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const ScrollContainer = styled.div<{ $mode: string, $bottomOffset: number, $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  gap: ${({ $mode }) => $mode === 'example' ? '30px' : '0px'};
  margin: 0px;
  padding: 10px;
  padding-bottom: ${({ $isMobile, $bottomOffset }) => $isMobile ? `${$bottomOffset}px` : '10px'};
  // max-height: ${({ $isMobile, $bottomOffset }) => $isMobile ? `calc(100vh - ${$bottomOffset}px)` : '100%'};
  min-height: 0;
`;

const Problem: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const debugParam = searchParams.has("debug");
  const config = useConfig();

  const debug = debugParam;
  const { localProblems, exampleProblems, menuProblems, allProblemCount, allProblemsLoading, fetchAllProblemsForMenu } = ProblemFetch('problem');
  
  const { data: userInfo } = useGetUserInfo();
  const { problemId } = useParams<{ problemId: string }>();
  const [loaded, setLoaded] = useState(false);
  const [problemLoaded, setProblemLoaded] = useState(false);
  const { data: questionData, loading: questionLoading, error: questionError, fetchQuestionById, fetchRawJsonById } = useGetQuestionResponseById();
  const { data: computeData, loading: computeLoading, error: computeError, computeResponse } = useComputeQuestionResponse();
  const [currentProblem, setCurrentProblem] = useState(null);
  const [rawProblemJson, setRawProblemJson] = useState(null); 
  const [todayProblemCount, setTodayProblemCount] = useState(0);
  const [isComputingProblem, setIsComputingProblem] = useState(false);

  const [mode, setMode] = useState<'new' | 'example' | 'solution'>();
  const [inputMode, setInputMode] = useState<'Type' | 'Scan'>('Type'); 
  const [inputValue, setInputValue] = useState('');
  const [bottomOffset, setBottomOffset] = useState(0);

  const rootRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOffset, setMenuOffset] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleMouseLeave } = useHover();
  const [submitClicked, setSubmitClicked] = useState(false); 

  const { computedProblemIds, setComputedProblemIds, copyProblemsToAccount } = useComputedProblems();
  const { getRenderContent, getRawContent, startStream, endStream } = useStream();
  const promptLogin = !getIsLoggedIn() && (computedProblemIds?.length >= config.NUM_NOT_LOGGED_IN_PROBLEMS || submitClicked);

  useLayoutEffect(() => {
    if (!loaded) {
      setLoaded(true);
      // console.log("UI loaded");
    }
  }, [loaded]);

  useEffect(() => {
    setMenuOffset(menuOpen && !isMobile ? 300 : 0);
  }, [menuOpen, isMobile]);  

  useLayoutEffect(() => {
    if (rootRef.current) {
      const { left, top, right, bottom } = rootRef.current.getBoundingClientRect();
      // console.log('RootRef Positions - Left:', left, 'Top:', top, 'Right:', right, 'Bottom:', bottom);
    }
  }, [rootRef, mode, inputMode, bottomOffset]);
  
  // useLayoutEffect(() => {
  //   console.log('mode: ', mode);
  //   console.log('input mode: ', inputMode);
  //   console.log('bottom offset: ', bottomOffset);
  //   console.log('inner: ', window.innerHeight, window.innerWidth);
  //   console.log('viewport: ', window.visualViewport.height, window.visualViewport.width);
  // }, [mode, inputMode, bottomOffset, window]);

  useLayoutEffect(() => {
    const handleOffsetChange = () => {
      const offset = window.innerHeight - window.visualViewport.height;
      // Add a small buffer to prevent extra blank space
      const adjustedOffset = Math.max(0, offset - 40);
      setBottomOffset(adjustedOffset);
      // console.log('Bottom offset:', offset);
    };
    
    const handleViewportChange = () => {
      if (window.visualViewport) {
        handleOffsetChange();
      }
    };
  
    const handleInputFocus = () => {
      if (window.visualViewport) {
        requestAnimationFrame(() => {
          handleOffsetChange();
        });
      }
    };
  
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleViewportChange);
      window.visualViewport.addEventListener('scroll', handleViewportChange);
    }
  
    window.addEventListener('focus', handleInputFocus, true);
    window.addEventListener('blur', handleInputFocus, true);
  
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleViewportChange);
        window.visualViewport.removeEventListener('scroll', handleViewportChange);
      }
      window.removeEventListener('focus', handleInputFocus, true);
      window.removeEventListener('blur', handleInputFocus, true);
    };
  }, []);  

  useLayoutEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        // console.log('ResizeObserver entry:', entry.target.id, entry.contentRect);
      }
    });
    const rootContainer = document.querySelector('#rootContainer');
    if (rootContainer) observer.observe(rootContainer);
    return () => {
      if (rootContainer) observer.unobserve(rootContainer);
      observer.disconnect();
    };
  }, []);
  
  const handleInputChange = (value: string) => {
    // console.log('handleInputChange', value);
    setInputValue(value);
  };

  const handleSubmitClick = useCallback((text: string, imageIds?: string[]) => {
    if (getIsLoggedIn() || computedProblemIds?.length < 1) {
      // Set flag when starting computation
      setCurrentProblem(null);
      setIsComputingProblem(true);
      setProblemLoaded(false);
      setMode('solution');
      setMenuOpen(false);
      navigate(`/problem/new${debugParam ? '?debug=true' : ''}`, { replace: false });
      setInputValue(text);
      handleMouseLeave();
      doSubmitProblem(text, imageIds);
      scrollPastProblem(500);
    } else {
      setSubmitClicked(true);
    }
  }, [inputValue]);

  const latestSubmissionRef = useRef<string | null>(null);
  const doSubmitProblem = useCallback(async (problemText: string, imageIds?: string[]) => {
    // Generate a unique ID for this submission
    const submissionId = Date.now().toString();
    latestSubmissionRef.current = submissionId;

    try {
      const computedResponse = await computeResponse(problemText, imageIds);
      
      // Only proceed if this is still the latest submission
      if (computedResponse && latestSubmissionRef.current === submissionId) {
        // Set the new problem state first
        setComputedProblemIds([...computedProblemIds, computedResponse.id]);
        fetchAllProblemsForMenu();
        setTodayProblemCount(prev => prev + 1);
        
        // Only navigate if we're on the new problem page
        if (window.location.href.includes('new') || location.pathname.includes('/new')) {
          const newPath = `/problem/${computedResponse.id}${debugParam ? '?debug=true' : ''}`;
          // Use replace to avoid adding to history stack
          window.history.replaceState(null, '', newPath);
        } else {
          console.warn('Not navigating because current path is:', location.pathname);
        }
        scrollPastProblem(1000);

        // Poll for updates
        if (computedResponse.status === 'IN_PROGRESS') {
          if (config.SWITCH_POLL_TO_STREAM) {
            const streamResponse = await streamAndSetProblem(computedResponse.id, submissionId);
          } else {
            const pollResponse = await pollAndSetProblem(computedResponse.id, submissionId);
          }
        } else if (computedResponse.status === 'ERROR' || computedResponse.status === 'SUCCESS') {
          setCurrentProblem(computedResponse);
          setProblemLoaded(true);
          setIsComputingProblem(false);
          fetchAndSetRawProblemJson(problemId);
        }
      } else {
        console.warn('No computed response received');
      }
    } catch (error) {
      // Only show error if this is still the latest submission
      if (latestSubmissionRef.current === submissionId) {
        console.error("Error fetching question response:", error);
        setIsComputingProblem(false);
      }
    }
  }, [computeResponse, window.location.href, location.pathname, computedProblemIds, debugParam, fetchAllProblemsForMenu]);

  const streamAndSetProblem = useCallback(async (problemId: string, submissionId: string) => {
    if (latestSubmissionRef.current !== submissionId) {
      console.log('Not starting stream for older submission:', submissionId);
      return null;
    }

    try {
      startStream(problemId);
      const response = await fetchQuestionById(problemId);
      if (response && latestSubmissionRef.current === submissionId) {
        setCurrentProblem(response);
      }
      return response;
    } catch (error) {
      console.error('Error in streamAndSetProblem:', error);
      endStream(problemId);
      return null;
    }
  }, [fetchQuestionById, startStream, endStream]);

  // Update problem solution when stream content changes
  useEffect(() => {
    if (config.SWITCH_POLL_TO_STREAM) {
      if (currentProblem?.status === 'IN_PROGRESS') {
        const streamContent = getRenderContent(currentProblem.id);
        const done = getRawContent(currentProblem.id).includes('[DONE]');
        // console.log('done:', done);
        if (streamContent && !done) {
          setCurrentProblem(prev => {
            if (!prev) return prev;
            return {
              ...prev,
              parts: [{
                ...prev.parts[0],
                solution: streamContent
              }]
            };
          });
          scrollToBottom();
        } else if (streamContent && done) {
          console.log('STREAM DONE INDICATOR ============================');
          const id = currentProblem.id;
          const contentWithoutDone = streamContent.replace('[DONE]', '');
          setCurrentProblem(prev => {
            if (!prev) return prev;
            return {
              ...prev,
              status: 'SUCCESS',
              parts: [{
                ...prev.parts[0],
                solution: contentWithoutDone
              }]
            };
          });
          endStream(currentProblem.id);
          setIsComputingProblem(false);
          setProblemLoaded(true);

          const fetchAndSet = async (id) => {
            try {
              const response = await fetchQuestionById(id);
              if (response) {
                if (response.status === 'IN_PROGRESS') {
                  setTimeout(() => {
                    fetchAndSet(id);
                  }, 3000);
                } else {
                  if (debug) {
                    fetchAndSetRawProblemJson(id);
                  }
                  setCurrentProblem(response);
                }
              }
            } catch (error) {
              console.error('Error fetching question:', error);
            }
          };
          setTimeout(() => {
            fetchAndSet(id);
          }, 1000);
          console.log('finished setting from getbyid');
          return;
        }
      }
    }
  }, [currentProblem?.id, currentProblem?.status, getRenderContent]);

  const lastScrollTimeRef = useRef(0); // Track last scroll time using ref to persist between renders
  const scrollToBottom = useCallback(() => {
    const container = document.getElementById('scrollContainer');
    if (!container) return;

    const lineHeightSmall = 62;
    const lineHeightBig = 94;
    const lineHeight = 80;
    const bottomHeight = 74;
    const padding = 10;
    const loadingHeight = 18 + (40*2);
    const heightCheck = loadingHeight;

    // Check if we're already scrolled up (not at bottom)
    const atBottom = container.scrollHeight - container.scrollTop - container.clientHeight < heightCheck;
    // console.log('Scroll check:', {
    //   scrollHeight: container.scrollHeight,
    //   scrollTop: container.scrollTop,
    //   clientHeight: container.clientHeight,
    //   atBottom
    // });

    // Only auto-scroll if we're already at the bottom and it's been more than 1 second since last scroll
    const now = Date.now();
    if (atBottom && now - lastScrollTimeRef.current >= 1000) {
      // console.log('Scrolling to bottom');
      lastScrollTimeRef.current = now;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, []);  

  const pollAndSetProblem = useCallback(async (problemId: string, submissionId: string) => {
    const pollInterval = 1000; // 1 second
    const maxDuration = 60 * 16; // 16 minutes
    const maxAttempts = maxDuration * (1000 / pollInterval);

    let attempts = 0;
    while (attempts < maxAttempts) {
      if (latestSubmissionRef.current !== submissionId) {
        console.log('Stopping poll for older submission:', submissionId);
        return null;
      }

      try {
        const response = await fetchQuestionById(problemId);
        if (response && latestSubmissionRef.current === submissionId) {
          setCurrentProblem(response);
          setProblemLoaded(true);
          // console.log('Problem', response.status, response.parts[0].solution);
          if (response.status === 'SUCCESS' || response.status === 'ERROR') {
            setIsComputingProblem(false);
            fetchAndSetRawProblemJson(problemId);
            return response;
          }
        }

        // Wait for the polling interval before next attempt
        await new Promise(resolve => setTimeout(resolve, pollInterval));
        attempts++;
      } catch (error) {
        if (latestSubmissionRef.current === submissionId) {
          console.error('Error polling for problem status:', error);
          setProblemLoaded(true);
          setIsComputingProblem(false);
        }
        return null;
      }
    }
  }, [fetchQuestionById]);

  const scrollPastProblem = (waitTime = 1000) => {
    setTimeout(() => {
      const scrollContainer = document.getElementById('scrollContainer');
      const initialDoc = document.querySelector('.initial-doc-container');
      if (scrollContainer && initialDoc) {
        const rect = initialDoc.getBoundingClientRect();
        // Calculate position that puts the bottom of initial container at the top of viewport
        const scrollPosition = rect.top + scrollContainer.scrollTop + rect.height - 80;
        scrollContainer.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      } else {
        console.warn('Cannot auto-scroll. ScrollContainer or InitialDoc not found.');
      }
    }, waitTime);
  };

  const handleScanResult = (scanText: string, imageIds?: string[]) => {
    // console.log('handleScanResult', scanText, imageIds);
    setInputMode('Type');
    handleSubmitClick(scanText, imageIds);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const submitKey = isMac ? event.metaKey : event.ctrlKey;
      if (event.key === 'Enter' && submitKey && mode === 'new') {
        handleSubmitClick(inputValue);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmitClick, inputValue, mode]);

  const handleNewProblemClick = useCallback(() => {
    setInputValue('');
    setInputMode('Type');
    setMode('new');
    navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
    setMenuOpen(false);
  }, [navigate]);

  const handleExampleProblemsClick = useCallback(() => {
    setMode('example');
    navigate(`/problem/example${debugParam ? '?debug=true' : ''}`, { replace: false });
    setMenuOpen(false);
  }, [navigate]);

  const handleClearHover = useCallback((event: React.MouseEvent) => {
    // Prevent clearing hover color if clicking on visualizations or expressions
    if (event.target instanceof HTMLElement && !event.target.closest('.no-clear-hover')) {
      handleMouseLeave(); // Clear hovered color
    }
  }, [handleMouseLeave]);

  // Memoize menu problem IDs
  const menuProblemIds = useMemo(() => 
    menuProblems.map(problem => problem.id),
    [menuProblems]
  );

  const fetchAndSetProblemById = useCallback(async (id: string | null) => {
    try {
      if (id === 'example') {
        setMode('example');
        setMenuOpen(false);
      } else {
        let selectedProblem = null;
        setCurrentProblem(null);
        setProblemLoaded(false);
        setInputValue('');
        setInputMode('Type');

        if (id === null || id === undefined) {
          resetProblemState();
        } else {
          setMode('solution');
        }
        
        if (config.LOCAL_PROBLEMS) {
          if (selectedProblem === null || selectedProblem === undefined) {
            if (config.EXAMPLES_IN_MENU) selectedProblem = exampleProblems.find(p => p.id === id);
            const response = localProblems.find(p => p.id === id);
            if (response) {
              selectedProblem = response;
              fetchAndSetRawProblemJson(id);
            }
          }
        } else {
          const response = await fetchQuestionById(id);
          if (response) {
            selectedProblem = response;
            fetchAndSetRawProblemJson(id);
          }
        }
        // console.log("selected problem", selectedProblem);
        
        if (selectedProblem) {
          // console.log('setting problem by id', selectedProblem);
          setMode('solution');
          setCurrentProblem(selectedProblem);
          setInputValue(selectedProblem.parts[0]?.text || '');
          setProblemLoaded(true);
          handleMouseLeave();
          // if problem is in progress, re-initiate stream
          if (selectedProblem.status === 'IN_PROGRESS') {
            const submissionId = Date.now().toString();
            latestSubmissionRef.current = submissionId;
            streamAndSetProblem(id, submissionId);
          }
        } else if (selectedProblem && currentProblem === selectedProblem) {
          console.log('problem already loaded');
        } else {
          console.warn('Cannot find problem ID', id);
          resetProblemState();
          navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
        }
      }
    } catch (error) {
      console.error("Error fetching question by ID", error);
      resetProblemState();
    }
  }, [localProblems, fetchQuestionById, handleMouseLeave, navigate, menuProblemIds, copyProblemsToAccount]);
  
  const fetchAndSetRawProblemJson = async (id: string | null) => {
    if (debug === true) {
      if (config.LOCAL_PROBLEMS) {
        return;
      } else {
        try {
          const rawJson = await fetchRawJsonById(id);
          if (rawJson) {
            if (rawJson.visualizations) {
              rawJson.visualizations = JSON.parse(rawJson.visualizations);
            }
            if (rawJson.expressions) {
              rawJson.expressions = JSON.parse(rawJson.expressions);
            }
            if (rawJson.solution) {
              rawJson.solution = JSON.parse(rawJson.solution);
            }
            if (rawJson.debugFeedback) {
              rawJson.debugFeedback = JSON.parse(rawJson.debugFeedback);
            }
            if (rawJson.meta) {
              rawJson.meta = JSON.parse(rawJson.meta);
            }
            setRawProblemJson(rawJson);
          }
        } catch {
          console.error("Error fetching raw JSON by ID");
        }
      }
    }
  };

  // if the problem isn't already in the menu, copy it
  useEffect(() => {
    // console.log('allProblemsLoading', allProblemsLoading);
    if (currentProblem === null || menuProblemIds.length === 0 || allProblemsLoading) return;
    const shouldCopy = !menuProblemIds.includes(currentProblem.id);
    // console.log('menuProblemIds', menuProblemIds);
    // console.log('selectedProblem.id', currentProblem.id);
    // console.log('shouldCopy', shouldCopy);
    if (shouldCopy) {
      copyProblemsToAccount([currentProblem.id]);
    }
  }, [currentProblem, menuProblemIds, copyProblemsToAccount, allProblemsLoading]);
  
  const resetProblemState = () => {
    // console.log('resetting problem state');
    setCurrentProblem(null);
    setMode('new');
    setInputMode('Type');
    setInputValue('');
    setMenuOpen(false);
    handleMouseLeave();
  };

  useEffect(() => {
    if (problemId === 'new') {
      // Only redirect if we didn't get here through computation
      if (!isComputingProblem) {
        navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
        setMode('new');
      }
    } else if (problemId) {
      fetchAndSetProblemById(problemId);
    } else {
      resetProblemState();
    }
    // Reset the computation flag when leaving /new
    if (problemId !== 'new') {
      setIsComputingProblem(false);
    }
  }, [problemId]);

  const handleMenuOptionClick = useCallback((optionId) => {
    // console.log("menu option click", optionId);
    if (optionId !== problemId) {
      setMenuOpen(false);
      navigate(`/problem/${optionId}${debugParam ? '?debug=true' : ''}`, { replace: false });
      fetchAndSetProblemById(optionId);
    } else {
      setMenuOpen(false);
    }
  }, [navigate, problemId]);       

  const handleMenuClick = () => {
    // if (menuOpen === false) fetchAllProblemsForMenu();
    setMenuOpen(!menuOpen);
  };

  const handleTabSelect = (selectedTabName: string) => {
    // console.log('input mode: ', selectedTabName);
    setInputMode(selectedTabName as 'Type' | 'Scan');
    setMenuOpen(false);
  };

  const memoizedInitialData = useMemo(() => {
    if (mode === 'example') {
      return exampleProblems;
    } else if (currentProblem) {
      return [currentProblem];
    }
    return [];
  }, [mode, currentProblem, exampleProblems]);

  const debugOverlay = useMemo(() => {
    if (currentProblem && debug) {
      return (
        <DebugOverlay 
          enableDebug={debug} 
          problem={currentProblem} 
          problemsData={menuProblems} 
          rawProblem={rawProblemJson} 
          fetchAndSetProblemById={fetchAndSetProblemById} 
        />
      );
    } else {
      return null;
    }
  }, [currentProblem, debug, menuProblems, rawProblemJson, fetchAndSetProblemById]);

  return (
    <Root id="rootContainer" ref={rootRef} data-unmask="true"
      $mode={mode} onClick={handleClearHover} $isMenuOpen={menuOpen} $isMobile={isMobile} $inputMode={inputMode} $bottomOffset={bottomOffset}>
      <Helmet>
        <title>SeeSolve - AI Math Solver & Calculator</title>
        <meta name="description" content="The #1 AI for learning math. Solve any problem. Get unstuck. Learn faster. With interactive visualizations and step-by-step calculators." />
      </Helmet>
       <StateProvider key={problemId} initialData={memoizedInitialData} contentType="problem">
        { !loaded && <LoadingSpinner /> }
        { loaded &&
          <>
            <TopBar 
              mode={mode} 
              problemId={problemId}
              handleNewProblemClick={handleNewProblemClick}
              handleMenuClick={handleMenuClick}
              handleTabSelect={handleTabSelect} 
              loggedIn={getIsLoggedIn()}
              userInfo={userInfo?.getUserInfo}
              isMobile={isMobile}
              menuOpen={menuOpen}
              inputMode={inputMode}
              promptLogin={promptLogin}
              NEW_ACCOUNT_UI={config.NEW_ACCOUNT_UI}
            />
            <ScrollContainer id="scrollContainer" key={problemId} data-scroll-container $mode={mode} $bottomOffset={bottomOffset} $isMobile={isMobile}>
              {mode === 'example' ? (
                exampleProblems
                  .map((exampleProblem, index) => (
                  <ProblemContent
                    key={index}
                    inputValue={exampleProblem.parts[0]?.text || ''}
                    mode="example"
                    currentProblem={exampleProblem}
                    loading={!loaded}
                    error={false}
                    handleInputChange={() => {}}
                    handleSubmitClick={() => {}}
                    inputMode={inputMode}  
                    submitClicked={submitClicked}
                    userInfo={userInfo}
                    isLoggedIn={getIsLoggedIn()}
                    promptLogin={promptLogin}
                    bottomOffset={bottomOffset}
                  />
                ))
              ) : (
                <>
                  <ProblemContent 
                    inputValue={inputValue} 
                    mode={mode} 
                    currentProblem={currentProblem}
                    handleInputChange={handleInputChange}
                    loading={!problemLoaded}
                    error={false}
                    handleSubmitClick={() => handleSubmitClick(inputValue)}
                    handleExampleProblemsClick={handleExampleProblemsClick}
                    inputMode={inputMode} 
                    todayProblemCount={todayProblemCount}
                    submitClicked={submitClicked}
                    userInfo={userInfo}
                    isLoggedIn={getIsLoggedIn()}
                    promptLogin={promptLogin}
                    bottomOffset={bottomOffset}
                  />
                  {debugOverlay}
                </>
              )}
            </ScrollContainer>
            {mode === 'new' && inputMode === 'Scan' && (
              <Scan menuOffset={menuOffset} onScanComplete={handleScanResult} closeMenu={() => setMenuOpen(false)} />
            )}
          </>
        }
        <Menu
          onSelect={handleMenuOptionClick}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          isMobile={isMobile}
          selectedId={problemId}
          problemsData={menuProblems}
          userInfo={userInfo}
          NEW_ACCOUNT_UI={config.NEW_ACCOUNT_UI}
        />
      </StateProvider>
    </Root>
  );
};

export default Problem;