// Visualization.tsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import BarGraph, { BarGraphProps } from '../components/visualizations/BarGraph';
import LineGraph, { LineGraphProps } from '../components/visualizations/LineGraph';
import CoordinatePlane, { CoordinatePlaneProps } from '../components/visualizations/CoordinatePlane';
import Shape, { ShapeProps, ShapeData } from '../components/visualizations/Shape';
import TableOLD, { TablePropsOLD } from './visualizations/TableOLD';
import NumberLine, { NumberLineProps } from '../components/visualizations/NumberLine';
import NumberCircle, { NumberCircleProps } from '../components/visualizations/NumberCircle';
import NumberBar, { NumberBarProps } from '../components/visualizations/NumberBar';
import Grid, { GridProps } from '../components/visualizations/Grid';
import PictureGraph, { PictureGraphProps } from '../components/visualizations/PictureGraph';
import AreaModel, { AreaModelProps } from './visualizations/AreaModel';
import Table, { TableProps } from './visualizations/Table';
import { useStateContext } from '../pages/shared/State';

interface VizContainerProps {
  $maxWidth?: string;
  $maxHeight?: string;
}

const visualizationSizes: { [key: string]: { maxWidth: string; maxHeight: string } } = {
  barGraph: { maxWidth: 'max-content', maxHeight: '400px' },
  lineGraph: { maxWidth: '700px', maxHeight: '700px' },
  coordinatePlane: { maxWidth: '400px', maxHeight: '500px' },
  numberLine: { maxWidth: '700px', maxHeight: 'max-content' },
  numberBar: { maxWidth: '700px', maxHeight: '180px' },
  numberCircle: { maxWidth: '300px', maxHeight: '400px' },
  grid: { maxWidth: '700px', maxHeight: 'max-content' },
  shape: { maxWidth: '700px', maxHeight: 'max-content' },
  tableOLD: { maxWidth: 'max-content', maxHeight: 'max-content' },
  table: { maxWidth: 'max-content', maxHeight: 'max-content' },
  pictureGraph: { maxWidth: '400px', maxHeight: '400px' },
  areaModel: { maxWidth: '400px', maxHeight: '400px' }
};

const scaleVizSizes = (scale: number) => {
  const scaledSizes = Object.fromEntries(
    Object.entries(visualizationSizes).map(([key, { maxWidth, maxHeight }]) => {
      const scaleValue = (value: string) => {
        return value === 'max-content'
          ? value
          : `${parseInt(value) * scale}px`;
      };
      return [key, scale !== null && scale !== undefined ? 
        { maxWidth: scaleValue(maxWidth), maxHeight: scaleValue(maxHeight) } 
        : { maxWidth, maxHeight }];
    })
  );
  return scaledSizes;
};

const VizContainer = styled.div<VizContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  max-width: ${(props) => props.$maxWidth || '100%'};
  max-height: ${(props) => props.$maxHeight || '100%'};
  width: 100%;
  height: auto;
  background: none;
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > * {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  svg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
  }
`;

interface VisualizationData {
  type: string;
  [key: string]: any;
}

interface VisualizationProps {
  visualization: VisualizationData;
  index: number;
  partIndex: number;
  problemId: string;
}

const Visualization: React.FC<VisualizationProps> = ({ visualization, index, partIndex, problemId }) => {
  const { updateVisualizations } = useStateContext();
  const [actualScale, setActualScale] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const [actualVizWidth, setActualVizWidth] = useState<number>(containerRef?.current?.getBoundingClientRect()?.width);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        let vizMaxWidth = visualizationSizes[visualization.type]?.maxWidth;
        if (vizMaxWidth === 'max-content' && actualVizWidth) {
          vizMaxWidth = actualVizWidth+'px';
        }
        
        const targetWidth = parseInt(vizMaxWidth);
        if (!isNaN(targetWidth) && rect.width > 0) {
          const scale = Math.min(rect.width / targetWidth, 1);
          setActualScale(scale);
        }
      }
    };

    // Initial update with a small delay to ensure proper dimensions
    const initialTimeout = setTimeout(updateScale, 100);
    const resizeObserver = new ResizeObserver(updateScale);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      clearTimeout(initialTimeout);
      resizeObserver.disconnect();
    };
  }, [problemId, index, partIndex, actualVizWidth]);

  const handleStateChange = (newState: any, skipLogic?: string | string[]) => {
    // console.log('visualization handle state change', problemId, visualization);
    const getFullPath = (skipLogic: string) => {
      return `parts[${partIndex}].visualizations[${index}].${skipLogic}`;
    };
    let skipLogicFullPath = undefined;
    if (skipLogic) {
      if (Array.isArray(skipLogic)) {
        skipLogicFullPath = skipLogic.map((logic) => getFullPath(logic));
      } else {
        skipLogicFullPath = getFullPath(skipLogic);
      }
      // console.log('skipLogic:', skipLogic, 'skipLogicFullPath:', skipLogicFullPath);
    }
    updateVisualizations(problemId, partIndex, index, {
      ...visualization,
      ...newState,
    }, skipLogicFullPath);    
  };

  if (!visualization || Object.keys(visualization).length === 0) {
    return null;
  }

  // console.log('scale', visualization.scale);
  const { maxWidth, maxHeight } = scaleVizSizes(visualization.vizScale)[visualization.type] || { maxWidth: '100%', maxHeight: '100%' };

  return (
    <VizContainer key={index} ref={containerRef} $maxWidth={maxWidth} $maxHeight={maxHeight}>
      {(() => {
        switch (visualization.type) {
          case 'barGraph':
            return (
              <BarGraph
                data={visualization.data}
                xAxis={visualization.xAxis}
                yAxis={visualization.yAxis}
                dragTick={visualization.dragTick}
                actualScale={actualScale}
                onActualWidth={(width) => {setActualVizWidth(width)}}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );
          case 'lineGraph':
            return (
              <LineGraph
                datasets={visualization.datasets}
                xAxis={visualization.xAxis}
                yAxis={visualization.yAxis}
                dragTick={visualization.dragTick}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );
          case 'coordinatePlane':
            return (
              <CoordinatePlane
                datasets={visualization.datasets}
                xAxis={visualization.xAxis}
                yAxis={visualization.yAxis}
                dragTick={visualization.dragTick}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );
          case 'shape':
            return (
              <Shape
                shape={visualization.shape}
                dimensions={visualization.dimensions}
              />
            );
          case 'tableOLD':
            return (
              <div id={`tableOLD-${index}`}>
                <TableOLD
                  data={visualization.data}
                  columnNames={visualization.columnNames}
                  rowNames={visualization.rowNames}
                  dataColors={visualization.dataColors}
                  columnColors={visualization.columnColors}
                  rowColors={visualization.rowColors}
                  onStateChange={(state) => {handleStateChange(state)}}
                />
              </div>
            );    
          case 'table':
            return (
              <Table
                title={visualization.title}
                columns={visualization.columns}
                rows={visualization.rows}
                onStateChange={(state) => {handleStateChange(state)}}
              />
            );
          case 'numberCircle':
            return (
              <NumberCircle
                values={visualization.values!}
                totalValue={visualization.totalValue!}
                labels={visualization.labels}
                tick={visualization.tick}
                colors={visualization.colors}
                draggable={visualization.draggable}
                dragTick={visualization.dragTick}
                dragOverrideOtherValues={visualization.dragOverrideOtherValues}
                tutorial={visualization.tutorial}
                steppable={visualization.steppable}
                stepMin={visualization.stepMin!}
                stepMax={visualization.stepMax!}
                stepUp={visualization.stepUp!}
                stepDown={visualization.stepDown!}
                tickInitial={visualization.tickInitial!}
                totalValueInitial={visualization.totalValueInitial!}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );
          case 'numberBar':
            return (
              <NumberBar
                values={visualization.values!}
                totalValue={visualization.totalValue!}
                labels={visualization.labels}
                tick={visualization.tick}
                tickPerValue={visualization.tickPerValue}
                colors={visualization.colors}
                direction={visualization.direction}
                draggable={visualization.draggable}
                dragTick={visualization.dragTick}
                dragMin={visualization.dragMin}
                dragMax={visualization.dragMax}
                dragOverrideOtherValues={visualization.dragOverrideOtherValues}
                tutorial={visualization.tutorial}
                steppable={visualization.steppable}
                stepMin={visualization.stepMin!}
                stepMax={visualization.stepMax!}
                stepUp={visualization.stepUp!}
                stepDown={visualization.stepDown!}
                tickInitial={visualization.tickInitial!}
                totalValueInitial={visualization.totalValueInitial!}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );
          case 'numberLine':
            return (
              <NumberLine
                min={visualization.min!}
                max={visualization.max!}
                tick={visualization.tick!}
                tickOffset={visualization.tickOffset!}
                data={visualization.data}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );            
          case 'grid':
            return (
              <Grid
                rows={visualization.rows!}
                cols={visualization.cols!}
                counters={visualization.counters}
                colors={visualization.colors}
                images={visualization.images}
                labelCounters={visualization.labelCounters}
                labelRowsCols={visualization.labelRowsCols}
                actualScale={actualScale}
                // onStateChange={(state) => {handleStateChange(state)}}
              />
            );                              
          case 'pictureGraph':
            return (
              <PictureGraph
                data={visualization.data}
                xAxis={visualization.xAxis}
                yAxis={visualization.yAxis}
                scale={visualization.scale}
                dragTick={visualization.dragTick}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );            
          case 'areaModel':
            return (
              <AreaModel
                rowValue={visualization.rowValue!}
                rowTotal={visualization.rowTotal!}
                columnValue={visualization.columnValue!}
                columnTotal={visualization.columnTotal!}
                rowColor={visualization.rowColor}
                columnColor={visualization.columnColor}
                totalColor={visualization.totalColor}
                rowLabel={visualization.rowLabel}
                columnLabel={visualization.columnLabel}
                totalLabel={visualization.totalLabel}
                draggable={visualization.draggable}
                steppable={visualization.steppable}
                tutorial={visualization.tutorial}
                actualScale={actualScale}
                onStateChange={(state, skipLogic) => {handleStateChange(state, skipLogic)}}
              />
            );            
          default:
            return null;
        }
      })()}
    </VizContainer>
  );
};

export default Visualization;
