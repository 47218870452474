import { problem } from "../Generate";

export const generateDivision = (maximum: number): problem[] => {
  const problems: problem[] = [];

  const addProblem = (template: any) => {
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      ...template
    });
  };

  const generateUpTo100 = (dividend: number, divisor: number, quotient: number, remainder: number) => {
    if (divisor === 0 || dividend > 100) return;

    const correctAnswer = quotient + (remainder > 0 ? 'R' + remainder : '');

    const template = {
      name: dividend <= 10
        ? remainder > 0
          ? "Division <= 10 Remainder: Number Line"
          : "Division <= 10: Number Line"
        : remainder > 0
          ? "Division <= 100 Remainder: Number Line"
          : "Division <= 100: Number Line",
      numColors: 3,
      variables: {
        divisor: "${dividend - interval_dragger}",
        num_hops: "${dividend / divisor}",
        quotient: "${Math.floor(num_hops)}",
        remainder: "${dividend % divisor}",
        tick: "${max / divisor <= 14 ? divisor : Math.ceil(max / 14 / divisor) * divisor}"
      },
      text: `${dividend} ÷ ${divisor} = ?`,
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: dividend + divisor,
          maxVar: "max",
          tick: divisor,
          tickVar: "tick",
          tickOffset: "${remainder}",
          data: [
            {
              type: "hop",
              start: dividend,
              startVar: "dividend",
              end: 0,
              hopInterval: "${-divisor}",
              startPoint: "dot",
              hopPoints: "arrow",
              hopLabels: "-${divisor}",
              partialHopLabel: "-${remainder}",
              lineLabel: "${remainder > 0 ? `=${quotient}R${remainder}` : `=${quotient}`}",
              startLabel: "${dividend}",
              startColor: 3,
              lineColor: 1,
              hopColor: 2,
              endColor: -1,
              startDraggable: true,
              startDragMin: "${interval_dragger + 1}",
              startDragMax: "${max}",
              dragTick: 1
            },
            {
              type: "line",
              start: dividend - divisor,
              startVar: "interval_dragger",
              startPoint: "dot",
              lineLabel: "÷${divisor}",
              startColor: 2,
              lineColor: 2,
              startDraggable: "${dividend === 1 ? false : true}",
              startDragMax: "${dividend - 1}",
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: dividend + divisor,
          tick: (dividend + divisor) / divisor <= 14 ? divisor : Math.ceil((dividend + divisor) / 14 / divisor) * divisor,
          tickOffset: remainder,
          data: [
            {
              type: "hop",
              start: dividend,
              end: 0,
              hopInterval: -divisor,
              startPoint: "dot",
              hopPoints: "arrow",
              hopLabels: `-${divisor}`,
              partialHopLabel: `-${remainder}`,
              lineLabel: remainder > 0 ? `=${quotient}R${remainder}` : `=${quotient}`,
              startLabel: `${dividend}`,
              startColor: 3,
              lineColor: 1,
              hopColor: 2,
              endColor: -1
            },
            {
              type: "line",
              start: dividend - divisor,
              startPoint: "dot",
              lineLabel: `÷${divisor}`,
              startColor: 2,
              lineColor: 2
            }
          ]
        }
      ],
      answer: {
        multipleChoice: Array.from(
          new Set([
            { value: quotient, remainder: remainder }, // correct answer
            { value: dividend, remainder: 0 },
            { value: divisor, remainder: 0 },
            { value: quotient - 1, remainder: 0 },
            { value: quotient + 1, remainder: 0 },
            { value: quotient, remainder: Math.floor(Math.random() * (divisor - 1)) + 1 },
            { value: quotient, remainder: Math.floor(Math.random() * (divisor - 1)) + 1 },
            { value: divisor, remainder: Math.floor(Math.random() * (divisor - 1)) + 1 },
            { value: dividend, remainder: Math.floor(Math.random() * (divisor - 1)) + 1 },
            remainder ? { value: 0, remainder: remainder } : undefined,
            dividend <= 10 ? { value: 0, remainder: 0 } : undefined,
            { value: Math.floor(dividend / (divisor - 1)), remainder: 0 },
            { value: Math.floor(dividend / (divisor + 1)), remainder: 0 },
            ...Array.from({ length: 10 }, () => ({ value: Math.floor(Math.random() * Math.max(quotient, 10)) + 1, remainder: 0 }))
          ].filter(Boolean))
        )
          .filter(({ value, remainder }) => !(value === 0 && remainder > 0))
          .map(({ value, remainder }) => ({ 
            original: { value, remainder },
            string: value === Infinity ? '∞' : remainder ? `${value}R${remainder}` : `${value}`
          }))
          .filter((item, index, self) => 
            self.findIndex(other => other.string === item.string) === index
          )
          .map(({ original }) => original)
          .slice(0, 6)
          .sort((a, b) => {
            if (a.value !== b.value) return a.value - b.value;
            if (a.remainder === 0 && b.remainder > 0) return -1;
            if (b.remainder === 0 && a.remainder > 0) return 1;
            return a.remainder - b.remainder;
          })
          .map(({ value, remainder }) => value === Infinity ? '∞' : remainder ? `${value}R${remainder}` : `${value}`),
        multiselect: false,
        correctAnswer: [correctAnswer.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const PERCENT_TO_GEN = {
    UP_TO_10: 1.0,
    UP_TO_10_REMAINDER: 0.2, // 20%
    UP_TO_100: 0.01, // 1% chance to generate
    UP_TO_100_REMAINDER: 0.002, // 0.2%
  };

  // Generate division problems with dividends up to 10
  for (let dividend = 1; dividend <= 10; dividend++) {
    for (let divisor = 1; divisor <= dividend; divisor++) {
      const quotient = Math.floor(dividend / divisor);
      const remainder = dividend % divisor;
      if (remainder === 0 && Math.random() < PERCENT_TO_GEN.UP_TO_10) { // no remainder
        generateUpTo100(dividend, divisor, quotient, remainder);
        if (problems.length >= maximum) return problems;
      } else if (Math.random() < PERCENT_TO_GEN.UP_TO_10_REMAINDER) { // with remainder
        generateUpTo100(dividend, divisor, quotient, remainder);
        if (problems.length >= maximum) return problems;
      }
    }
  }

  // Generate division problems with dividends from 11 to 100
  for (let dividend = 11; dividend <= 100; dividend++) {
    for (let divisor = 1; divisor <= dividend; divisor++) {
      const quotient = Math.floor(dividend / divisor);
      const remainder = dividend % divisor;
      // if (quotient > 20 && divisor === 1) continue; // Skip large quotients divided by 1
      if (remainder === 0 && Math.random() < PERCENT_TO_GEN.UP_TO_100) { // no remainder
        generateUpTo100(dividend, divisor, quotient, remainder);
        if (problems.length >= maximum) return problems;
      } else if (Math.random() < PERCENT_TO_GEN.UP_TO_100_REMAINDER) { // with remainder
        generateUpTo100(dividend, divisor, quotient, remainder);
        if (problems.length >= maximum) return problems;
      }
    }
  }

  return problems;
};
