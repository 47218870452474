// CalculatorContent.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";
import Latex from "../../components/basic/Latex";
import Visualization from "../../components/Visualization";
import Expression from "../../components/Expression";
import Loading from "../../components/basic/LoadingDots";
import { useStateContext } from "../shared/State";
import ButtonLink from "../../components/basic/ButtonLink";
import { useNavigate } from "react-router-dom";
import CalculatorLink from "./CalculatorLink";
import { useConfig } from "../../utils/config";
import Publish from "../internal/Publish";
import { Helmet } from 'react-helmet-async';
import CalculatorFooter from "./CalculatorFooter";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100svh;
  background-color: ${Colors.background};
  z-index: 10;
  pointer-events: auto;
  flex: initial;
`;

const ScrollContainer = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: ${props => props.$isMobile ? '20px 10px' : '20px 20px 20px 10px'};
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ButtonContainer = styled.div<{ $horizontalPadding: number }>`
  position: relative;
  left: -8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${(props) => props.$horizontalPadding}px;
`;

const Title = styled.div<{ $horizontalPadding: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${Colors.grey8};
  font-family: ${Fonts.lexendBold.fontFamily};
  font-weight: ${Fonts.lexendBold.fontWeight};
  font-size: 48px;
  line-height: 115%;
  text-align: left;
  padding: 10px ${(props) => props.$horizontalPadding}px;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const MainColumn = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${props => props.$isMobile ? '100%' : '64%'};
  border-radius: 8px;
  gap: 0px;
`;

const SideColumn = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${props => props.$isMobile ? '100%' : '36%'};
  gap: 20px;
`;

const DocContainer = styled.div<{ $type?: string, $padding?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  line-height: 1.4em;
  border: ${props => props.$type === 'answers' ? '1px solid ' + Colors.black : 'none'};
  // background-color: ${props => props.$type === 'answers' ? Colors.white : Colors.transparent};
  color: ${props => props.$type === 'answers' ? Colors.black : Colors.grey8};
  border-radius: 8px;
  padding: ${props => props.$padding ? `${props.$padding}px 0` : '0'};
`;

const VizContainer = styled.div<{ $isMobile: boolean; $horizontalPadding: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ $isMobile, $horizontalPadding }) => $isMobile ? `0 ${$horizontalPadding/4}px` : `0 ${$horizontalPadding}px`};
  box-sizing: border-box;
`;

const VizGrid = styled.div<{ $isMobile: boolean; $horizontalPadding: number; $bgColor?: string }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: ${props => props.$bgColor ? props.$bgColor : Colors.transparent};
  border-radius: 8px;
  padding: ${({ $isMobile, $horizontalPadding }) => $isMobile ? `${$horizontalPadding}px ${$horizontalPadding/4}px` : `${$horizontalPadding}px ${$horizontalPadding/2}px`};
  box-sizing: border-box;
`;

const ExpressionContainer = styled.div<{ $horizontalPadding: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 24px;
  line-height: 1.4em;
  width: 100%;
  height: auto;
  padding: 10px ${({ $horizontalPadding }) => $horizontalPadding}px;
  gap: 12px;
`;

const RelatedContainer = styled.div<{ $horizontalPadding: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 20px ${(props) => props.$horizontalPadding}px;
`;

const RelatedHeader = styled.div<{ $color: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  color: ${(props) => props.$color};
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 24px;
  line-height: 1.4em;
  text-align: left;
  text-transform: uppercase;
`;

const RelatedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  width: 100%;
  margin: 10px 0;
  gap: 0px 20px;
`;

interface ContentProps {
  currentCalculator: any;
  relatedCalculators?: any[];
  loading: boolean;
  isMobile: boolean;
  horizontalPadding: number;
}

const CalculatorContent: React.FC<ContentProps> = ({ currentCalculator, relatedCalculators = [], loading, isMobile, horizontalPadding }) => {
  const config = useConfig();
  const navigate = useNavigate();
  const { problemsData } = useStateContext();
  currentCalculator = currentCalculator ? problemsData.find(problem => problem.id === currentCalculator.id) : null;
  const currentDescription = currentCalculator?.description;

  const related_font_size = 20;
  const related_color = config.CALCULATOR_SHOW_LINK_CARD ? Colors.blueDark : 'midnightblue';

  const onAllCalculatorsClick = () => {
    navigate("/calculator");
  };

  // console.log('currentCalculator', currentCalculator);

  const renderRoot = () => {
    return (
      <Root key={currentCalculator?.id} >
        <Helmet>
          <title>{`${currentCalculator.name} - SeeSolve`}</title>
          <meta name="description" content={currentDescription} />
        </Helmet>
        <Publish url={currentCalculator?.id} />
        <ScrollContainer $isMobile={isMobile}>
          <ButtonContainer $horizontalPadding={horizontalPadding}>
            <ButtonLink text={"All Calculators"} icon={'left'} color={'blue'} size="medium" onClick={onAllCalculatorsClick}/>
          </ButtonContainer>
          <Title $horizontalPadding={horizontalPadding}>
            {currentCalculator?.name}
          </Title>
          {isMobile ? (
              <>
                <MainColumn $isMobile={isMobile}>
                  {currentCalculator && currentCalculator.parts && currentCalculator.parts.map((part, partIndex) => (
                    <React.Fragment key={partIndex}>
                      {renderMainContent(part, partIndex)}
                    </React.Fragment>
                  ))}
                  {(loading === true || (currentCalculator?.status && currentCalculator?.status === 'IN_PROGRESS')) && (
                    <Loading/>
                  )}
                </MainColumn>
                <SideColumn $isMobile={isMobile}>
                  {currentCalculator && currentCalculator.parts && currentCalculator.parts.map((part, partIndex) => (
                    <React.Fragment key={partIndex}>
                      {renderSideContent(part, partIndex)}
                    </React.Fragment>
                  ))}
                </SideColumn>
                {currentCalculator && renderRelated()}
              </>
          ) : (
            <ContentContainer>
              <MainColumn $isMobile={isMobile}>
                {currentCalculator && currentCalculator.parts && currentCalculator.parts.map((part, partIndex) => (
                  <React.Fragment key={partIndex}>
                    {renderMainContent(part, partIndex)}
                  </React.Fragment>
                ))}
                {currentCalculator && renderRelated()}
                {(loading === true || (currentCalculator?.status && currentCalculator?.status === 'IN_PROGRESS')) && (
                  <Loading/>
                )}
              </MainColumn>
              <SideColumn $isMobile={isMobile}>
                {currentCalculator && currentCalculator.parts && currentCalculator.parts.map((part, partIndex) => (
                  <React.Fragment key={partIndex}>
                    {renderSideContent(part, partIndex)}
                  </React.Fragment>
                ))}
              </SideColumn>
            </ContentContainer>
          )}
          <CalculatorFooter padding={`10px ${horizontalPadding}px 0px ${horizontalPadding}px`} />
        </ScrollContainer>
      </Root>
    )
  };

  const renderMainContent = (part, partIndex) => {
    return (
      <>
        {part.text && (
          <DocContainer data-part-index={partIndex} id="text" className="text-container" $padding={0}>
            {Array.isArray(part.text) ? (
              <Latex content={part.text.map((text, index) => `${text}\n\n`).join('')} fontSize={24} padding={`0px ${horizontalPadding}px`} />
            ) : (
              <Latex content={part.text} fontSize={24} padding={`0px ${horizontalPadding}px`} />
            )}
          </DocContainer>
        )}
        {part.inputs && (
          <DocContainer data-part-index={partIndex} id="inputs" className="inputs-container">
            <Latex content={part.inputs.map((inputs, index) => `${inputs}\n\n`).join('')} fontSize={24} padding={`0px ${horizontalPadding}px`} />
          </DocContainer>
        )}
        {part.visualizations && part.visualizations?.length > 0 && (
          <VizContainer data-part-index={partIndex} $isMobile={isMobile} $horizontalPadding={horizontalPadding}>
            <VizGrid className="no-clear-hover" data-part-index={partIndex} $isMobile={isMobile} $horizontalPadding={horizontalPadding} $bgColor={Colors.white}>
              {part.visualizations?.map((viz, index) => {
                return (
                  <Visualization
                    key={index}
                    visualization={viz}
                    index={index}
                    partIndex={partIndex}
                    problemId={currentCalculator.id}
                  />
                );
              })}
            </VizGrid>
          </VizContainer>
        )}
        {part.main_content && (
          <DocContainer data-part-index={partIndex} id="main_content" className="main-content-container">
            <Latex content={part.main_content.map((main_content, index) => `${main_content}\n\n`).join('')} fontSize={18} padding={`0px ${horizontalPadding}px`} />
          </DocContainer>
        )}
      </>
    )
  };

  const renderRelated = () => {
    return relatedCalculators && relatedCalculators.length > 0 && (
      <>
        <RelatedContainer $horizontalPadding={horizontalPadding}>
          {relatedCalculators.some(relatedCalc => 
            config.SHOW_WIP_CALCULATORS || 
            (!relatedCalc.name.endsWith('(?)') && relatedCalc.id)
          ) && (
            <RelatedHeader $color={related_color}>Related Tools</RelatedHeader>
          )}
          <RelatedGrid>
            {relatedCalculators.map((relatedCalc, index) => (
              <CalculatorLink
                key={index}
                name={relatedCalc.name}
                to={relatedCalc.id ? `/calculator/${relatedCalc.id}` : ''}
                icon={relatedCalc.icon || 'default-icon'}
                fontSize={related_font_size}
              />
            ))}
          </RelatedGrid>
        </RelatedContainer>
      </>
    )
  };

  const renderSideContent = (part, partIndex) => {
    return (
      <>
        {part.solution && (
          <DocContainer data-part-index={partIndex} id="solution" className="solution-container">
            <Latex content={part.solution.map((solution, index) => `${solution}\n\n`).join('')} fontSize={18} padding={`0px ${horizontalPadding}px`} />
          </DocContainer>
        )}
        {part.side_visualizations && part.side_visualizations?.length > 0 && (
          <VizContainer data-part-index={partIndex} $isMobile={isMobile} $horizontalPadding={horizontalPadding}>
            <VizGrid className="no-clear-hover" data-part-index={partIndex} $isMobile={isMobile} $horizontalPadding={horizontalPadding}>
              {part.side_visualizations?.map((viz, index) => {
                return (
                  <Visualization
                    key={index}
                    visualization={viz}
                    index={index}
                    partIndex={partIndex}
                    problemId={currentCalculator.id}
                  />
                );
              })}
            </VizGrid>
          </VizContainer>
        )}
        {part.answers && (
          <DocContainer data-part-index={partIndex} id="answers" className="answers-container" $type="answers">
            <Latex content={part.answers.map((answers, index) => `${answers}\n\n`).join('')} fontSize={18} padding={`0px ${horizontalPadding}px`} />
          </DocContainer>
        )}
        {part.expressions && part.expressions.length > 0 && (
          <ExpressionContainer className="no-clear-hover" data-part-index={partIndex} $horizontalPadding={horizontalPadding}>
            {part.expressions.map((expr, index) => (
              <Expression
                key={index}
                problemId={currentCalculator.id}                            
                expression={expr}
                partIndex={partIndex}
                index={index}
              />
            ))}
          </ExpressionContainer>
        )}
        {part.content && (
          <DocContainer data-part-index={partIndex} id="content" className="content-container">
            <Latex content={part.content.map((content, index) => `${content}\n\n`).join('')} fontSize={18} padding={`0px ${horizontalPadding}px`} fontColor={Colors.black} fontWeight={Fonts.lexendMedium.fontWeight} />
          </DocContainer>
        )}
      </>
    )
  };

  return renderRoot();
};

export default CalculatorContent;