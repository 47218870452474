// Fonts.tsx
const Fonts = {
    lexendLight: {
        fontFamily: 'Lexend',
        fontWeight: 160,
    },
    lexendSemiLight: {
        fontFamily: 'Lexend',
        fontWeight: 240,
    },
    lexendMedium: {
        fontFamily: 'Lexend',
        fontWeight: 320,
    },
    lexendSemiBold: {
        fontFamily: 'Lexend',
        fontWeight: 380,
    },
    lexendBold: {
        fontFamily: 'Lexend',
        fontWeight: 460,
    },
    lexendUltraBold: {
        fontFamily: 'Lexend',
        fontWeight: 800,
    },




    quicksandLight: {
        fontFamily: 'Quicksand',
        fontWeight: 300,
    },
    quicksandSemiLight: {
        fontFamily: 'Quicksand',
        fontWeight: 400,
    },
    quicksandMedium: {
        fontFamily: 'Quicksand',
        fontWeight: 500,
    },
    quicksandSemiBold: {
        fontFamily: 'Quicksand',
        fontWeight: 600,
    },
    quicksandBold: {
        fontFamily: 'Quicksand',
        fontWeight: 700,
    },
};

export default Fonts;
