// Stepper.ts
import * as d3 from 'd3';
import { roundToPrecision } from '../../../utils/beautifyText';
import Fonts from '../../common/Fonts';

interface StepperProps {
  svgRef: SVGSVGElement;
  id?: string;
  x: number;
  y: number;

  value?: number;
  min?: number;
  max?: number;
  tick?: number;
  tickInitial?: number;
  decimalPrecision?: number;
  showLabel?: boolean;
  actualScale?: number;

  stepUp?: string;
  stepDown?: string;

  direction?: 'horizontal' | 'vertical' | null;
  onClick?: (newValue: number) => void;
}

const Stepper = ({ svgRef, id, x, y, value, min, max, tick, tickInitial, decimalPrecision, showLabel, stepUp, stepDown, direction = 'horizontal', onClick, actualScale = 1 }: StepperProps) => {
  const svg = d3.select(svgRef);

  const lineHeight = 42 / actualScale;
  const triangleSize = 34 / actualScale;
  // Adjust y to be the center point
  const adjustedY = y - lineHeight / 2;
  const centerY = y;
  const padding = 18 / actualScale;
  const hoverSize = 4 / actualScale;
  const touchAreaSize = 80 / actualScale;
  const strokeWidth = 1 / actualScale;
  const fontSize = 18 / actualScale;

  // console.log(`stepper value ${value} min ${min} max ${max} tick ${tick}`);
  const disabledLeft = value === undefined || min === undefined || value <= min;
  const disabledRight = value === undefined || max === undefined || value >= max;

  const handleInteraction = (element, clickHandler, disabled, isArrow) => {
    let isMouseDown = false;
    let isOverElement = false;
    let touchStartTarget = null;

    const handleEvent = (event, type) => {
      // Prevent default behavior for touch events to avoid conflicts
      if (event.type.startsWith('touch')) {
        event.preventDefault();
      }

      switch (type) {
        case 'over':
          isOverElement = true;
          if (!disabled && isArrow) {
            animateArrows(d3.select(event.currentTarget), triangleSize + hoverSize);
          }
          // if (showLabel) renderLabel();
          break;
        case 'touchstart':
          touchStartTarget = event.target;
          isOverElement = true;
          if (!disabled && isArrow) {
            animateArrows(d3.select(event.currentTarget), triangleSize + hoverSize);
          }
          // if (showLabel) renderLabel();
          break;
        case 'out':
          isOverElement = false;
          if (!disabled && isArrow) {
            animateArrows(d3.select(event.currentTarget), triangleSize);
          }
          // if (showLabel) removeLabel();
          break;
        case 'touchcancel':
        case 'touchmove':
          touchStartTarget = null;
          isOverElement = false;
          if (!disabled && isArrow) {
            animateArrows(d3.select(event.currentTarget), triangleSize);
          }
          // if (showLabel) removeLabel();
          break;
        case 'down':
          isMouseDown = true;
          if (!disabled && isArrow) {
            animateArrows(d3.select(event.currentTarget), triangleSize - hoverSize / 2);
          }
          // if (showLabel) renderLabel();
          break;
        case 'up':
          if (!disabled && isArrow && isMouseDown && isOverElement) {
            animateArrows(d3.select(event.currentTarget), triangleSize);
            clickHandler(event);
          }
          isMouseDown = false;
          // if (showLabel) renderLabel();
          break;
        case 'touchend':
          // For touch events, only trigger if the touchend target matches the touchstart target
          if (!disabled && isArrow && touchStartTarget === event.target) {
            animateArrows(d3.select(event.currentTarget), triangleSize);
            clickHandler(event);
          }
          touchStartTarget = null;
          isOverElement = false;
          // if (showLabel) renderLabel();
          break;
        default:
          break;
      }
    };
  
    element
      .on('mouseover', (event) => handleEvent(event, 'over'))
      .on('mouseout', (event) => handleEvent(event, 'out'))
      .on('mousedown', (event) => handleEvent(event, 'down'))
      .on('mouseup', (event) => handleEvent(event, 'up'))
      .on('touchstart', (event) => handleEvent(event, 'touchstart'))
      .on('touchend', (event) => handleEvent(event, 'touchend'))
      .on('touchmove', (event) => handleEvent(event, 'touchmove'))
      .on('touchcancel', (event) => handleEvent(event, 'touchcancel'));
  
    if (disabled) {
      element.style('opacity', '0.3').style('pointer-events', 'none');
    }
  };  

  const renderStepper = () => {
    const touchArea = svg.append('rect')
      .attr('x', x - touchAreaSize / 2)
      .attr('y', adjustedY + lineHeight / 2 - touchAreaSize / 2)
      .attr('width', touchAreaSize)
      .attr('height', touchAreaSize)
      .style('fill', 'transparent')
      // .attr('stroke', 'red')
      .style('pointer-events', 'auto');
  
    const addLine = (transform: string) => {
      return svg.append('line')
        .attr('id', id)
        .attr('x1', x)
        .attr('y1', adjustedY)
        .attr('x2', x)
        .attr('y2', adjustedY + lineHeight)
        .attr('transform', transform)
        .attr('stroke', 'black')
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', '6,6')
        .attr('stroke-linecap', 'round')
        .style('pointer-events', 'none');
    };
  
    const addCaret = (transform: string, disabled: boolean) => {
      return svg.append('image')
        .attr('href', '/icons/icon-caret.svg')
        .attr('x', direction === 'horizontal' ? x - triangleSize/2 : x - triangleSize/2)
        .attr('y', direction === 'horizontal' ? adjustedY - padding : adjustedY - padding)
        .attr('width', triangleSize)
        .attr('height', triangleSize)
        .attr('transform', transform)
        .attr('opacity', disabled ? '0.3' : '1.0')
        .style('cursor', disabled ? 'default' : 'pointer')
        .style('pointer-events', disabled ? 'none' : 'auto')
        .style('-webkit-tap-highlight-color', 'transparent');
    };
  
    const line = addLine(
      direction === 'horizontal' ? null : `rotate(-90, ${x}, ${centerY})`
    );
    const leftArrow = addCaret(
      direction === 'horizontal' 
        ? `rotate(-90, ${x}, ${centerY})`
        : `rotate(180, ${x}, ${centerY})`, 
      disabledLeft
    );
    const rightArrow = addCaret(
      direction === 'horizontal'
        ? `rotate(90, ${x}, ${centerY})`
        : `rotate(0, ${x}, ${centerY})`,
      disabledRight
    );

    if (showLabel) renderLabel();
  
    handleInteraction(touchArea, () => {}, false, false);
  
    handleInteraction(leftArrow, () => {
      if (onClick && value && min && max && tick) {
        // console.log(`stepper left click initial value: ${value}, tick: ${tick}`);

        // let newValue;
        // if (type === 'tick') {
        //   newValue = value - (tick/2);
        // } else { // for type total and anything else
        //   newValue = value - tick;
        // }

        // let newValue = value - tick;

        let newValue;
        if (stepDown) {
          const stepDownVal = Number(eval(`${stepDown}`));
          // console.log(stepDown, stepDownVal);
          newValue = stepDownVal;
        } else {
          const subtract = tickInitial ? tickInitial : tick;
          newValue = value - subtract;
          // newValue = value - tick;
        }

        if (decimalPrecision !== undefined) {
          newValue = roundToPrecision(newValue, decimalPrecision);
        }
        // console.log(`newValue ${newValue} >= min ${min} = ${newValue >= min}`);
        if (newValue >= min) {
          onClick(newValue);
        }
      }
    }, disabledLeft, true);
  
    handleInteraction(rightArrow, () => {
      if (onClick && value && min && max && tick) {
        // console.log(`stepper right click initial value: ${value}, tick: ${tick}`);
        // let newValue = value + tick;

        let newValue;
        if (stepUp) {
          const stepUpVal = Number(eval(`${stepUp}`));
          // console.log(stepUp, stepUpVal);
          newValue = stepUpVal;
          // console.log(`newValue ${newValue}`);
        } else {
          const add = tickInitial ? tickInitial : tick;
          newValue = value + add;
          // newValue = value + tick;
        }

        if (decimalPrecision !== undefined) {
          newValue = roundToPrecision(newValue, decimalPrecision);
        }
        // console.log(`newValue ${newValue} <= max ${max} = ${newValue <= max}`);
        if (newValue <= max) {
          onClick(newValue);
        }
      }
    }, disabledRight, true);
  };  

  const animateArrows = (selection, size) => {
    selection.transition()
      .duration(100)
      .attr('width', size)
      .attr('height', size)
      .attr('x', x - size / 2)
      .attr('y', adjustedY - padding - (size - triangleSize) / 2);
  };

  const renderLabel = () => {
    if (value !== null && value !== undefined) {
      svg.append('circle')
        .attr('id', `${id}-label-bg`)
        .attr('cx', x)
        .attr('cy', adjustedY + lineHeight / 2)
        .attr('r', fontSize / 2)
        .attr('fill', 'white')
        .style('pointer-events', 'none');
      svg.append('text')
        .attr('id', `${id}-label-text`)
        .attr('x', x)
        .attr('y', adjustedY + lineHeight / 2 + fontSize / 3) 
        .attr('text-anchor', 'middle')
        .style('font-family', Fonts.lexendMedium.fontFamily)
        .style('font-weight', Fonts.lexendMedium.fontWeight)
        .style('font-size', `${fontSize}px`)
        .attr('fill', 'black')
        .text(value)
        .style('pointer-events', 'none');
    }
  };

  const removeLabel = () => {
    svg.select(`#${id}-label-bg`).remove();
    svg.select(`#${id}-label-text`).remove();
  };

  renderStepper();
};

export default Stepper;
