import { problem } from "../Generate";

export const generateAddition = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const generateCombinations = (currentCombination: number[], start: number, sum: number, maxValues: number, maxSum: number) => {
    if (sum > maxSum || currentCombination.length > maxValues) return;
    if (currentCombination.length >= 2 && sum <= maxSum) {
      if (currentCombination.filter(num => num === 0).length <= 1) {
        count += 1;
        const template = {
          name: "Addition",
          numColors: currentCombination.length,
          text: `${currentCombination.join(' + ')} = ?`,
          visualizations: [
            {
              type: "grid",
              cols: maxSum <= 10 ? 5 : 10,
              rows: maxSum <= 10 ? 2 : 10,
              counters: currentCombination,
              colors: Array.from({ length: currentCombination.length }, (_, i) => i + 1)
            }
          ],
          trace: {
            solution: `${currentCombination.join(' + ')} = ${sum}`,
            grade_standards: sum <= 10 && currentCombination.length <= 2 ? "K.OA.A.5: Fluently add and subtract within 5."
              : sum >10 && sum <= 20 || sum <= 10 && currentCombination.length > 2 ? "1.OA.C.6: Add and subtract within 20, demonstrating fluency for addition and subtraction within 10."
              : "2.NBT.B.5: Fluently add and subtract within 100 using strategies based on place value, properties of operations, and/or the relationship between addition and subtraction.",
            plan: maxSum <= 10 ?
              `A 10 frame grid with ${currentCombination.join(', ')} counters (each in its own color) added up to show a total of ${sum} counters.` :
              `A 10x10 grid with ${currentCombination.join(', ')} counters (each in its own color) added up to show a total of ${sum} counters.`
          }
        };
        problems.push({
          id: crypto.randomUUID(),
          createdAt: new Date().toISOString(),
          name: `${template.name}: ${currentCombination.length} values <=${maxSum}`,
          numColors: template.numColors,
          text: template.text,
          visualizations: template.visualizations,
          trace: template.trace
        });
      }
    }
    for (let i = start; i <= 100; i++) {
      if (sum + i <= maxSum) {
        if (i !== 0 || !currentCombination.includes(0)) {
          generateCombinations([...currentCombination, i], i, sum + i, maxValues, maxSum);
          if (problems.length >= maximum) return;
        }
      }
    }
  };

  // First pass for sums > 10 and <= 100
  generateCombinations([], 0, 0, 2, 100);

  // Second pass for sums <= 10
  generateCombinations([], 0, 0, 5, 10);

  return problems;
};