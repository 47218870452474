import { problem } from "../Generate";

export const generateSubtraction = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const addProblem = (template: any) => {
    problems.push({
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      ...template
    });
  };

  const generateUpTo10 = (val1: number, diff: number, negative?: boolean) => {
    if (val1 > 10 || diff > val1) return;
    const val2 = val1 - diff;

    count += 1;
    const template = {
      name: "Subtraction " + (negative ? "-10" : "<= 10") + ": Number Line",
      numColors: 3,
      variables: {
        diff: "${val1 - val2}",
        val2: "${val1 - diff}"
      },
      text: `${val1} - ${val2} = ${Math.random() < 0.5 ? '?' : '_'}`,
      visualizations: [
        {
          type: "numberLine",
          min: negative ? -10 : 0,
          max: 10,
          tick: 1,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: diff,
              endVar: "diff",
              hopInterval: "${diff === val1 ? 0 : 1}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "${diff <= val1 ? '-' : '+'}${Math.abs(val2)}",
              endLabel: "${diff}",
              startColor: 1,
              lineColor: 2,
              endColor: 3,
              startDraggable: true,
              endDraggable: true,
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: negative ? -10 : 0,
          max: 10,
          tick: 1,
          data: [
            {
              type: "hop",
              start: val1,
              end: diff,
              hopInterval: diff === val1 ? 0 : 1,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: "-" + val2.toString(),
              endLabel: diff.toString(),
              startColor: 1,
              lineColor: 2,
              endColor: 3
            }
          ]
        }
      ],
      answer: {
        multipleChoice: negative ? Array.from({ length: 11 }, (_, i) => (i - 10).toString()) 
          : Array.from({ length: 11 }, (_, i) => i.toString()),
        multiselect: false,
        correctAnswer: [diff.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const generateUpTo100 = (val1: number, val2: number) => {
    if (val1 > 100 || val2 > val1) return;
    const final_diff = val1 - val2;
    const val2_num_tens = Math.floor(val2/10);
    const val2_tens = val2 >= 0 ? Math.floor(val2/10) * 10 : Math.ceil(val2/10) * 10;
    const val2_ones = val2 % 10;
    const tens_diff = val1 - val2_tens;

    count += 1;
    const template = {
      name: "Subtraction 11 - 100: Number Line",
      numColors: 3,
      variables: {
        val1: "${final_diff + val2}",
        val2: "${val1 - final_diff}",
        final_diff: "${val1 - val2}",
        val2_num_tens: "${Math.floor(val2/10)}",
        val2_tens: "${val2 >= 0 ? Math.floor(val2/10) * 10 : Math.ceil(val2/10) * 10}",
        val2_ones: "${Math.abs(val2 % 10)}",
        tens_diff: "${val1 - val2_tens}"
      },
      text: `${val1} - ${val2} = ?`,
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: tens_diff,
              endVar: "tens_diff",
              hopInterval: "${val2_ones !== 0 || val2_tens !== 0 ? 10 : 0}",
              startPoint: "dot",
              endPoint: "${val2_tens !== 0 ? 'dot' : undefined}",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "${val2_tens !== 0 ? '-' + val2_tens : ' '}",
              endLabel: "${val2_tens !== 0 ? tens_diff : ''}",
              startColor: 1,
              lineColor: 2,
              endColor: -1,
              startDraggable: true,
              dragTick: 1
            },
            {
              type: "hop",
              start: "${tens_diff}",
              end: final_diff,
              endVar: "final_diff",
              hopInterval: "${val2_ones !== 0 || val2_tens !== 0 ? 1 : 0}",
              startPoint: "${val2_tens !== 0 ? 'dot' : undefined}",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val2_tens !== 0 ? tens_diff : ''}",
              lineLabel: "${val2_ones !== 0 || (val2_tens === 0 && val2_ones === 0) ? '-' + val2_ones : ' '}",
              endLabel: "${final_diff}",
              startColor: -1,
              lineColor: 2,
              endColor: 3,
              endDraggable: true,
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              end: tens_diff,
              hopInterval: val2_ones !== 0 || val2_tens !== 0 ? 10 : 0,
              startPoint: "dot",
              endPoint: val2_tens !== 0 ? "dot" : undefined,
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: val2_tens !== 0 ? '-' + val2_tens : ' ',
              endLabel: tens_diff.toString(),
              startColor: 1,
              lineColor: 2,
              endColor: -1
            },
            {
              type: "hop",
              start: tens_diff,
              end: final_diff,
              hopInterval: val2_ones !== 0 || val2_tens !== 0 ? 1 : 0,
              startPoint: val2_tens !== 0 ? "dot" : undefined,
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: tens_diff.toString(),
              lineLabel: val2_ones !== 0 || (val2_tens === 0 && val2_ones === 0) ? '-' + val2_ones : ' ',
              endLabel: final_diff.toString(),
              startColor: -1,
              lineColor: 2,
              endColor: 3
            }
          ]
        }
      ],
      answer: {
        multipleChoice: Array.from(new Set([
          final_diff.toString(),
          (final_diff + 10).toString(),
          (final_diff - 10).toString(),
          (final_diff + Math.floor(Math.random() * 5) + 1).toString(),
          (final_diff - Math.floor(Math.random() * 5) - 1).toString(),
          (final_diff + Math.floor(Math.random() * 15) + 11).toString(),
          (final_diff - Math.floor(Math.random() * 15) - 11).toString()
        ])).slice(0, 6).sort((a, b) => parseInt(a) - parseInt(b)),
        multiselect: false,
        correctAnswer: [final_diff.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const generateThreeValues = (val1: number, val2: number, val3: number) => {
    if (val1 > 100 || val2 > val1 || val3 > (val1 - val2)) return;
    const diff1_2 = val1 - val2;
    const diff_all = val1 - val2 - val3;

    count += 1;
    const template = {
      name: "Subtraction <= 100: 3 Values",
      numColors: 4,
      text: `${val1} - ${val2} - ${val3} = ?`,
      variables: {
        val1: "${diff1_2 + val2}",
        val2: "${val1 - diff1_2}",
        val3: "${diff1_2 - diff_all}",
        diff1_2: "${val1 - val2}",
        diff_all: "${val1 - val2 - val3}"
      },
      visualizations: [
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              startVar: "val1",
              end: diff1_2,
              endVar: "diff1_2",
              hopInterval: "${val2}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${val1}",
              lineLabel: "-${val2}",
              endLabel: "${diff1_2}",
              startColor: 1,
              lineColor: 2,
              endColor: -1,
              startDraggable: true,
              startDragMin: "${diff1_2}",
              endDraggable: true,
              endDragMax: "${val1}",
              endDragMin: "${diff_all}",
              dragTick: 1
            },
            {
              type: "hop",
              start: "${diff1_2}",
              end: diff_all,
              endVar: "diff_all",
              hopInterval: "${val3}",
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: "${diff1_2}",
              lineLabel: "-${val3}",
              endLabel: "${diff_all}",
              startColor: -1,
              lineColor: 3,
              endColor: 4,
              endDraggable: true,
              endDragMax: "${diff1_2}",
              dragTick: 1
            }
          ]
        },
        {
          type: "numberLine",
          min: 0,
          max: 100,
          tick: 100,
          data: [
            {
              type: "hop",
              start: val1,
              end: diff1_2,
              hopInterval: val2,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: val1.toString(),
              lineLabel: `-${val2}`,
              endLabel: diff1_2.toString(),
              startColor: 1,
              lineColor: 2,
              endColor: -1
            },
            {
              type: "hop",
              start: diff1_2,
              end: diff_all,
              hopInterval: val3,
              startPoint: "dot",
              endPoint: "dot",
              hopPoints: "arrow",
              startLabel: diff1_2.toString(),
              lineLabel: `-${val3}`,
              endLabel: diff_all.toString(),
              startColor: -1,
              lineColor: 3,
              endColor: 4
            }
          ]
        }
      ],
      answer: {
        multipleChoice: Array.from(new Set([
          diff1_2.toString(),
          (diff_all + 10).toString(),
          (diff_all + (Math.random() < 0.5 ? -1 : 1) * (1 + Math.floor(Math.random() * 9))).toString(),
          (diff_all + (Math.random() < 0.5 ? -1 : 1) * (11 + Math.floor(Math.random() * 9))).toString(),
          diff_all.toString(),
          (diff_all - 10).toString()
        ])).slice(0, 6).sort((a, b) => parseInt(a) - parseInt(b)),
        multiselect: false,
        correctAnswer: [diff_all.toString()]
      },
      trace: {
        solution: "",
        grade_standards: "",
        plan: ""
      }
    };

    addProblem(template);
  };

  const PERCENT_TO_GEN = {
    UP_TO_10: 1, // 100% chance to generate
    NEG_10: 1,
    UP_TO_100: 0.01, // 1% chance to generate
    THREE_VALUES: 0.001 // 0.1% chance to generate
  };

  // Generate problems up to 10
  for (let val1 = 0; val1 <= 10; val1++) {
    for (let diff = 0; diff <= val1; diff++) {
      if (Math.random() < PERCENT_TO_GEN.UP_TO_10) {
        generateUpTo10(val1, diff);
      }
      if (problems.length >= maximum) return problems;
    }
  }

  // Generate problems from -10 to 10
  for (let val1 = -10; val1 <= 10; val1++) {
    for (let val2 = 1; val2 <= 10; val2++) {
      const diff = val1 - val2;
      if (diff < 0 && diff >= -10) {
        if (Math.random() < PERCENT_TO_GEN.NEG_10) {
          generateUpTo10(val1, diff, true);
        }
        if (problems.length >= maximum) return problems;
      }
    }
  }

  // Generate problems up to 100
  for (let val1 = 0; val1 <= 100; val1++) {
    for (let val2 = 0; val2 <= val1; val2++) {
      const diff = val1 - val2;
      if (diff >= 11 && diff <= 100) {
        if (Math.random() < PERCENT_TO_GEN.UP_TO_100) {
          generateUpTo100(val1, val2);
        }
        if (problems.length >= maximum) return problems;
      }
    }
  }

  // Generate three value problems
  for (let val1 = 20; val1 <= 100; val1++) {
    for (let val2 = 1; val2 <= val1/2; val2++) {
      for (let val3 = 1; val3 <= (val1 - val2)/2; val3++) {
        if (Math.random() < PERCENT_TO_GEN.THREE_VALUES) {
          generateThreeValues(val1, val2, val3);
        }
        if (problems.length >= maximum) return problems;
      }
    }
  }

  return problems;
};
