// App.tsx
import { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation, useNavigate } from "react-router-dom";
import Problem from "./pages/problem/Problem";
import Calculator from "./pages/content/Calculator";
import { HoverProvider } from "./pages/problem/HoverContext";
import LogIn from "./pages/account/LogIn";
import SignUp from "./pages/account/SignUp";
import ForgotPassword from "./pages/account/ForgotPassword";
import ResetPassword from "./pages/account/ResetPassword";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsOfService from "./pages/legal/TermsOfService";
import Website from "./pages/website/Website";
import { ComputedProblemsProvider } from './pages/problem/ComputedProblemsContext';
import { ConfigProvider } from "./utils/config";
import Stream from "./pages/internal/Stream";
import { Helmet } from 'react-helmet-async';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate('/problem');
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <ConfigProvider>
      <Helmet defaultTitle="SeeSolve - AI Math Solver & Calculator" titleTemplate="%s">
        <title>SeeSolve - AI Math Solver & Calculator</title>
        <meta name="description" content="The #1 AI for learning math. Solve any problem. Get unstuck. Learn faster. With interactive visualizations and step-by-step calculators." />
        {location.pathname === "/problem" && (
          <link rel="canonical" href={`${window.location.origin}/problem`} />
        )}
      </Helmet>
      <ComputedProblemsProvider>
        <HoverProvider>
          <Routes>
            <Route path="/" element={<Problem />} />
            <Route path="/problem" element={<Problem />} />
            <Route path="/problem/:problemId" element={<Problem />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/calculator/:calculatorId" element={<Calculator />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            {/* <Route path="/website" element={<Website />} /> */}
            {/* <Route path="/stream" element={<Stream />} /> */}
          </Routes>
        </HoverProvider>
      </ComputedProblemsProvider>
    </ConfigProvider>
  );
}

export default App;
