// NumberBar.tsx
import React, { useRef, useEffect, useState, act } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../../pages/problem/HoverContext';
import Dragger from './interactives/Dragger';
import Stepper from './interactives/Stepper';
import { getMaxDecimalPlaces, wrapText } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

export interface NumberBarProps {
  values: number[];
  totalValue: number;
  labels?: string[];
  tickPerValue?: number[];
  tick?: number;
  colors?: string[];
  direction?: 'left' | 'right';
  actualScale?: number;

  draggable?: boolean[];
  dragTick?: number;
  dragOverrideOtherValues?: boolean;
  dragMin?: number[];
  dragMax?: number[];
  tutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;

  steppable?: 'total' | 'tick' | null;
  stepMin?: number;
  stepMax?: number;
  stepUp?: string;
  stepDown?: string;

  totalValueInitial?: number;
  tickInitial?: number;
  onStateChange?: (state: NumberBarProps, skipLogic?: string) => void;
}

const numberBarPropsTypeDefinition: NumberBarProps = {
  values: [0],
  totalValue: 0,
  labels: [''],
  tick: 0,
  tickPerValue: [0],
  colors: [''],
  direction: 'left',

  draggable: [false],
  dragTick: 0,
  
  steppable: 'total',
  stepMin: 0,
  stepMax: 0,

  totalValueInitial: 0,
  tickInitial: 0,
  onStateChange: () => {},
};

const NumberBar: React.FC<NumberBarProps> = (props) => {
  props = convertAllTypes(props, numberBarPropsTypeDefinition);
  let {values, totalValue = 100, labels, tick, tickPerValue, colors, direction = 'left', 
    draggable, dragTick, dragOverrideOtherValues, dragMin, dragMax, tutorial, steppable, stepMin, stepMax, stepUp, stepDown, 
    totalValueInitial, tickInitial, onStateChange, actualScale = 1} = props;
  
  const MIN_SCALE = 0.37;
  const SCALE_BREAK_POINT = 0.7;
  const padding = actualScale > SCALE_BREAK_POINT ? 
      { left: 20, right: steppable ? 100/actualScale : 20, top: 20, bottom: 20 } :
      { left: 20, right: 20, top: 20, bottom: steppable && actualScale > MIN_SCALE ? 60/actualScale : 20 };

  // useEffect(() => {
  //   console.log('numberBar values', values);
  //   console.log('numberBar total value', totalValue);
  //   console.log('numberBar tick initial', tickInitial);
  //   console.log('numberBar totalValue initial', totalValueInitial);
  // }, [values, totalValue, tickInitial, totalValueInitial]);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [decimalPrecision, setDecimalPrecision] = useState(() => getMaxDecimalPlaces([
    ...(tick !== undefined ? [tick] : []), 
    ...(dragTick !== undefined ? [dragTick] : []), 
    ...values, 
    totalValue
  ]));
  // console.log('decimal precision', decimalPrecision);

  if (draggable?.some(d => d) && dragTick === undefined && tick !== undefined) {
    dragTick = tick;
  }

  const updateState = (newValuesData: number[], newTotalValueData: number, newTickData?: number, skipLogic?: string) => {
    // console.log(`NUMBERBAR newValuesData: ${newValuesData}, newTotalValueData: ${newTotalValueData}, newTickData: ${newTickData}`);
    values = newValuesData;
    totalValue = newTotalValueData;
    tick = newTickData;

    if (skipLogic) setHasInteracted(true);
    // console.log('skip logic', skipLogic);
    if (onStateChange) {
      onStateChange({
        ...props,
        values: newValuesData,
        totalValue: newTotalValueData,
        tick: newTickData,
        labels,
        colors,
        direction,
        draggable,
        dragTick,
        steppable,
      }, skipLogic);
    }
  }; 

  const handleDrag = (index: number, newValue: number) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    // console.log('drag values:', updatedValues);
    const skipLogic = `values[${index}]`;
    // console.log('skip logic', skipLogic);
    updateState(updatedValues, totalValue, tick, skipLogic);
  };

  const handleStepperClick = (newValue) => {
    // console.log('number bar stepper click', newValue);
    if (steppable === 'total') {
      updateState(values, newValue, tick, 'totalValue');
    } else if (steppable === 'tick') {
      updateState(values, totalValue, newValue, 'tick');
    }
  };

  // useEffect(() => {
  //   console.log('total', totalValue, 'tick every', tick, 'tick value', totalValue/tick);
  // }, [totalValue, tick]);

  const render = () => {
    if (!svgRef.current) return;

    let width = 700;
    const height = 80;
    const maxValue = totalValue;
    const tickInterval = tick ? (width / maxValue) * tick : 0;

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width + padding.left + padding.right} ${height + padding.top + padding.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', '100%');

    svg.selectAll('*').remove();

    // Value bars
    let accumulatedWidth = direction === 'left' ? padding.left : padding.left + width;
    const draggerData = [];
    values.forEach((value, index) => {
      const barWidth = (value / maxValue) * width;
      const barX = direction === 'left' ? accumulatedWidth : accumulatedWidth - barWidth;
        accumulatedWidth = direction === 'left' ? accumulatedWidth + barWidth : accumulatedWidth - barWidth;
      const color = colors && colors[index] ? colors[index] : Colors.vizDefault;

      // Value bar
      svg.append('rect')
        .attr('x', barX)
        .attr('y', padding.top)
        .attr('width', barWidth)
        .attr('height', height)
        .attr('fill', color)
        .attr('stroke', steppable ? 'none' : 'black')
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('stroke-width', 1)
        // .attr('rx', 1)
        // .attr('ry', 1)
        // .attr('stroke-width', 3)
        .on('click', () => {
          handleMouseEnter(color);
        });

      // Tick per value
      if (tickPerValue && tickPerValue[index] && tickPerValue[index] > 0) {
        console.log('tickPerValue', tickPerValue[index]);
        const tickCount = Math.floor(value / tickPerValue[index]);
        for (let i = 1; i < tickCount; i++) {
          const tickIntervalForValue = tickPerValue[index] ? (width / maxValue) * tickPerValue[index] : 0;
          const tickX = barX + (i * tickIntervalForValue);
          svg.append('line')
            .attr('x1', tickX)
            .attr('x2', tickX)
            .attr('y1', padding.top)
            .attr('y2', height + padding.top)
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .attr('opacity', 0.25);
        }
      }

      // Dragger data
      if (draggable && draggable[index]) {
        draggerData.push({
          id: `number-bar-segment-${index}`,
          x: direction === 'left' ? barX + barWidth : barX,
          y: padding.top + height / 2,
          color,
          index,
          value: values[index],
          min: dragMin && dragMin[index] ? dragMin[index] : 0,
          max: dragMax && dragMax[index] ? dragMax[index] : maxValue,
          tutorial: hasInteracted ? null : tutorial!,
        });
      }
    });

    // Tick lines
    if (tick && tick > 0 && tick !== totalValue) {
      for (let i = 0; i <= maxValue; i += tick) {
        const x = direction === 'left'
          ? (i / maxValue) * width + padding.left
          : width - (i / maxValue) * width + padding.left;
        svg.append('line')
          .attr('x1', x)
          .attr('x2', x)
          .attr('y1', padding.top)
          .attr('y2', height + padding.top)
          .attr('vector-effect', 'non-scaling-stroke')
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          .attr('opacity', 1.0);
      }
    }

    // Label text
    values.forEach((value, index) => {
      if (actualScale < MIN_SCALE) return;
      const barWidth = (value / maxValue) * width;
      const barX = direction === 'left' 
        ? padding.left + values.slice(0, index).reduce((acc, cur) => acc + (cur / maxValue) * width, 0)
        : padding.left + width - values.slice(0, index).reduce((acc, cur) => acc + (cur / maxValue) * width, 0) - barWidth;
      const color = colors && colors[index] ? colors[index] : Colors.vizDefault;
    
      if (labels && labels[index] && barWidth > 0) {
        const minLabelWidth = 120;
        const labelWidth = Math.max(barWidth-10, minLabelWidth);
        const baseFontSize = 24;
        const scaledFontSize = baseFontSize / actualScale;
        const edgePadding = { x: 4 / actualScale, y: -2 / actualScale };
        const lineHeight = 1.1;

        const insideLabel = svg.append('text')
          .attr('x', barX + barWidth / 2)
          .attr('y', padding.top + height / 2)
          .style('font-family', Fonts.lexendMedium.fontFamily)
          .style('font-weight', Fonts.lexendMedium.fontWeight)
          .style('font-size', `${scaledFontSize}px`)
          .style('line-height', `${lineHeight}em`)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('dy', `${lineHeight/3}em`)
          .text(labels[index]);

        // Apply text wrapping with scaled width
        wrapText(insideLabel, labelWidth, 'middle', (scaledFontSize * 2.5 > height) ? 1 : 2, lineHeight);

        // After wrapping, adjust vertical position based on number of lines
        const numLines = insideLabel.selectAll('tspan').size();
        
        // Calculate total height of text block
        const totalTextHeight = lineHeight * (numLines - 1) * scaledFontSize;
        // Position the text block so its center aligns with the bar's center
        const startY = padding.top + height / 2 - totalTextHeight / 2;
        
        // Position all tspans relative to the center point
        insideLabel.selectAll('tspan')
          .attr('x', barX + barWidth / 2)
          .attr('y', startY);

        if (value !== 0) {
          // Draw background box for each line of text independently
          insideLabel.selectAll('tspan').each(function() {
            const tspan = d3.select(this);
            const bbox = (this as SVGTSpanElement).getBBox();
            const maxHeight = (height - (edgePadding.y*2) - 3*2) / actualScale; // Subtract thick black outline (3px * 2)
            const boxHeight = Math.min(bbox.height - edgePadding.y, maxHeight);
            svg.insert('rect', 'text')
              .attr('x', bbox.x - edgePadding.x)
              .attr('y', bbox.y - edgePadding.y)
              .attr('rx', 4 / actualScale)
              .attr('ry', 4 / actualScale)
              .attr('width', bbox.width + edgePadding.x*2)
              .attr('height', boxHeight + edgePadding.y*2)
              .attr('fill', color)
              // .attr('stroke', 'red')
              // .attr('stroke-width', 1)
              .attr('opacity', 1.0);
          });
        }

        insideLabel.on('click', () => {
          handleMouseEnter(color);
        });
      }
    });

    // Thick black outline
    svg.append('rect')
      .attr('x', padding.left)
      .attr('y', padding.top)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('rx', 1)
      .attr('ry', 1)
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('stroke', 'black')
      .attr('stroke-width', 3);

    // Draggers on top
    draggerData.forEach(({ id, x, y, color, index, value, min, max, tutorial }) => {
      if (actualScale < MIN_SCALE) return;
      const draggerColor = dragOverrideOtherValues || draggable.length === 1 ? Colors.black : color; 
      Dragger({
        svgRef: svgRef,
        id,
        color: draggerColor,
        direction: direction === 'left' ? 'right' : 'left',
        x,
        y,
        minPos: min ? min * 800 / totalValue + padding.left : 0 + padding.left,
        maxPos: max ? max * 800 / totalValue - padding.right : 800 - padding.right,
        value: value,
        minVal: min ? min : 0,
        maxVal: max ? max : totalValue,
        tick: dragTick,
        decimalPrecision: decimalPrecision,
        prevSumValue: index > 0 ? values.slice(0, index).reduce((acc, cur) => acc + cur, 0) : 0,
        postSumValue: index + 1 < values.length ? values.slice(index + 1).reduce((acc, cur) => acc + cur, 0) : 0,
        dragOverrideOtherValues: dragOverrideOtherValues,
        tutorial: actualScale < 1 ? null : tutorial,
        actualScale: actualScale,
        handleDrag: (newValue) => handleDrag(index, newValue)
      });
    });

    // Stepper on top
    if (steppable) {
      if (actualScale < MIN_SCALE) return;
      const stepperX = actualScale < SCALE_BREAK_POINT 
        ? padding.left + width / 2  // Center horizontally
        : width + padding.left + padding.right / 2;  // Current position
      const stepperY = actualScale < SCALE_BREAK_POINT 
        ? padding.top + height + padding.bottom/2  // Position at bottom with some padding
        : padding.top + height / 2;  // Current centered position

      const valueProp = steppable === 'total' ? totalValue : tick;
      const sumValueData = values.reduce((acc, value) => acc + value, 0);
      const maxTicks = 20;
      let minProp = steppable === 'total' ? Math.max(sumValueData, tickInitial ?? 0) : tickInitial ?? 0;
      let maxProp = steppable === 'total' ? maxTicks * (tickInitial ?? 1) : totalValueInitial;
      if (stepMin) minProp = stepMin;
      if (stepMax) maxProp = stepMax;
      const stepTick = tick ?? 1;

      // console.log('total value', totalValue, 'tick', tick);
      // console.log('initial total value', totalValueInitial, 'initial tick', tickInitial);
      // console.log('stepper', valueProp, 'sumValueData', sumValueData, 'tick', tick);
      // console.log('minProp', minProp, 'maxProp', maxProp);
      // console.log(`stepUp ${stepUp} stepDown ${stepDown}`);
      // console.log('------');

      Stepper({
        svgRef: svgRef.current!,
        x: stepperX,
        y: stepperY,

        value: valueProp,
        min: minProp,
        max: maxProp,
        tick: stepTick,
        tickInitial: tickInitial,
        decimalPrecision: decimalPrecision,
        showLabel: true,

        stepUp: stepUp!,
        stepDown: stepDown!,

        actualScale: actualScale,

        onClick: (newValue) => handleStepperClick(newValue)
      });
    }

    // Scale fonts to maintain constant visual size regardless of visualization scale
    const baseFontSize = 24;
    const scaledFontSize = baseFontSize / actualScale;
    // Divide by actualScale to maintain constant visual size (since SVG is already scaled by actualScale)
    
    // svg.selectAll('text')
    //   .style('font-size', `${scaledFontSize}px`);
    
    // svg.selectAll('.label')
    //   .style('font-size', `${scaledFontSize}px`);
  };

  useEffect(() => {
    render();
  }, [values, totalValue, labels, tick, colors, draggable, direction, handleMouseEnter, handleMouseLeave, onStateChange, actualScale]); 

  return <svg ref={svgRef}></svg>;
};

export default NumberBar;
