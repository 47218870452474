// DebugInput.tsx
import React from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Fonts from '../../components/common/Fonts';
import Colors from '../../components/common/Colors';
import { TextareaAutosize } from '@mui/material';

const StyledTextField = styled(TextField)`
  background-color: ${Colors.background};
  color: ${Colors.grey8};

  .MuiInputBase-root {
    height: auto; // Allow height to grow
    background-color: ${Colors.white};
    border-radius: 6px;
    font-family: ${Fonts.lexendMedium.fontFamily}, sans-serif;
    font-weight: ${Fonts.lexendMedium.fontWeight};
    font-size: 16px;
  }

  .MuiInputBase-input {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    overflow: hidden !important; // Allow content to be visible
    white-space: pre-wrap; // Allow wrapping
    transition: all 0.2s ease; // Smooth transition for height changes

    &:focus {
      white-space: pre-wrap; // Allow wrapping when focused
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.grey1};
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.grey3};
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.black};
  }
`;

interface DebugInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxRows?: number;
}

const DebugInput: React.FC<DebugInputProps> = ({ 
  value, 
  onChange, 
  placeholder = '', 
  maxRows = Infinity 
}) => {
  return (
    <StyledTextField
      multiline
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent: TextareaAutosize as any,
        inputProps: {
          minRows: 1,
          maxRows: maxRows,
          style: { resize: 'none' }
        }
      }}
    />
  );
};

export default DebugInput;