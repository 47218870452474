import { problem } from "../Generate";

export const generateSubtraction = (maximum: number): problem[] => {
  const problems: problem[] = [];
  let count = 0;

  const generateCombinations = (currentCombination: number[], start: number, sum: number, maxValues: number, maxSum: number) => {
    if (sum > maxSum || currentCombination.length > maxValues) return;
    if (currentCombination.length >= 2 && sum <= maxSum) {
      const zeroCount = currentCombination.filter(num => num === 0).length;
      if ((currentCombination.length === 2 && zeroCount <= 1) || 
          (currentCombination.length > 2 && zeroCount === 0)) {
        count += 1;
        const minuend = sum;
        const subtrahends = currentCombination.slice(0, -1);
        const difference = currentCombination[currentCombination.length - 1];
        
        const template = {
          name: "Subtraction",
          numColors: currentCombination.length,
          text: `${minuend} - ${subtrahends.join(' - ')} = ?`,
          visualizations: [
            {
              type: "grid",
              rows: maxSum <= 10 ? 2 : 10,
              cols: maxSum <= 10 ? 5 : 10,
              counters: [difference, ...subtrahends.reverse()],
              colors: Array.from({ length: currentCombination.length }, (_, i) => i + 1)
            }
          ],
          trace: {
            solution: `${minuend} - ${subtrahends.join(' - ')} = ${difference}`,
            grade_standards: maxSum <= 10 && currentCombination.length <= 2 ? "K.OA.5: Fluently add and subtract within 5."
              : maxSum > 10 && maxSum <= 20 || maxSum <= 10 && currentCombination.length > 2 ? "1.OA.6: Add and subtract within 20, demonstrating fluency for subtraction within 10."
              : "2.NBT.5: Fluently add and subtract within 100 using strategies based on place value, properties of operations, and the relationship between addition and subtraction.",
            plan: maxSum <= 10 ?
              `A 10 frame grid showing ${minuend} starting counters made up of ${difference} counters remaining in one color, and ${subtrahends.join(', ')} counters removed ${subtrahends.length > 1 ? 'each in different colors' : 'in another color'}.` :
              `A 10x10 grid showing ${minuend} starting counters made up of ${difference} counters remaining in one color, and ${subtrahends.join(', ')} counters removed ${subtrahends.length > 1 ? 'each in different colors' : 'in another color'}.`
          }
        };
        
        problems.push({
          id: crypto.randomUUID(),
          createdAt: new Date().toISOString(),
          name: `${template.name}: ${currentCombination.length} values <=${maxSum}`,
          numColors: template.numColors,
          text: template.text,
          visualizations: template.visualizations,
          trace: template.trace
        });
      }
    }
    for (let i = start; i <= 100; i++) {
      if (sum + i <= maxSum) {
        const newCombination = [...currentCombination, i];
        const zeroCount = newCombination.filter(num => num === 0).length;
        if ((newCombination.length <= 2 && zeroCount <= 1) || 
            (newCombination.length > 2 && zeroCount === 0)) {
          generateCombinations(newCombination, i, sum + i, maxValues, maxSum);
          if (problems.length >= maximum) return;
        }
      }
    }
  };

  // First pass for sums > 10 and <= 100
  generateCombinations([], 0, 0, 2, 100);

  // Second pass for sums <= 10
  generateCombinations([], 0, 0, 5, 10);

  return problems;
};