// State.tsx
import React, { createContext, useContext, useState, useCallback, useEffect, ReactNode, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import { transformContentEveryUpdate } from './TransformContent';

interface StateProviderProps {
  initialData: any[];
  children: ReactNode;
  onStateChange?: (problemId: string, problemData: any) => void;
  contentType: 'problem' | 'calculator';
}

const StateContext = createContext<any | null>(null);

export const useStateContext = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};

export const StateProvider: React.FC<StateProviderProps> = ({ children, initialData, onStateChange, contentType }) => {
  const [problemsData, setProblemsData] = useState(initialData || []);
  const previousContentType = useRef<'problem' | 'calculator' | null>(null);

  // useEffect(() => {
  //   console.log('state update', problemsData);
  // }, [problemsData]); 

  useEffect(() => {
    if (initialData) {
      // Clear state when content type changes
      if (previousContentType.current !== contentType) {
        setProblemsData([]);
        previousContentType.current = contentType;
      }

      const updatedProblems = initialData.map(problem => {
        const existingProblem = problemsData.find(p => p.id === problem.id);
        if (existingProblem && isEqual(existingProblem, problem)) {
          return existingProblem;
        }
        if (onStateChange) onStateChange(problem.id, problem);
        return problem;
      });
      setProblemsData(updatedProblems);
      // console.log('Initial state data set:', updatedProblems);
    }
  }, [initialData, onStateChange, contentType]);    

  const handleStateChange = useCallback((problemId: string, updatedData: any) => {
    if (!checkValidData(updatedData)) return;

    const currentData = problemsData[problemId];
    if (!isEqual(updatedData, currentData)) {
      if (onStateChange) onStateChange(problemId, updatedData);
    }
  }, [problemsData, onStateChange]);

  const updateProblem = useCallback((problemId: string, newProblemData: any, skipLogic?: string | string[]) => {
    if (!checkValidData(newProblemData)) return;
  
    setProblemsData(prevProblems => {
      const problemIndex = prevProblems.findIndex(problem => problem.id === problemId);
      if (problemIndex === -1) return prevProblems;
  
      const updatedProblems = [...prevProblems];
      updatedProblems[problemIndex] = transformContentEveryUpdate({
        ...prevProblems[problemIndex],
        ...newProblemData,
      }, null, skipLogic);
  
      // console.log(`Updated problem data for problemId ${problemId}:`, updatedProblems[problemIndex]);
      handleStateChange(problemId, updatedProblems[problemIndex]);
  
      return updatedProblems;
    });
  }, [handleStateChange]);    

  const updateVisualizations = useCallback((problemId: string, partIndex: number, vizIndex: number, newVisualization: any, skipLogic?: string | string[]) => {
    // console.log('updateVisualizations', problemId, skipLogic);
    setProblemsData(prevProblems => {
      const problemIndex = prevProblems.findIndex(problem => problem.id === problemId);
      if (problemIndex === -1) return prevProblems;
  
      const updatedParts = prevProblems[problemIndex].parts.map((part: any, pIndex: number) => {
        if (pIndex !== partIndex) return part;
        const updatedVisualizations = part.visualizations.map((viz: any, vIndex: number) => {
          if (vIndex !== vizIndex) return viz;
          return {
            ...viz,
            ...newVisualization,
          };
        });
        return {
          ...part,
          visualizations: updatedVisualizations,
        };
      });
  
      const updatedProblems = [...prevProblems];
      updatedProblems[problemIndex] = {
        ...prevProblems[problemIndex],
        parts: updatedParts,
      };
  
      // console.log(`Updated visualizations for problemId ${problemId}, partIndex ${partIndex}, vizIndex ${vizIndex}:`, updatedProblems[problemIndex]);
      updateProblem(problemId, updatedProblems[problemIndex], skipLogic);
  
      return updatedProblems;
    });
  }, [problemsData, handleStateChange]);  

  const updateExpressions = useCallback((problemId: string, partIndex: number, exprIndex: number, newExpression: any) => {
    setProblemsData(prevProblems => {
      const problemIndex = prevProblems.findIndex(problem => problem.id === problemId);
      if (problemIndex === -1) return prevProblems;
  
      const updatedParts = prevProblems[problemIndex].parts.map((part: any, pIndex: number) => {
        if (pIndex !== partIndex) return part;
        const updatedExpressions = part.expressions.map((expr: any, eIndex: number) => {
          if (eIndex !== exprIndex) return expr;
          return {
            ...expr,
            ...newExpression,
          };
        });
        return {
          ...part,
          expressions: updatedExpressions,
        };
      });
  
      const updatedProblems = [...prevProblems];
      updatedProblems[problemIndex] = {
        ...prevProblems[problemIndex],
        parts: updatedParts,
      };
  
      // console.log(`Updated expressions for problemId ${problemId}, partIndex ${partIndex}, exprIndex ${exprIndex}:`, updatedProblems[problemIndex]);
      updateProblem(problemId, updatedProblems[problemIndex]);
      return updatedProblems;
    });
  }, [problemsData, handleStateChange]);

  const checkValidData = (data: any) => {
    if (data === null) {
      console.warn('Problem data is null:', data);
      return false;
    } else if (!data.parts) {
      console.warn('Parts is missing:', data);
      return false;
    } else if (!Array.isArray(data.parts)) {
      console.warn('Parts is not an array:', data);
      return false;
    }
    return true;
  };

  const contextValue = useMemo(() => ({
    problemsData,
    updateProblem,
    updateVisualizations,
    updateExpressions,
  }), [problemsData, updateProblem, updateVisualizations, updateExpressions]);

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};
