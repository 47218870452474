import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '../common/Colors';
import Fonts from '../common/Fonts';

interface InlineInputProps {
    value?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
}

const InputWrapper = styled.div`
    display: inline-block;
    vertical-align: baseline;
    margin: 0 2px;
`;

const HiddenSpan = styled.span`
    visibility: hidden;
    position: absolute;
    white-space: pre;
    padding: 0 2px;
    font-family: ${Fonts.lexendMedium.fontFamily};
    font-weight: ${Fonts.lexendMedium.fontWeight};
`;

const StyledInput = styled.input`
    display: inline-block;
    background-color: ${Colors.white};
    border-radius: 4px;
    border: 1px solid ${Colors.grey1};
    outline: none;
    padding: 0px 4px;
    font-family: ${Fonts.lexendMedium.fontFamily};
    font-weight: ${Fonts.lexendMedium.fontWeight};
    font-size: inherit;
    text-align: center;
    line-height: inherit;
    color: ${Colors.black};
    width: auto;
    min-width: 10px;
    box-sizing: content-box;
    cursor: text;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: ${Colors.grey4};
    }

    &:focus {
        border-color: ${Colors.blueLight};
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }

    &:disabled {
        border-color: ${Colors.grey1};
        background-color: ${Colors.transparent};
        cursor: pointer;
    }
`;

const InlineInput: React.FC<InlineInputProps> = ({ 
    value: initialValue = '', 
    onChange, 
    placeholder, 
    disabled = false 
}) => {
    const [value, setValue] = useState(initialValue.toString());
    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (inputRef.current && spanRef.current) {
            // Set input width to match the text width
            inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
        }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange && onChange(e);
    };

    return (
        <InputWrapper>
            <HiddenSpan ref={spanRef}>
                {value || placeholder || ''}
            </HiddenSpan>
            <StyledInput
                ref={inputRef}
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
            />
        </InputWrapper>
    );
};

export default InlineInput;
